import * as React from 'react';
import { AddButton, EditButton } from '../../shared/Buttons';
import { AssistanceProgram, ProgramType } from '../../../store/program/AssistanceProgramModel';
import { AssistanceService, AssistanceType } from '../../../store/program/AssistanceServiceModel';
import AssistanceServiceEditor from './AssistanceServiceEditor';
import AssistanceServiceToDrugEditor from './AssistanceServiceToDrugEditor';
import AssistanceServiceToInsuranceClassEditor from './AssistanceServiceToInsuranceClassEditor';
import { formatBoolean } from '../../../functions/format.functions';
import { ServiceCategory } from '../../../store/program/ServiceCategoryModel';
import AssistanceServiceActionDialog from './AssistanceServiceActionDialog';
import { ColumnsType } from 'antd/lib/table';
import { Dropdown, Button, MenuProps } from 'antd';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import AssistanceServiceToManufacturerEligibilityCriteriaIndex from './AssistanceServiceToManufacturerEligibilityCriteriaIndex';
import AssistanceServiceToFoundationEligibilityCriteriaIndex from './AssistanceServiceToFoundationEligibilityCriteriaIndex';
import AssistanceServiceToBrandDefaultsIndex from './AssistanceServiceToBrandDefaultsIndex';
import AssistanceServiceToFoundationDefaultsIndex from './AssistanceServiceToFoundationDefaultsIndex';
import Restricted from '../../../auth/Restricted';
import { KnownSettings } from '../../../store/SettingsModel';

interface IExpandedServiceProps {
   serviceCategories: ServiceCategory[];
   assistanceProgram: AssistanceProgram;
   assistanceServicesForProgram: AssistanceService[];
   setSelectedAssistanceProgramId: (id: number) => void;

   selectedAssistanceServiceId: number;
   setSelectedAssistanceServiceId: (id: number) => void;
   closeEditor: () => void;
}


enum AssistanceServiceActionType {
   ToDrugs = 0,
   ToBrandDefaults = 1,
   ToFoundationDefaults = 2,
   ToInsuranceClass = 3,
   ToEligibilityCriteria = 4,
}

const AssistanceServiceRow: React.FC<IExpandedServiceProps> = (props) => {
   const [isAssistanceServiceEditorOpen, setIsAssistanceServiceEditorOpen] = React.useState<boolean>(false);
   const [isAssistanceServiceToDrugEditorOpen, setIsAssistanceServiceToDrugEditorOpen] = React.useState<boolean>(false);
   const [isAssistanceServiceToInsuranceClassEditorOpen, setIsAssistanceServiceToInsuranceClassEditorOpen] = React.useState<boolean>(false);
   const [isAssistanceServiceToManufacturerEligibilityCriteriaIndexOpen, setIsAssistanceServiceToManufacturerEligibilityCriteriaIndexOpen] = React.useState<boolean>(false);
   const [isAssistanceServiceToFoundationEligibilityCriteriaIndexOpen, setIsAssistanceServiceToFoundationEligibilityCriteriaIndexOpen] = React.useState<boolean>(false);
   const [isAssistanceServiceToBrandDefaultsIndexOpen, setIsAssistanceServiceToBrandDefaultsIndexOpen] = React.useState<boolean>(false);
   const [isAssistanceServiceToFoundationDefaultsIndexOpen, setIsAssistanceServiceToFoundationDefaultsIndexOpen] = React.useState<boolean>(false);

   const [isActionEditorOpen, setIsActionEditorOpen] = React.useState<boolean>(false);
   //const [actionEditorType, setActionEditorType] = React.useState<AssistanceServiceActionType>();


   const { serviceCategories, assistanceProgram,
      assistanceServicesForProgram, setSelectedAssistanceProgramId,
      selectedAssistanceServiceId, setSelectedAssistanceServiceId,
      //   isAssistanceServiceEditorOpen, setIsAssistanceServiceEditorOpen,
      closeEditor
   } = props;

   const FoundationAssistanceServiceActionTypes = [
      AssistanceServiceActionType.ToFoundationDefaults,
      AssistanceServiceActionType.ToEligibilityCriteria
   ]

   const ManufacturerAssistanceServiceActionTypes = [
      AssistanceServiceActionType.ToDrugs,
      AssistanceServiceActionType.ToBrandDefaults,
      AssistanceServiceActionType.ToInsuranceClass,
      AssistanceServiceActionType.ToEligibilityCriteria
   ]

   const ShouldAssistanceActionTypeBeDisabled = (assistanceServiceType: AssistanceServiceActionType) => {
      switch (assistanceProgram.programType) {
         case ProgramType.Foundation:
            return !FoundationAssistanceServiceActionTypes.includes(assistanceServiceType)
         case ProgramType.Manufacturer:
            return !ManufacturerAssistanceServiceActionTypes.includes(assistanceServiceType)
         default:
            return false;
      }
   }

   const handleCloseClick = (assistanceProgramId?: number,
      assistanceServiceId?: number) => {
      setIsAssistanceServiceEditorOpen(false);
      closeEditor();
   }

   const handleCloseAssistanceServiceToDrugClick = () => {
      setIsAssistanceServiceToDrugEditorOpen(false);
      closeEditor();
   }

   const handleCloseAssistanceServiceToInsuranceClassClick = () => {
      setIsAssistanceServiceToInsuranceClassEditorOpen(false);
      closeEditor();
   }

   const handleCloseAssistanceServiceToManufacturerEligibilityCriteria = () => {
      setIsAssistanceServiceToManufacturerEligibilityCriteriaIndexOpen(false);
      closeEditor();
   }

   const handleCloseAssistanceServiceToFoundationEligibilityCriteria = () => {
      setIsAssistanceServiceToFoundationEligibilityCriteriaIndexOpen(false);
      closeEditor();
   }

   const handleCloseAssistanceServiceToBrandDefaults = () => {
      setIsAssistanceServiceToBrandDefaultsIndexOpen(false);
      closeEditor();
   }

   const handleCloseAssistanceServiceToFoundationDefaults = () => {
      setIsAssistanceServiceToFoundationDefaultsIndexOpen(false);
      closeEditor();
   }

   const handleActionEditorClick = (assistanceServiceId: number,
      actionType: AssistanceServiceActionType) => {
      setSelectedAssistanceProgramId(assistanceProgram.id);
      setSelectedAssistanceServiceId(assistanceServiceId);
      //setActionEditorType(actionType);
      switch (actionType) {
         case AssistanceServiceActionType.ToDrugs:
            setIsAssistanceServiceToDrugEditorOpen(true);
            break;
         case AssistanceServiceActionType.ToInsuranceClass:
            setIsAssistanceServiceToInsuranceClassEditorOpen(true);
            break;
         case AssistanceServiceActionType.ToEligibilityCriteria:
            switch (assistanceProgram.programType) {
               case ProgramType.Manufacturer:
                  setIsAssistanceServiceToManufacturerEligibilityCriteriaIndexOpen(true);
                  break;
               case ProgramType.Foundation:
                  setIsAssistanceServiceToFoundationEligibilityCriteriaIndexOpen(true);
                  break;
            }
            break;
         case AssistanceServiceActionType.ToBrandDefaults:
            setIsAssistanceServiceToBrandDefaultsIndexOpen(true);
            break;
         case AssistanceServiceActionType.ToFoundationDefaults:
            setIsAssistanceServiceToFoundationDefaultsIndexOpen(true);
            break;
         default:
            setIsActionEditorOpen(true);
            break;
      }
   }

   const handleCloseActionEditorClick = (assistanceProgramId?: number,
      assistanceServiceId?: number) => {
      //setActionEditorType(undefined);
      setIsActionEditorOpen(false);
      closeEditor();
   }

   //TODO: this 'disabled' prop is incorrect. just disabled it so I could get to the sub menus.
   const buildAssistanceServiceToContent = (assistanceServiceRecord: AssistanceService): MenuProps => {

      const items: MenuProps['items'] = [
         {
            key: 1,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => handleActionEditorClick(assistanceServiceRecord.id, AssistanceServiceActionType.ToDrugs)}>
                  Drugs
               </a>
            ),
            disabled: ShouldAssistanceActionTypeBeDisabled(AssistanceServiceActionType.ToDrugs)
         },
         {
            key: 2,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => handleActionEditorClick(assistanceServiceRecord.id, AssistanceServiceActionType.ToInsuranceClass)}>
                  Insurance Class
               </a>
            ),
            disabled: ShouldAssistanceActionTypeBeDisabled(AssistanceServiceActionType.ToInsuranceClass)
         },
         {
            type: 'divider'
         },
         {
            key: 3,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => handleActionEditorClick(assistanceServiceRecord.id, AssistanceServiceActionType.ToBrandDefaults)}>
                  Brand Defaults
               </a>
            ),
            disabled: ShouldAssistanceActionTypeBeDisabled(AssistanceServiceActionType.ToBrandDefaults)
         },
         {
            key: 4,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => handleActionEditorClick(assistanceServiceRecord.id, AssistanceServiceActionType.ToFoundationDefaults)}>
                  Foundation Defaults
               </a>
            ),
            disabled: ShouldAssistanceActionTypeBeDisabled(AssistanceServiceActionType.ToFoundationDefaults)
         },
         {
            key: 5,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => handleActionEditorClick(assistanceServiceRecord.id, AssistanceServiceActionType.ToEligibilityCriteria)}>
                  Eligibility Criteria
               </a>
            ),
            disabled: ShouldAssistanceActionTypeBeDisabled(AssistanceServiceActionType.ToEligibilityCriteria)
         }
      ];
      const thing = items[0]
      return { items } as MenuProps;
   }

   const columns: ColumnsType<AssistanceService> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 1
      },
      {
         title: 'Assistance Service Name',
         dataIndex: 'assistanceServiceName',
         key: 2
      },
      {
         title: 'Assistance Type',
         dataIndex: '',
         key: 3,
         render: (text, record) => {
            const assistanceType = AssistanceType.find(y => y.id === record.assistanceType)?.name;
            return <> {`[${record.assistanceType}] ${assistanceType}`} </>
         }
      },
      {
         title: 'Description',
         dataIndex: 'description',
         key: 4
      },
      {
         title: 'IsExternallyUpdated',
         dataIndex: 'isExternallyUpdated',
         key: 5,
         render: (isExternallyUpdated) => formatBoolean(isExternallyUpdated)
      },
      {
         title: 'ServiceCategoryId',
         dataIndex: '',
         key: 6,
         render: (text, record) => {
            const serviceCategory = serviceCategories?.find(y => y.id === record.serviceCategoryId);
            return <> {serviceCategory?.serviceDescription} </>
         }
      },
      {
         title: 'DuplicateRules',
         dataIndex: 'duplicateRules',
         key: 7
      },
      {
         title: 'HideInWizard',
         dataIndex: 'hideInWizard',
         key: 8,
         render: (hideInWizard) => formatBoolean(hideInWizard)
      },
      {
         title: <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
            <AddButton title='Add' buttonText='New Service' onClick={() => {
               setSelectedAssistanceProgramId(assistanceProgram.id);
               setSelectedAssistanceServiceId(undefined);
               setIsAssistanceServiceEditorOpen(true);
            }} />
         </Restricted>,
         dataIndex: '',
         key: 9,
         width: '15%',
         render: (text, record) =>
            <>
               <Dropdown menu={buildAssistanceServiceToContent(record)} trigger={['click']}>
                  <Button>Assistance Service To</Button>
               </Dropdown>
               <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                  <EditButton title='Edit' onClick={() => {
                     setSelectedAssistanceProgramId(assistanceProgram.id);
                     setSelectedAssistanceServiceId(record.id);
                     setIsAssistanceServiceEditorOpen(true);
                  }} />
               </Restricted>
            </>
      }
   ]

   const html = (
      <>
         <MinimalistTable
            rowKey='id'
            columns={columns}
            data={assistanceServicesForProgram}
            showHeader={true}
         />

         { isAssistanceServiceEditorOpen && 
            <AssistanceServiceEditor
               assistanceProgramId={assistanceProgram.id}
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isAssistanceServiceEditorOpen}
               closeEditor={handleCloseClick} />
         }

         { isAssistanceServiceToDrugEditorOpen &&
            <AssistanceServiceToDrugEditor
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isAssistanceServiceToDrugEditorOpen}
               closeEditor={handleCloseAssistanceServiceToDrugClick} />

         }

         {isAssistanceServiceToInsuranceClassEditorOpen &&
            <AssistanceServiceToInsuranceClassEditor
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isAssistanceServiceToInsuranceClassEditorOpen}
               closeEditor={handleCloseAssistanceServiceToInsuranceClassClick} />
         }

         {isActionEditorOpen &&
            <AssistanceServiceActionDialog
               assistanceProgramId={assistanceProgram.id}
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isActionEditorOpen}
               closeEditor={handleCloseActionEditorClick} />
         }

         {isAssistanceServiceToManufacturerEligibilityCriteriaIndexOpen &&
            <AssistanceServiceToManufacturerEligibilityCriteriaIndex
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isAssistanceServiceToManufacturerEligibilityCriteriaIndexOpen}
               handleClose={handleCloseAssistanceServiceToManufacturerEligibilityCriteria}
            />
         }

         {isAssistanceServiceToFoundationEligibilityCriteriaIndexOpen &&
            <AssistanceServiceToFoundationEligibilityCriteriaIndex
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isAssistanceServiceToFoundationEligibilityCriteriaIndexOpen}
               handleClose={handleCloseAssistanceServiceToFoundationEligibilityCriteria}
            />
         }

         {isAssistanceServiceToBrandDefaultsIndexOpen &&
            <AssistanceServiceToBrandDefaultsIndex
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isAssistanceServiceToBrandDefaultsIndexOpen}
               handlClose={handleCloseAssistanceServiceToBrandDefaults}
            />
         }

         {isAssistanceServiceToFoundationDefaultsIndexOpen &&
            <AssistanceServiceToFoundationDefaultsIndex
               assistanceServiceId={selectedAssistanceServiceId}
               isOpen={isAssistanceServiceToFoundationDefaultsIndexOpen}
               handlClose={handleCloseAssistanceServiceToFoundationDefaults}
            />
         }
      </>
   );

   return html;
}

export default AssistanceServiceRow;

import { Space, TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import * as React from 'react';
import { IOptionItem } from '../../functions/option.functions';
import { formatDateLong } from '../../functions/time.functions';
import { AlignItemsCenter } from '../../layouts/AlignItems';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import {
    mutateDocuSignRequestSearch,
   searchDocuSignRequestUrl, useDocuSignRequestSearch,
   useFetchDocuSignPractices, useFetchDocuSignPrograms
} from '../../store/docusign/DocuSignRequestFetcher';
import {
   DocuSignSearchFilter, DocuSignSearchResult
} from '../../store/docusign/DocuSignRequestModels';
import ApiErrorDisplay from '../ApiErrorDisplay';
import HighlightSearchText from '../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import ColumnFilter from '../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../shared/AntComponents/Filter/FilteredInfo';
import SearchResultsTable from '../shared/AntComponents/Table/SearchResultsTable';
import { antSortOptions } from '../shared/AntComponents/Table/table.functions';
import {
   BasicDateRangePickerField, BasicDropdownField, DebouncedBasicInputField
} from '../shared/BasicInputLibrary';
import { IconButton } from '../shared/Buttons';
import MultiSelectColumnFilter from '../shared/AntComponents/Filter/MultiSelectColumnFilter';
import colorWheel from '../../Theme/ColorWheel';
import {
   allSignaturesCompleted, canResend, getLastEvent, getPatientEvents, getProviderEvents
} from '../../store/docusign/DocuSignHelper';
import { SignatureStatus, RecordStatus } from './SignatureStatus';
import { AssistanceType } from '../../store/program/AssistanceServiceModel';
import DocuSignOperationsDialog from './DocuSignOperationsDialog';
import DocuSignEventsRow from './DocuSignEventsRow';
import Restricted from '../../auth/Restricted';
import { KnownSettings } from '../../store/SettingsModel';

const initSearchFilter: DocuSignSearchFilter = {
   page: 1,
   pageSize: 25,
   sortDirection: 'desc',
   sortField: 'id',
   applicationServiceStatuses: [8] //hard coded for now to only pull in pending applications
}

type searchFilter =  'practiceId'
   | 'patientId'
   | 'assistanceProgramIds'
   | 'applicationServiceStatuses'
   | 'applicationId'
   | 'submittedDateFrom'
   | 'submittedDateTo'
   ;

const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
   practiceId: undefined,
   patientId: undefined,
   assistanceProgramIds: undefined,
   applicationServiceStatuses: { title: 'Status', values: [{label: 'Pending Signature', value: 8} as IOptionItem] },
   applicationId: undefined,
   submittedDateFrom: undefined,
   submittedDateTo: undefined
};


const _keys: string[] = [searchDocuSignRequestUrl];

const DocuSignIndex: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();

   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);
   const [searchFilter, setSearchFilter] = React.useState<DocuSignSearchFilter>(initSearchFilter);
   const { pagedResult, isLoading, refreshDate } = useDocuSignRequestSearch(httpPost, searchFilter);
   const { practices } = useFetchDocuSignPractices(httpGet);
   const { programs } = useFetchDocuSignPrograms(httpGet);
   const [isDocuSignOperationsDialogOpen, setIsDocuSignOperationsDialogOpen] = React.useState(false);
   const [selectedDocuSignRequest, setSelectedDocuSignRequest] = React.useState<DocuSignSearchResult>();

   React.useEffect(() => {
      removeErrors({ keys: _keys });
      // for "removeErrors"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // clear errors on intial render of the page

   React.useEffect(() => {
      const newSearchFilter = {
         ...searchFilter,
         practiceId: filteredInfo.practiceId?.value as number,
         patientId: filteredInfo.patientId?.value as string,
         assistanceProgramIds: filteredInfo.assistanceProgramIds?.values.map(y => y.value as number),
         applicationServiceStatuses: filteredInfo.applicationServiceStatuses?.values.map(y => y.value as number),
         applicationId: filteredInfo.applicationId?.value as number,
         submittedDateFrom: filteredInfo.submittedDateFrom?.value as Date,
         submittedDateTo: filteredInfo.submittedDateTo?.value as Date,
         page: 1
      } as DocuSignSearchFilter;

      setSearchFilter(newSearchFilter);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredInfo]);

   const handleOnChange: TableProps<DocuSignSearchResult>['onChange'] = (pagination, filters, sorter, extra) => {
      const newSearchFilter = {
         ...searchFilter,
         page: pagination?.current
      }
      const theSorter: SorterResult<DocuSignSearchResult> = Array.isArray(sorter) ? sorter[0] : sorter;
      if (sorter && sorter.hasOwnProperty('field')) {
         newSearchFilter.sortField = theSorter.field as string;
         newSearchFilter.sortDirection = theSorter.order?.indexOf('desc') > -1 ? 'desc' : 'asc';
      }

      setSearchFilter(newSearchFilter);
   }
      
   const practiceOptions = React.useMemo(() => {
      return practices?.map((item): IOptionItem => ({
        // label: item.name,
         label: `[${item.id}]: ${item.name ?? 'Not In System'}`,
         value: item.id,
      } as IOptionItem))
   }, [practices])

   const programOptions = React.useMemo(() => {
      return programs?.map((item): IOptionItem => ({
         label: item.name,
         value: item.id,
      } as IOptionItem))
   }, [programs])

   const openDocuSignOperationsDialog = async (docuSignRequest: DocuSignSearchResult) => {
      setIsDocuSignOperationsDialogOpen(true);
      setSelectedDocuSignRequest(docuSignRequest);
   }

   const closeDocuSignOperationsDialog = async () => {
      setIsDocuSignOperationsDialogOpen(false);
      setSelectedDocuSignRequest(undefined);
   }

   const columns: ColumnsType<DocuSignSearchResult> = [
      {
         title:'Id',
         width: '5rem',
         dataIndex: 'id',
         sorter: true,
         sortDirections: antSortOptions,
         defaultSortOrder: 'descend',
         hidden: true
      },
      {
         title: <ColumnFilter
            title='Practice'
            filteredInfo={filteredInfo?.practiceId}
            content={
               <>
                  <BasicDropdownField
                     containerStyle={{ width: 300 }}
                     name='practiceId'
                     value={filteredInfo?.practiceId as unknown as number}
                     label='Practice'
                     multiple={false}
                     clearable={true}
                     options={practiceOptions}
                     search={true}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           practiceId: {
                              title: 'practiceId',
                              value: e ? e as unknown as number : undefined
                           }
                        })
                     }}
                  />
               </>
            }
         />,
         dataIndex: 'practiceId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            const practiceId = filteredInfo?.practiceId?.value as number;
            const targetString = `[${record.practiceId}] ${record.practiceName}`;
            const searchString = practiceId && record.practiceId ? targetString : undefined;

            return <HighlightSearchText searchString={searchString} targetString={targetString} />
         }
      },     
      {
         title: <ColumnFilter
            title='PatientId'
            filteredInfo={filteredInfo.patientId}
            content={
               <>
                  <DebouncedBasicInputField
                     label='Filter by PatientId'
                     placeholder='Filter by PatientId'
                     value={filteredInfo.patientId?.value as string}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           patientId: {
                              title: 'PatientId',
                              value: e ? e as string : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         width: '8rem',
         dataIndex: 'patientId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            return <HighlightSearchText searchString={filteredInfo?.patientId?.value as string} targetString={record.patientId} />
         }
      },
      {
         title: <ColumnFilter
            title='ApplicationId'
            filteredInfo={filteredInfo.applicationId}
            content={
               <>
                  <DebouncedBasicInputField
                     label='Filter by ApplicationId'
                     placeholder='Filter by ApplicationId'
                     value={filteredInfo.applicationId?.value as number}
                     type={'number'}
                     onChange={(e) => {
                        setFilteredInfo({
                           ...filteredInfo,
                           applicationId: {
                              title: 'ApplicationId',
                              value: e ? e as number : undefined
                           }
                        })
                     }} />
               </>
            }
         />,
         dataIndex: 'applicationId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            const assistanceTypes = AssistanceType.filter(y => record.assistanceTypes.includes(y.id))?.map(x => x.name);
            return  <div>
               <HighlightSearchText searchString={filteredInfo?.applicationId?.value as string} targetString={String(record.applicationId)} />
               {assistanceTypes?.length > 0 &&
                  <div style={{ color: colorWheel.graniteGrey }}>{assistanceTypes.join(', ')}</div>
               }
            </div>
         }
      },
      {
         title: <ColumnFilter title='Program Name'
            filteredInfo={filteredInfo?.assistanceProgramIds}
            content={<MultiSelectColumnFilter
               options={programOptions}
               searchPlaceholder='Filter by Programs'
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey='assistanceProgramIds'
               filteredInfoTitle='Programs'
            />}
         />,
         dataIndex: 'programName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            const assistanceProgramIds = filteredInfo?.assistanceProgramIds?.values;
            const searchString = filteredInfo.assistanceProgramIds?.values?.find(y => y.value === record.assistanceProgramId)?.label as string;

            return <div>
               <div style={{ fontWeight: '600', color: colorWheel.darkGrey }}>{ record.regimenName}</div>
               <HighlightSearchText searchString={searchString} targetString={record.programName} />
            </div>
         }
      },
      {
         title: <ColumnFilter
            title='Submittted'
            filteredInfo={filteredInfo?.submittedDateFrom}
            content={<BasicDateRangePickerField
               label='Filter by Submitted Date'
               containerStyle={{ maxWidth: 300 }}
               defaultFromDate={filteredInfo.submittedDateFrom?.value as Date}
               defaultToDate={filteredInfo.submittedDateTo?.value as Date}
               clearable={true}
               onChangeDateRange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     submittedDateFrom: {
                        title: 'Submitted From',
                        value: e?.length > 0 ? e[0] : undefined
                     },
                     submittedDateTo: {
                        title: 'Submitted To',
                        value: e?.length > 1 ? e[1] : undefined
                     }
                  })
               }}  
            />}
         />,
         dataIndex: 'envelopeSent',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatDateLong(record.envelopeSent)
      },
      {
         title: 'Patient Signature',
         dataIndex: 'patientName',
         render: (text, record) => {
            var patientEvents = getPatientEvents(record.docuSignEvents);
            const lastEvent = getLastEvent(patientEvents); 
            return <div>
               <SignatureStatus resultEvents={patientEvents} />
               {lastEvent && 
                  <div>{lastEvent.recipientName}</div>}
         </div>}
      },
      {
         title: 'Provider Signature',
         dataIndex: 'practiceId',
         render: (text, record) => {
            var providerEvents = getProviderEvents(record.docuSignEvents);
            const lastEvent = getLastEvent(providerEvents); 

            return <div>
               <SignatureStatus resultEvents={providerEvents} />
               {lastEvent &&
                  <div>{lastEvent.recipientName}</div>}
         </div>  }
      },
      {
         title: 'Consent',
         width: '6rem',
         dataIndex: 'signForPatient',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => record.signForPatient ? 'Yes' : 'No'
      },
      {
         title: 'Signature Status',
         dataIndex: 'applicationServiceStatus',
         render: (text, record) => {
            return <RecordStatus resultEvents={record.docuSignEvents} />
         }
      },
      {
         title: <IconButton
            style={{ border: 'none' }}
            displayNoText={false}
            title='Refresh'
            onClick={async () => await mutateDocuSignRequestSearch()}
            icon={<CustomIcon type={CustomIconType.ReloadOutlined} />} />,
         fixed: 'right',
         width: '4rem',
         dataIndex: 'actions',
         render: (text, record) => {
            const isComplete = allSignaturesCompleted(record.docuSignEvents);

            const enableResend = canResend(record.docuSignEvents);
            if (isComplete || !enableResend) return;

            return <AlignItemsCenter>
               <Space>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <IconButton
                        style={{ border: 'none' }}
                        displayNoText={false}
                        title='Resend DocuSign'
                        onClick={async () => await openDocuSignOperationsDialog(record)}
                        icon={<CustomIcon type={CustomIconType.SignaturedOutlined} />} />
                  </Restricted>
               </Space>
            </AlignItemsCenter >
         }
      }
   ];
   return (
      <>
         <ApiErrorDisplay title='Api Error' keys={_keys} />

         <SearchResultsTable
            rowkey={'id'}
            onChange={handleOnChange}
            columns={columns}
            data={pagedResult?.data}
            serverSideRowCount={pagedResult?.totalRecords}
            currentPage={searchFilter.page}
            currentPageSize={searchFilter?.pageSize}
            setCurrentPageSize={newPageSize => {
               setSearchFilter({
                  ...searchFilter,
                  page: 1, //reset the page to keep us on the page
                  pageSize: Number(newPageSize)
               })
            }}
            titleText='Pending Signatures'
            fixedHeader={true}
            scrollY='calc(100vh - 250px)'
            size='small'
            loading={isLoading}
            expandedRowRender={(row: DocuSignSearchResult) => <DocuSignEventsRow docuSignRequest={row} />}
         />

         {isDocuSignOperationsDialogOpen &&
            <DocuSignOperationsDialog
               isOpen={isDocuSignOperationsDialogOpen}
               docuSignRequest={selectedDocuSignRequest}
               close={closeDocuSignOperationsDialog} />
         }
      </>
   );
}

export default DocuSignIndex;
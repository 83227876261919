import * as React from 'react';
import { Form, Row, Col, Divider, Alert } from 'antd';
import { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { BasicFieldWrapper } from '../../shared/BasicInputLibrary';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { useAssistanceProgramNameOptions } from '../../../store/program/AssistanceProgramFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import {
   xrefStatusOptions,
   PracticeServiceToApXRefViewModel,
   MAPPED_STATUS,
   PracticeServiceToApXRefBulkEditorViewModel,
} from '../../../store/xref/PracticeServiceToApXRefModel';
import {
   practiceServiceToApXRefBaseUrl,
   bulkMapPracticeServiceToApXRef
} from '../../../store/xref/PracticeServiceToApXRefFetcher';
import { useApiContext } from '../../../store/ApiContext';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormMultiSelectInput } from '../../shared/MultiSelect/FormMultiSelectInput';
import { DropdownField } from '../../shared/InputLibrary';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    bulkItemsToEdit: PracticeServiceToApXRefViewModel[]
};

const dialogContentStyle = {
    height: '580px',
    maxHeight: '95vh',
}

interface IPracticeBulkEditor {
   newRecordStatus: string;
   additionalPrograms: number[];
}

const yupPracticeServiceBulkEditorSchema = yup.object({
   newRecordStatus: yup.string().required('A Status must be selected'),
   additionalPrograms: yup.array()
      .when('newRecordStatus', {
         is: MAPPED_STATUS,
         then: () => yup.array().test('arraySize', 'At least one Assistance Program must be selected', value => value?.length > 0)
      })
})

interface IPracticeServiceBulkEditorYup extends yup.Asserts<typeof yupPracticeServiceBulkEditorSchema> { } 
const __formId = "frmPracticeServiceToApXRefBulk";
const _keysLike: string[] = [practiceServiceToApXRefBaseUrl];

const PracticeServiceToApXRefBulkEditor: React.FC<IProps> = (props) => {
   const { bulkItemsToEdit, isOpen, closeEditor } = props;

   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);

   const { control, handleSubmit, getValues, setValue, trigger, reset, formState: { errors, } } = useForm<IPracticeServiceBulkEditorYup>({
      resolver: yupResolver<yup.AnyObject>(yupPracticeServiceBulkEditorSchema),
      shouldFocusError: true,
   });
   
   React.useEffect(() => {
      reset({
         newRecordStatus: MAPPED_STATUS,
         additionalPrograms: []
      } as IPracticeBulkEditor)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [bulkItemsToEdit, isOpen]);
     
   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   const onSave = (data: IPracticeServiceBulkEditorYup) => {
      setIsFormSaving(true);
      
      const viewModel: PracticeServiceToApXRefBulkEditorViewModel = {
         sourceRecords: bulkItemsToEdit ?? [],
         additionalProgramIds: data.additionalPrograms,
         status: data.newRecordStatus
      };
      
      bulkMapPracticeServiceToApXRef(httpPost, viewModel)
         .then(handleClose)
         .catch(err => console.error('Error calling bulkMapPracticeServiceToApXRef', err))
         .finally(() => setIsFormSaving(false));
   }

   if (isFormSaving && isOpen) {
      return <Spinner message='Saving Bulk PracticeServiceToApXref...' />
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'PracticeServiceToApXref Bulk Update'}
         open={isOpen}
         size='large'
         actionButtons={
            <>
               <CancelButton onClick={() => handleClose()} loading={isFormSaving} />
               <SaveButton
                  onClick={() => ''}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving}
                  formId={__formId}
               />
            </>
         }>
         <div style={dialogContentStyle}>
            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving Bulk PracticeServiceToApXRef'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSave)}>
               {bulkItemsToEdit?.map(sourceItem => {
                  return <Row gutter={[16, 16]} key={sourceItem.id}>
                     <Col span={12}>
                        <BasicFieldWrapper
                           label='Insurance Plan'
                           field={sourceItem.insurancePlan}
                           disabled={true}
                        />
                     </Col>
                     <Col span={12}>
                        <BasicFieldWrapper
                           label='Practice'
                           field={`[${sourceItem.practiceId}] ${sourceItem.practiceName}`}
                           disabled={true}
                        />
                     </Col>
                  </Row>
               }
               )}
               <Divider> Additional XRef Records </Divider>
               <br />
               <Row gutter={[16, 16]}>
                  <Col span={4}>
                     <DropdownField
                        name='newRecordStatus'
                        label='Status'
                        placeholder={' - Select a Status -'}
                        multiple={false}
                        options={xrefStatusOptions}
                        control={control}
                        error={errors?.newRecordStatus}
                        onChange={val => {
                           trigger();
                           // Reset additionalPrograms when status not 'Mapped'
                           val as string !== MAPPED_STATUS && setValue('additionalPrograms', []);
                        }}
                        required
                        search
                        tooltip={"Selecting a Status other than 'Mapped' will clear the selected Assistance Programs"}
                     />
                  </Col>
                  <Col span={20}>
                     {getValues()?.newRecordStatus === MAPPED_STATUS &&
                        <FormMultiSelectInput
                           options={assistanceProgramNameOptions}
                           searchable
                           allowSelectAll
                           label={`Select Assistance Program(s) (${getValues()?.additionalPrograms?.length ?? 0} selected)`}
                           searchPlaceholder={'Seach for Programs'}
                           popoverTitle={'Select Assistance Program(s)'}
                           control={control}
                           name={'additionalPrograms'}
                           error={errors?.additionalPrograms}
                           customIcon={CustomIconType.PlusOutlined}
                           showRecordCount
                           trigger={trigger}
                           displayListStartingLength={200}
                           showOkButton
                           required={getValues()?.newRecordStatus === MAPPED_STATUS}
                        />
                     }

                     {getValues()?.newRecordStatus !== MAPPED_STATUS &&
                        <div style={{ paddingTop: '25px' }}>
                           <BasicFieldWrapper
                              field={<Alert
                                 type="info"
                                 message={<>Status' other than <em>Mapped</em> don't require Assistance Programs</>}
                              />}
                           />
                        </div>
                     }
                  </Col>
               </Row>
            </Form>
         </div>
      </Dialog>
   );

   return html;
}

export default PracticeServiceToApXRefBulkEditor;
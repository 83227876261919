import useSWR, { mutate } from 'swr';
import { useEffect, useMemo, useState } from 'react';
import { PracticeServiceToApXRef, PracticeServiceToApXRefBulkEditorViewModel, PracticeServiceToApXRefBulkMapProgramType, PracticeServiceToApXRefSearchFilter, PracticeServiceToApXRefViewModel } from './PracticeServiceToApXRefModel';
import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { IStatus, NameAndId, PagedList } from '../models';
import { useFetchPractice } from '../practice/PracticeFetcher';
import { createKey, mutateSearch, swrOptions } from '../../functions/swr.functions';

export const practiceServiceToApXRefBaseUrl = `api/XRef/PracticeServiceToApXRef`;
export const practiceServiceToApXRefSearchUrl = `${practiceServiceToApXRefBaseUrl}/search`;
export const bulkMap = `${practiceServiceToApXRefBaseUrl}/BulkMap`;
export const practiceServiceToApXRefBulkMapProgramTypeUrl = `${practiceServiceToApXRefBaseUrl}/BulkMapProgramType`
const practiceServiceToApXRefPracticesUrl = `${practiceServiceToApXRefBaseUrl}/GetPractices`;
const practiceServiceToApXRefAssistanceProgramsUrl = `${practiceServiceToApXRefBaseUrl}/GetAssistancePrograms`;

//keeping last key to trigger revalidate 
let _lastFetchKey: string;

export function usePracticeServiceToApXRefSearch(httpPost: TPost, request: PracticeServiceToApXRefSearchFilter):
   IStatus &
   { pagedResult: PagedList<PracticeServiceToApXRef> } {

   const params = useMemo(() => {
      return request
   }, [request]);

   _lastFetchKey = createKey(practiceServiceToApXRefSearchUrl, params);

   const fetcher = () => httpPost<PagedList<PracticeServiceToApXRef>>(practiceServiceToApXRefSearchUrl, params);

   const { data, isLoading, error } = useSWR<PagedList<PracticeServiceToApXRef>, HttpError>(
      _lastFetchKey,
      fetcher,
      { ...swrOptions,  }
   );

   return {
      pagedResult: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchXRefPractices(httpGet: TGet): IStatus & { xRefPractices: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      practiceServiceToApXRefPracticesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      xRefPractices: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchXRefAssistancePrograms(httpGet: TGet): IStatus & { xRefAssistancePrograms: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      practiceServiceToApXRefAssistanceProgramsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      xRefAssistancePrograms: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function usePracticeServiceToApXRefSearchViewModel(httpGet: TGet, httpPost: TPost, request: PracticeServiceToApXRefSearchFilter): IStatus & { pagedResult: PagedList<PracticeServiceToApXRefViewModel> } {
   const { pagedResult, isLoading, error, isSaving } = usePracticeServiceToApXRefSearch(httpPost, request);
   const { practices, isLoading: isLoading_p, error: error_p } = useFetchPractice(httpGet);

   const viewModel = useMemo(() => {
      const result: PagedList<PracticeServiceToApXRefViewModel> = {
         page: pagedResult?.page ?? -1,
         pageSize: request?.pageSize ?? 25,
         totalPages: pagedResult?.totalPages ?? 0,
         totalRecords: pagedResult?.totalRecords ?? 0,
         data: []
      };

      if (!isLoading && pagedResult && practices?.length > 0) {         

         if (pagedResult?.data?.length > 0) {
            result.data = pagedResult?.data.map((item): PracticeServiceToApXRefViewModel => {
               const p = practices?.find(y => y.id === item.practiceId);
               return {
                  ...item,
                  practiceName: p?.name,
               } as PracticeServiceToApXRefViewModel
            });
         }
      }
      return result;

   }, [isLoading, pagedResult, practices, request?.pageSize]);

   return {
      pagedResult: viewModel,
      isLoading: isLoading || isLoading_p,
      error: error ?? error_p,
      isSaving
   };
}

export const savePracticeServiceToApXRef = async (httpPost: TPost, items: PracticeServiceToApXRef[]) => {
   const result = await httpPost<PracticeServiceToApXRef[]>(practiceServiceToApXRefBaseUrl, items);
   mutateSearch(practiceServiceToApXRefSearchUrl, _lastFetchKey);
   mutate(practiceServiceToApXRefAssistanceProgramsUrl);
   mutate(practiceServiceToApXRefPracticesUrl);
   return result;
}

export const bulkMapPracticeServiceToApXRef = async (httpPost: TPost, viewModel: PracticeServiceToApXRefBulkEditorViewModel) => {
   await httpPost<PracticeServiceToApXRef[]>(bulkMap, viewModel);
   mutateSearch(practiceServiceToApXRefSearchUrl, _lastFetchKey);
   mutate(practiceServiceToApXRefAssistanceProgramsUrl);
   mutate(practiceServiceToApXRefPracticesUrl);
}

export const bulkMapProgramType = async (httpPost: TPost, mapRequest: PracticeServiceToApXRefBulkMapProgramType) => {
   await httpPost<PracticeServiceToApXRef[]>(practiceServiceToApXRefBulkMapProgramTypeUrl, mapRequest);
   mutateSearch(practiceServiceToApXRefSearchUrl, _lastFetchKey);
   mutate(practiceServiceToApXRefAssistanceProgramsUrl);
   mutate(practiceServiceToApXRefPracticesUrl);
}
import { Col, Row, Table, Space, Dropdown, MenuProps } from 'antd';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect } from 'react';
import { useUserContext } from '../../../auth/authContext';
import Restricted from '../../../auth/Restricted';
import { isInRole } from '../../../functions/auth.functions';
import { IOptionItem } from '../../../functions/option.functions';
import {
   ContentClassificationXRef, ContentClassificationXRefSearchFilter
} from '../../../store/xref/ContentClassificationXRefModel';
import { useFetchAPInsurancePayerType,
   updateContentClassificationXRefItem,
   verifyContentClassificationXRefList,
   bulkUpdateContentClassificationXRefList,
   contentClassificationBaseUrl,
   useContentClassificationXrefSearch,
   useFetchContentClassificationXRefPractices
} from '../../../store/xref/ContentClassificationXRefFetcher';
import { KnownSettings } from '../../../store/SettingsModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { BasicDropdownField, DebouncedBasicInputField, BasicRadioListField } from '../../shared/BasicInputLibrary';
import { ActionButton, SaveButton } from '../../shared/Buttons';
import { useApiContext } from '../../../store/ApiContext';
import { antSortOptions } from '../../shared/AntComponents/Table/table.functions';
import { useErrorContext } from '../../../store/ErrorContext';
import { formatPracticeName } from '../../../store/practice/PracticeModel';
import ColumnFilter from '../../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../../shared/AntComponents/Filter/FilteredInfo';
import MultiSelectColumnFilter from '../../shared/AntComponents/Filter/MultiSelectColumnFilter';
import { SearchResultsTableWithFilters } from '../../shared/AntComponents/Table/SearchResultsTableWithFilters';
import { formatDateTimeString } from '../../../functions/format.functions';

const INSURANCE_GROUP_TITLE = 'Insurance Group';
const PAYER_TITLE = 'Payer';
const PRACTICE_ID_TITLE = 'Practice ID';
const PRACTICE_NAME_TITLE = 'Practice Name';
const NEW_ONLY_TITLE = 'New Only';
const ASSIGNED_CLASS_TITLE = 'Assigned Class';
const FACILITY_GROUPING_TITLE = 'Facility Grouping'
type searchFilter = 'insuranceGroup' | 'payer' |'practiceId' | 'practiceName' | 'newOnly' | 'assignedClass' | 'facilityGrouping';
const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
   insuranceGroup: undefined,
   payer: undefined,
   practiceId: undefined,
   practiceName: undefined,
   newOnly: {
      title: NEW_ONLY_TITLE,
      value: true
   },
   assignedClass: undefined,
   facilityGrouping: undefined
}

const initSearchFilter: ContentClassificationXRefSearchFilter = {
   page: 1,
   pageSize: 25,
   sortDirection: 'desc',
   sortField: 'id',
   newOnly: true,
}

const _keysLike: string[] = [contentClassificationBaseUrl];

export const ContentClassificationXRefList: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const { userRoles } = useUserContext();
   const canUserEdit = isInRole(userRoles, [KnownSettings.ContentAdmin]);

   const [searchFilter, setSearchFilter] = React.useState<ContentClassificationXRefSearchFilter>(initSearchFilter);
   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);
   const [isSaving, setIsSaving] = React.useState(false);
   const [selectedItems, setSelectedItems] = React.useState<number[]>([]);

   // Only used for handleInsuranceClass dropdown to set disabled/loading
   const [idForInsuranceClassBeingSaved, setIdForInsuranceClassBeingSaved] = React.useState<number>(undefined)

   const { apInsurancePayerTypes } = useFetchAPInsurancePayerType(httpGet);
   const { practices } = useFetchContentClassificationXRefPractices(httpGet);
   const { pagedResult, isLoading } = useContentClassificationXrefSearch(httpPost, searchFilter);
   const [selectedInsuranceClass, setSelectedInsuranceClass] = React.useState<string>();

   React.useEffect(() => {
      removeErrors({ keysLike: _keysLike });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   React.useEffect(() => {
      const newSearchFilter = {
         ...searchFilter,
         practiceIds: filteredInfo.practiceId?.values?.map(y => y.value as number),
         practiceName: filteredInfo.practiceName?.value as string,
         payer: filteredInfo.payer?.value as string,
         insuranceGroup: filteredInfo.insuranceGroup?.value as string,
         newOnly: filteredInfo.newOnly?.value as boolean,
         assignedClass: filteredInfo.assignedClass?.value as string,
         facilityGrouping: filteredInfo.facilityGrouping?.value as string
      };
      setSearchFilter(newSearchFilter);
      // No, we don't want this to execute when searchFilter changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredInfo])

   const handleOnChange: TableProps<ContentClassificationXRef>['onChange'] = (pagination, filters, sorter, extra) => {
      const newSearchFilter = {
         ...searchFilter,
         page: pagination?.current
      }
      const theSorter = Array.isArray(sorter) ? sorter[0] as SorterResult<ContentClassificationXRef> : sorter as SorterResult<ContentClassificationXRef>;
      if (sorter) {
         
         if (sorter.hasOwnProperty('field')) {
            newSearchFilter.sortField = theSorter.field as string;
            newSearchFilter.sortDirection = theSorter.order?.indexOf('desc') > -1 ? 'desc' : 'asc';
         }
      }
      onClearBulkSelection(); //reset bulk items on page\sort
      setSearchFilter(newSearchFilter);
   }

   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);

   const apInsurancePayerTypeOptions = React.useMemo(() => {
      return apInsurancePayerTypes?.map(o => {
         return {
            label: o.name,
            value: String(o.name).toUpperCase()
         } as IOptionItem;
      });
   }, [apInsurancePayerTypes]);

   const practiceOptions = React.useMemo(() => {
      return practices?.map(y => {
         return {
            key: y.id,
            value: y.id,
            label: formatPracticeName(y.name, y.id)
         } as IOptionItem;
      });
   }, [practices]);

   const insurancePayerClassMenu = React.useMemo(() => {
      const items = apInsurancePayerTypes?.map((ip, idx) => {
         const menuProp = {
            key: idx,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={async () => {
                     //NOTE for the next dev -> using this because the stateful selectedItems does not have a value if called from this onclick
                     await setSelectedInsuranceClass(ip.name.toUpperCase());
                     //This is not very intuitive, but we need the useEffect to make this work
                     //await bulkUpdateInsuranceClasses();
                  }}>
                  {ip.name.toUpperCase()}
               </a>
            )
         };
         return menuProp;
      });
      return { items } as MenuProps;
   }, [apInsurancePayerTypes])

   useEffect(() => {
      //NOTE: need to useEffect, as the menu onClick seems to happen in another stateless dimension
      const effect = async () => {
         if (selectedItems && selectedInsuranceClass) {
            await bulkUpdateInsuranceClasses();
         }
      }
      effect();

      // eslint-disable-next-line react-hooks/exhaustive-deps      
   }, [selectedInsuranceClass, selectedItems]);

   const handleInsuranceClass = (itemId: number, value: string) => {
      setIdForInsuranceClassBeingSaved(itemId);
      let newItem: ContentClassificationXRef = {
         id: itemId,
         practiceId: undefined,
         practiceName: undefined,
         facilityGrouping: undefined,
         insuranceGroup: undefined,
         payer: undefined,
         insuranceClassStart: undefined,
         insuranceClassCurrent: value,
         patientCount: undefined
      };
      
      updateContentClassificationXRefItem(httpPost, newItem)
         .then(() => setIdForInsuranceClassBeingSaved(undefined))
         .catch(err => console.error('Error updateContentClassificationXRefItem', err));
   }

   const clickVerifyButton = () => {
      setIsSaving(true);
      verifyContentClassificationXRefList(httpPost, selectedItems)
         .then(() => {
            setSelectedItems([]);
            setSelectedRowKeys([]);
         })
         .catch(err => console.error('Error verifyContentClassificationXRefList', err))
         .finally(() => setIsSaving(false));
   }

   const bulkUpdateInsuranceClasses = async () => {
      if (selectedItems && selectedInsuranceClass) {
         await bulkUpdateContentClassificationXRefList(httpPost, selectedItems, selectedInsuranceClass)
            .then(() => {
               setSelectedItems([]);
               setSelectedRowKeys([]);
               setSelectedInsuranceClass(undefined);
            })
            .catch(err => console.error('Error bulkUpdateContentClassificationXRefList', err));
      }
   }

   const tableColumns: ColumnsType<ContentClassificationXRef> = [
      {
         title: <ColumnFilter
            title={PRACTICE_ID_TITLE}
            filteredInfo={filteredInfo?.practiceId}
            content={<MultiSelectColumnFilter
               options={practiceOptions}
               searchPlaceholder={`Filter by ${PRACTICE_ID_TITLE}`}
               searchable
               allowSelectAll
               setFilteredInfo={setFilteredInfo}
               filteredInfo={filteredInfo}
               filteredInfoKey={'practiceId'}
               filteredInfoTitle={PRACTICE_ID_TITLE}
            />}
         />,
         dataIndex: 'practiceId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.practiceId?.values.some(y => y.value === record.practiceId) ? String(text) : ''} targetString={record.practiceId?.toString()} />
      },
      {
         title: <ColumnFilter
            title={PRACTICE_NAME_TITLE}
            filteredInfo={filteredInfo?.practiceName}
            content={<DebouncedBasicInputField
               label={`Filter by ${PRACTICE_NAME_TITLE}`}
               placeholder={`Filter by ${PRACTICE_NAME_TITLE}`}
               value={filteredInfo.practiceName?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     practiceName: {
                        title: PRACTICE_NAME_TITLE,
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'practiceName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.practiceName} targetString={record.practiceName} />
      },
      {
         title: <ColumnFilter
            title={FACILITY_GROUPING_TITLE}
            filteredInfo={filteredInfo?.facilityGrouping}
            content={<DebouncedBasicInputField
               label={`Filter by ${FACILITY_GROUPING_TITLE}`}
               placeholder={`Filter by ${FACILITY_GROUPING_TITLE}`}
               value={filteredInfo.facilityGrouping?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     facilityGrouping: {
                        title: FACILITY_GROUPING_TITLE,
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'facilityGrouping',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.facilityGrouping} targetString={record.facilityGrouping} />
      },
      {
         title: <ColumnFilter
            title={INSURANCE_GROUP_TITLE}
            filteredInfo={filteredInfo?.insuranceGroup}
            content={<DebouncedBasicInputField
               label={`Filter by ${INSURANCE_GROUP_TITLE}`}
               placeholder={`Filter by ${INSURANCE_GROUP_TITLE}`}
               value={filteredInfo.insuranceGroup?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     insuranceGroup: {
                        title: INSURANCE_GROUP_TITLE,
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'insuranceGroup',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.insuranceGroup} targetString={record.insuranceGroup} />
      },
      {
         title: <ColumnFilter
            title={PAYER_TITLE}
            filteredInfo={filteredInfo?.payer}
            content={<DebouncedBasicInputField
               label={`Filter by ${PAYER_TITLE}`}
               placeholder={`Filter by ${PAYER_TITLE}`}
               value={filteredInfo.payer?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     payer: {
                        title: PAYER_TITLE,
                        value: e ? e as string : undefined
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'payerName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={searchFilter.payer} targetString={`${text}`} />
      },
      {
         title: 'Identified Class',
         dataIndex: 'insuranceClassStart',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => <div title={`id: ${record.id}`}>{text}</div>
      },
      Table.SELECTION_COLUMN,
      {
         title: 'Assigned Class',
         dataIndex: 'insuranceClassCurrent',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            return canUserEdit ? 
               <BasicDropdownField
                  containerStyle={{ margin: '0px' }}
                  name='AssignedClass'
                  multiple={false}
                  options={apInsurancePayerTypeOptions}
                  search={true}
                  clearable={false}
                  onChange={(e) => handleInsuranceClass(record.id, e as string)}
                  value={record.insuranceClassCurrent?.toUpperCase() ?? ''}
                  loading={idForInsuranceClassBeingSaved === record.id}
                  disabled={idForInsuranceClassBeingSaved === record.id}
               /> :
               <p>{record.insuranceClassCurrent?.toUpperCase() ?? ''}</p>
         },
      },
      {
         title: 'Patient Count',
         width: 140,
         dataIndex: 'patientCount',
         sorter: true,
         sortDirections: antSortOptions,
      },
      {
         title: 'Modified On',
         dataIndex: 'updatedOn',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatDateTimeString(record.updatedOn)
      },
   ];

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      //console.log('selectedRowKeys changed: ', newSelectedRowKeys);
      const arr = newSelectedRowKeys.map((y) => { return Number(y) });
      setSelectedItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   };

   const onClearBulkSelection = () => {
      setSelectedItems([]);
      setSelectedRowKeys([]);
      setSelectedInsuranceClass(undefined);
   }

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   return (
      <>
         <ApiErrorDisplay
            title='Api Error'
            keysLike={_keysLike}
         />

         <SearchResultsTableWithFilters
            loading={isLoading}
            rowkey={'id'}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            onChange={handleOnChange}
            columns={tableColumns}
            data={pagedResult?.data}
            serverSideRowCount={pagedResult?.totalRecords}
            currentPage={searchFilter.page}
            currentPageSize={searchFilter.pageSize}
            setCurrentPageSize={newPageSize => {
               setSearchFilter({
                  ...searchFilter,
                  page: 1, //reset the page to keep us on the page
                  pageSize: Number(newPageSize)
               })
            }}
            titleText='CONTENT_CLASSIFICATION_XREF'
            fixedHeader={true}
            scrollY={'calc(100vh - 425px)'}
            onFiltersClear={() => setFilteredInfo(defaultFilterValue)}
            footer={<Row justify="end">
               <Col>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <Space>
                        <ActionButton
                           buttonText='Clear Bulk Selection'
                           onClick={onClearBulkSelection}
                           disabled={selectedItems.length === 0}
                           icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                        />
                        <Dropdown disabled={selectedItems.length === 0} menu={insurancePayerClassMenu} trigger={['click']}>
                           <ActionButton
                              icon={<CustomIcon type={insurancePayerClassMenu ? CustomIconType.CaretDownFilled : CustomIconType.CaretUpFilled} />}
                              disabled={selectedItems.length === 0}
                              buttonText='Bulk Change Assigned Class'
                           ></ActionButton>
                        </Dropdown>
                        <SaveButton
                           buttonText='Verify'
                           disabled={selectedItems.length === 0}
                           hasIcon={false}
                           onClick={clickVerifyButton}
                           loading={isSaving}
                        />
                     </Space>
                  </Restricted>
               </Col>
            </Row>}
            rowSelection={rowSelection}
            size='small'
            additionalComponents={[
               <BasicRadioListField
                  name='newOnly'
                  options={[
                     { key: 1, value: 'true', label: 'New Only' } as IOptionItem,
                     { key: 0, value: 'false', label: 'Show All' } as IOptionItem
                  ]}
                  value={String(searchFilter.newOnly)}
                  onChange={(e) => setFilteredInfo({
                     ...filteredInfo,
                     newOnly: {
                        title: NEW_ONLY_TITLE,
                        value: e === 'true'
                     }
                  })}
               />,
               <BasicDropdownField
                  name='AssignedClassFilter'
                  label='Filter By Assigned Class'
                  placeholder={' - Filter By Assigned Class -'}
                  value={searchFilter.assignedClass}
                  options={apInsurancePayerTypeOptions}
                  search={true}
                  clearable={true}
                  onChange={(e) => setFilteredInfo({
                     ...filteredInfo,
                     assignedClass: {
                        title: ASSIGNED_CLASS_TITLE,
                        value: e ? e as string : undefined
                     }
                  })}
                  disabled={searchFilter.newOnly}
               />
            ]} />

      </>
   );
}
export default ContentClassificationXRefList;


import { Alert, Col, Row, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { UnsafeAppInsights } from './InsightsContext';
import { useUserContext } from './auth/authContext';
import ImageContainer from './components/shared/AntComponents/ImageContainer';
import * as React from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useFetchSetting } from './store/SettingsFetcher';
import { KnownSettings } from './store/SettingsModel';

interface IUnauthorizedProps {
   path: string
}

const Unauthorized: React.FC<IUnauthorizedProps> = (props) => {
   const { path } = props;
   const appInsights = UnsafeAppInsights;
   const { user } = useUserContext();
   const { Title } = Typography;
   const emailString: string = useFetchSetting(KnownSettings.SupportEmail);
   const phoneString: string = useFetchSetting(KnownSettings.SupportPhone);

  React.useMemo(() => {
      // Given that we should only render this component when the route doesn't have a match, tell AppInsights someone got lost
      appInsights.trackException({
         error: { name: 'Unauthorized', message: `Unauthorized, Path: ${path}, Upn: ${user.username}` },
         severityLevel: SeverityLevel.Error,
         properties: { Path: path, Upn: user.username }
      })
   }, [])

   const html = (
      <Row>
         <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }} >
            <ImageContainer imageSrc='/403_error_sign.png' styles={{ 'width': 275, padding: '1rem' }} />
         </Col>
         <Col span={14} >
            <Title>
               <div style={{ paddingTop: 20 }}>
                  Meep, meep! This is a secured area.
               </div>
            </Title>

            <Alert style={{ padding: '10px', margin: '10px 0' }}
               message={<>{`Contact our support staff at ${phoneString} or ${emailString} to resolve access issues.`}</>}
               description={<>
                  <ul>
                     <li>
                        Return to the&nbsp;
                        <Link
                           to={'/'}
                        >
                           <span>Dashboard</span>
                        </Link>
                     </li>
                  </ul>
                  <i>If you think this was a mistake, please contact support and provide them with a screenshot of this page to help us quickly identify and resolve any issues.</i>

               </>}
               type="info"
            />
            <Row gutter={[16, 16]} style={{ marginTop: '8px' }}>
               {user && <>
                  <Col span={5} style={{ textAlign: 'end' }}>
                     <Tag>User </Tag>
                  </Col>
                  <Col span={19}>
                     {user.username}
                  </Col>
               </>
               }
               <Col span={5} style={{ textAlign: 'end' }}>
                  <Tag>Location </Tag>
               </Col>
               <Col span={19}>
                  {window.location.href}
               </Col>
               {appInsights && appInsights?.context &&
                  <>
                     <Col span={5} style={{ textAlign: 'end' }}>
                        <Tag>Session Id</Tag>
                     </Col>
                     <Col span={19}>
                        <code>{appInsights.context.getSessionId()}</code>
                     </Col>
                  </>
               }
               {appInsights && appInsights?.context?.telemetryTrace &&
                  <>
                     <Col span={5} style={{ textAlign: 'end' }}>
                        <Tag>Operation Id</Tag>
                     </Col>
                     <Col span={19}>
                        <code>{appInsights.context.telemetryTrace.traceID}</code>
                     </Col>
                  </>
               }
               <Col span={5} style={{ textAlign: 'end' }}>
                  <Tag>UTC Timestamp </Tag>
               </Col>
               <Col span={19}>
                  <code>{dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}</code>
               </Col>
            </Row>
         </Col>            
      </Row>);
   return html;
}

export default Unauthorized;



import * as React from 'react';
import { Layout, Typography, Form, Row, Col, Space } from 'antd';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
   useForm, Control, FieldErrors
   //,FieldValue, FieldValues, UseFormSetValue
} from 'react-hook-form';
import { IOptionItem } from '../functions/option.functions';
import {
   InputField,
   TextAreaField,
   DropdownField,
   RadioListField,
   CheckboxField,
   DatePickerField
} from './shared/InputLibrary';
import Dialog from './Dialog';
import { SaveButton, CancelButton, EditButton, DeleteButton, ActionButton } from './shared/Buttons';
import {
   logDebug, logError, logInfo, logWarn,
   throwErrorOne, throwErrorTwo,timeoutAttempt
} from '../store/LogFetcher';
import { BasicFieldWrapper, BasicInputField } from './shared/BasicInputLibrary';
import { useApiContext } from '../store/ApiContext';
import CustomIcon, { CustomIconType } from './shared/AntComponents/CustomIcon';
import { useInsightsContext } from '../InsightsContext';
import DropDownButton from './shared/DropDownButton';
import { BasicMultiSelect } from './shared/MultiSelect/BasicMultiSelectInput';

const yupAntStylingSchema = yup.object({
   id: yup.number().notRequired(),
   name: yup.string()
      .max(256, 'Max length is 256 characters')
      .required(),
   description: yup.string()
      .max(4000, 'Max length is 4000 characters'),
   legCount: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),
   subSpecies: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),
   bornOn: yup.date().notRequired(),
   isBiter: yup.boolean()
      .required(),
   vertical: yup.number()
      .required(),
   horizontal: yup.number()
      .required(),
});

interface IAntStyling {
   id: number;
   name: string;
   description?: string;
   legCount: number;
   subSpecies: number;
   bornOn: Date;
   isBiter: boolean;
   vertical: number;
   horizontal: number;
}

const defaultAnt: IAntStyling = {
   id: 0,
   name: 'Cornelius',
   legCount: 7,
   subSpecies: 9,
   bornOn: new Date('1998-05-16'),
   isBiter: true,
   horizontal: 3,
   vertical: 3,
}

interface IAntStylingYup extends yup.Asserts<typeof yupAntStylingSchema> { }

const subSpeciesType: IOptionItem[] = [
   { value: 1, label: 'Red Imported fire ant' },
   { value: 2, label: 'Black garden ant' },
   { value: 3, label: 'Argentine ant' },
   { value: 4, label: 'Tapinoma sessile' },
   { value: 5, label: 'Pharaoh ant' },
   { value: 6, label: 'Tetramorium immigrans' },
   { value: 7, label: 'Black carpenter ant' },
   { value: 8, label: 'Formica fusca' },
   { value: 9, label: 'Yellow crazy ant' },
   { value: 10, label: 'Adetomyrma' },
   { value: 11, label: 'Amblyopone' },
   { value: 12, label: 'Casaleia' },
   { value: 13, label: 'Fulakora' },
   { value: 14, label: 'Myopopone' },
   { value: 15, label: 'Mystrium' },
   { value: 16, label: 'Onychomyrmex' },
   { value: 17, label: 'Prionopelta' },
   { value: 18, label: 'Stigmatomma' },
   { value: 19, label: 'Xymmer' },

]

const yesNoMaybe: IOptionItem[] = [
   { value: 1, label: 'Yes' },
   { value: 2, label: 'No' },
   { value: 3, label: 'Maybe' },
   { value: 4, label: 'So' }
]

const __formId = "frmTest";

const { Title, Text } = Typography;

const AntStyling: React.FC = () => {
   const { httpPost } = useApiContext();
   const { logAppInsightsError } = useInsightsContext();

   const [isGlobalErrorRunning, setIsGlobalErrorRunning] = React.useState<boolean>(false);

   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [itemToEdit, setItemToEdit] = React.useState<IAntStyling>(defaultAnt);
   const [selectedDropDownButtonOption, setSelectedDropDownButtonOption] = React.useState<IOptionItem>();

   const [isDialogOpenOk, setIsDialogOpenOk] = React.useState<boolean>(false);
   const [isDialogOpenSaveCancel, setIsDialogOpenSaveCancel] = React.useState<boolean>(false);
   const [isDialogOpenCustomButtons, setIsDialogOpenCustomButtons] = React.useState<boolean>(false);
   const [isDialogLoading, setDialogLoading] = React.useState<boolean>(false);
   const [isButtonWorking, setIsButtonWorking] = React.useState<boolean>(false);

   const [logSending, setLogSending] = React.useState<boolean>(false);
   const [logText, setLogText] = React.useState<string>(`Test from ${window.location.origin}`);

   const { Content } = Layout;

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm<IAntStylingYup>({
      // @ts-ignore
      resolver: yupResolver<yup.AnyObject>(yupAntStylingSchema),
      shouldFocusError: true,
      reValidateMode: 'onBlur',
   });

   React.useEffect(() => {
      const doReset = () => reset(defaultAnt);
      doReset();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemToEdit]);

   const onSubmit = (data: IAntStylingYup): void => {
      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as IAntStyling;
      console.log('-------------- onSubmit : IAntStylingYup --------------------')
      console.log(item);
   }


   return <Layout>
      <Content>
         <Row gutter={[16, 16]}>
            <Col>
               <br />
               <Space>
                  <ActionButton buttonText='Do The GlobalErrorDisplay' loading={logSending}
                     icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                     onClick={async () => {
                        setIsGlobalErrorRunning(true);
                        const q = throwErrorOne(httpPost);
                        const a = throwErrorTwo(httpPost);

                        await Promise.all([q, a])
                           .then(() => {
                              console.log('All jokes aside');
                           })
                           .finally(() => {
                              setIsGlobalErrorRunning(false);
                           });

                     }} />
                  <ActionButton buttonText='Throw an Error' loading={logSending}
                     icon={<CustomIcon type={CustomIconType.WarningOutlined} />}
                     onClick={() => { throw new Error('That was easy...to break') }} />

                  <ActionButton buttonText='hook the error' loading={logSending}
                     icon={<CustomIcon type={CustomIconType.WarningOutlined} />}
                     onClick={() => logAppInsightsError(new Error('That was also easy...to log'))} />
               </Space>
            </Col>
         </Row>
         <Row gutter={[16, 16]}>
            <Col>
               <BasicInputField name='LogText' label='Log Text' value={logText} onChange={(val) => setLogText(val as string)} />
            </Col>
            <Col>
               <BasicFieldWrapper label='log type'
                  field={
                     <>
                        <ActionButton buttonText='Debug' loading={logSending}
                           icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                           onClick={() => {
                              setLogSending(true);
                              logDebug(httpPost, logText)
                                 .finally(() => { setTimeout(() => setLogSending(false), 2000) })
                           }} />
                        <ActionButton buttonText='Info' loading={logSending}
                           icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                           onClick={() => {
                              setLogSending(true);
                              logInfo(httpPost, logText)
                                 .finally(() => { setTimeout(() => setLogSending(false), 2000) })
                           }} />
                        <ActionButton buttonText='Warn' loading={logSending}
                           icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                           onClick={() => {
                              setLogSending(true);
                              logWarn(httpPost, logText)
                                 .finally(() => { setTimeout(() => setLogSending(false), 2000) })
                           }} />
                        <ActionButton buttonText='Error' loading={logSending}
                           icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                           onClick={() => {
                              setLogSending(true);
                              logError(httpPost, logText)
                                 .catch((err) => console.log('request failed:', err))
                                 .finally(() => setLogSending(false))
                           }} />
                        <ActionButton
                           buttonText='Timeout Attempt'
                           loading={logSending}
                           icon={<CustomIcon type={CustomIconType.AlertOutlined} />}
                           onClick={() => {
                              const date = new Date();
                              console.log('Attempting to timeout via long running process...');
                              console.log(`Start Time: ${date.getFullYear()}/${date.getMonth()}/${date.getDay()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`);

                              setLogSending(true);
                              timeoutAttempt(httpPost)
                                 .catch((err) => console.log('Error:', err))
                                 .finally(() => {
                                    console.log('Completed finally block from button.')
                                    setLogSending(false);
                                 });
                           }} />
                     </>
                  } />
            </Col>
         </Row>

         <Row gutter={[16, 16]}>
            <Col>Sample Antd Form</Col>
            <Col>
               <EditButton buttonText='Open in Modal with "Ok" button'
                  onClick={() => {
                     setIsDialogOpenOk(true);
                     setDialogLoading(true);
                     setTimeout(() => {
                        setDialogLoading(false);
                     }, 1500);
                  }}
               />
            </Col>
            <Col>
               <EditButton buttonText='Open in Modal with Default Buttons'
                  onClick={() => {
                     setIsDialogOpenSaveCancel(true);
                     setDialogLoading(true);
                     setTimeout(() => {
                        setDialogLoading(false);
                     }, 1500);
                  }}
               />
            </Col>
            <Col>
               <EditButton buttonText='Open in Modal with Custom Buttons'
                  onClick={() => {
                     setIsDialogOpenCustomButtons(true);
                     setDialogLoading(true);
                     setTimeout(() => {
                        setDialogLoading(false);
                     }, 1500);
                  }}
               />
            </Col>
         </Row>
         <Row gutter={[16, 16]}>
            <Col>
               <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
                  <Row gutter={[16, 16]}>
                     <Col>
                        {errors && Object.keys(errors).length > 0 &&
                           <Row>
                              <Col>
                                 <Title level={5}>Please Correct the Errors</Title>
                                 <Text type="danger">Fix them</Text>
                              </Col>
                           </Row>
                        }
                     </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                     <Col>
                        <SampleForm control={control} errors={errors} />
                     </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                     <Col>
                        <ActionButton buttonText='Reset'
                           icon='undo'
                           onClick={() => {
                              reset(defaultAnt)
                           }}
                        />
                        <SaveButton buttonText='Ants'
                           formId={__formId}
                           onClick={() => null}
                           disabled={Object.keys(errors).length > 0}
                           loading={isFormSaving} />
                     </Col>
                  </Row>
               </Form>
            </Col>
         </Row>
         <Row>
            <Col>
               <BasicMultiSelect
                  options={subSpeciesType}
                  searchPlaceholder={'Search for Sub Species'}
               />
            </Col>
         </Row>
         <Row>
            <Col>
               <DropDownButton
                  buttonText='subSpecies'
                  options={subSpeciesType}
                  onClick={opt => setSelectedDropDownButtonOption(opt)}
                  title='Select Sub Species'
               />
            </Col>
            <Col>
               {selectedDropDownButtonOption && <>
                  <span> value: {selectedDropDownButtonOption.value}</span>
                  <span> label: {selectedDropDownButtonOption.label}</span>
               </>
               }
            </Col>
         </Row>
      </Content>

      <Dialog
         scrollingContent={true}
         title={'Antd Dialog Test - "Ok" 🤞'}
         open={isDialogOpenOk}
         pending={isDialogLoading}
         onClose={() => setIsDialogOpenOk(false)}
         size="large">
         <SampleForm control={control} errors={errors} />
      </Dialog>

      <Dialog
         scrollingContent={true}
         title={'Antd Dialog Test - "Save and Cancel"'}
         open={isDialogOpenSaveCancel}
         pending={isDialogLoading}
         onSave={() => setIsDialogOpenSaveCancel(false)}
         onClose={() => setIsDialogOpenSaveCancel(false)}
         size="large">
         <SampleForm control={control} errors={errors} />
      </Dialog>

      <Dialog
         scrollingContent={true}
         title={'Antd Dialog - with custom buttons'}
         open={isDialogOpenCustomButtons}
         pending={isDialogLoading}
         size="large"
         actionButtons={
            <>
               <CancelButton buttonText='Close'
                  onClick={() => {
                     setIsButtonWorking(true);
                     setTimeout(() => {
                        setIsButtonWorking(false);
                        setIsDialogOpenCustomButtons(false);
                     }, 3000);
                  }}
                  loading={isButtonWorking}
               />
               <DeleteButton buttonText='Ants'
                  onClick={() => {
                     setIsButtonWorking(true);
                     setTimeout(() => {
                        setIsButtonWorking(false);
                     }, 3000);
                  }}
                  loading={isButtonWorking} />
               <SaveButton buttonText='Ants'
                  formId={__formId}
                  onClick={() => {
                     setIsButtonWorking(true);
                     setTimeout(() => {
                        setIsButtonWorking(false);
                     }, 3000);
                  }}
                  loading={isButtonWorking} />
            </>
         }>


         <SampleForm control={control} errors={errors} />
      </Dialog>
   </Layout>
}
export default AntStyling;


interface ISampleFormProps {
   //control: Control<FieldValue<FieldValues>, any>;
   control: Control<IAntStylingYup, object>;
   //errors: FieldErrors<FieldValues>;
   errors: FieldErrors<IAntStylingYup>;
   // errors: FieldErrors<object>;
   // setValue: UseFormSetValue<FieldValue<FieldValues>>
};

const SampleForm: React.FC<ISampleFormProps> = (props) => {

   const { control, errors } = props;

   //const control = rawControl as unknown as Control<FieldValue<FieldValues>>;

   return <Row>
      <Col>
         <InputField
            control={control}
            error={errors?.name}
            name='name'
            label='Ants real name'
            required={true}
         />
         <TextAreaField
            control={control}
            error={errors?.description}
            name='description'
            label='Describe it'
            required={false}
            rows={4}
            maxLength={100}
         />
         <InputField
            control={control}
            error={errors?.legCount}
            name='legCount'
            label='Number of Legs'
            type='number'
            required={true}
         />
         <DropdownField
            control={control}
            name='subSpecies'
            label='Sub-Species'
            error={errors?.subSpecies}
            multiple={false}
            options={subSpeciesType}
            required={true}
            search={true}
            clearable={false}
         />
         <DatePickerField
            control={control}
            name='bornOn'
            label='Born On'
            required={true}
            error={errors?.bornOn}
         />
         <CheckboxField
            control={control}
            name='isBiter'
            label='Is Biter'
            error={errors?.isBiter}
            required={true}
         />
         <RadioListField
            control={control}
            name='horizontal'
            label='Align Horizontal'
            error={errors?.horizontal}
            options={yesNoMaybe}
            required={true}
         />
         <RadioListField
            control={control}
            name='vertical'
            label='Align Vertical'
            direction='vertical'
            error={errors?.vertical}
            options={yesNoMaybe}
            required={true}
         />
      </Col>
   </Row>
}
import useSWR from 'swr';
import { ContentClassificationXRefChangeLogSearchFilter, ContentClassificationXRefChangeLog, ContentClassificationChangeLogSummary } from './ContentClassificationXRefChangeLogModel';
import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { IStatus, PagedList } from '../models';
import { useMemo } from 'react';
import { createKey, swrOptions } from '../../functions/swr.functions';

export const contentClassificationRefChangeLogBaseUrl = `api/XRef/ContentClassificationXRefChangeLog`;
const contentClassificationXRefChangeLogSearchUrl = `${contentClassificationRefChangeLogBaseUrl}/Search`
const changeLogSummaryUrl = `${contentClassificationRefChangeLogBaseUrl}/GetChangeLogSummary`

//keeping last key to trigger revalidate 
let _lastFetchKey: string;

export function useContentClassificationXrefChangeLogSearch(httpPost: TPost, search: ContentClassificationXRefChangeLogSearchFilter): IStatus & { pagedResult: PagedList<ContentClassificationXRefChangeLog> } {
   const params = useMemo(() => {
      return search
   }, [search]);

   _lastFetchKey = createKey(contentClassificationXRefChangeLogSearchUrl, params);

   const fetcher = () => httpPost<PagedList<ContentClassificationXRefChangeLog>>(contentClassificationXRefChangeLogSearchUrl, params);

   const { data, isLoading, error } = useSWR<PagedList<ContentClassificationXRefChangeLog>, HttpError>(
      _lastFetchKey,
      fetcher,
      { ...swrOptions, }
   );

   return {
      pagedResult: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useGetContentClassificationChangeLogSummary(httpGet: TGet): IStatus & { changeLogSummary: ContentClassificationChangeLogSummary } {
   const { data, isLoading, error } = useSWR<ContentClassificationChangeLogSummary, HttpError>(
      changeLogSummaryUrl,
      httpGet,
      {
         ...swrOptions,
         refreshInterval: 600000, //refresh every 10 minutes
         dedupingInterval: 0     //disable deduping
      }
   );

   return {
      changeLogSummary: data,
      isLoading: isLoading,
      error: error?.message
   };
}


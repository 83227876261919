import * as React from 'react';
import { Collapse, CollapseProps, Tag, Typography } from "antd";
import { KeyValue } from "../../../store/models";
import { View_Browse_Certified, View_Browse_Uncertified, buildReportUrl, getHeadingReportPageGroupSet, getHeadingSet } from "../../../store/report/ReportFunctions";
import colorWheel from "../../../Theme/ColorWheel";
import Spinner from "../../Spinner";
import { useReports } from "../ReportContext";
import ReportCollectionMenu from './ReportCollectionMenu';
import { Link } from 'react-router-dom';

const BrowseHeadings: React.FC = () => {
   const {
      viewReports,
      headingTree,
      menuIsCollapsed,
      areReportsLoading
   } = useReports();

   const headingSet = React.useMemo(() => {
      if (viewReports?.length > 0) {
         return getHeadingSet(viewReports)
      }
   }, [viewReports]);
   
   if ((!headingTree || !headingSet) && areReportsLoading) {
      return <Spinner message={menuIsCollapsed ? undefined : 'Reports loading...'} />;
   }

   if ((!headingTree || !headingSet) && !areReportsLoading) {
      return <div style={{ paddingTop: '25px', paddingLeft: '60px' }}>
         <Tag color={colorWheel.plumPurple}>No Reports available</Tag>
      </div>;
   }

   return (
      menuIsCollapsed ?
         <BrowseHeadingsContentCollapsed headingSet={headingSet} /> :
         <BrowseHeadingsContentExpanded headingSet={headingSet} />
   );
}

export default BrowseHeadings;

interface BrowseHeadingsContentProps {
   headingSet: KeyValue[];
}


const expandedTitleStyle: React.CSSProperties = {
   textAlign: 'left',
   fontWeight: 'bold',
   maxWidth: 185,
   color: colorWheel.textGrey,
   whiteSpace: 'nowrap',
}

const BrowseHeadingsContentExpanded: React.FC<BrowseHeadingsContentProps> = (props) => {
   const { headingSet } = props;
   const {
      headingTree,
      headingReportPageGroupTree,
      menuIsCollapsed,
      currentReport
   } = useReports();

   const {
      activeViewHeadingKey,
      activeViewHeadingReportPageGroupKey
   } = React.useMemo(() => {
      let activeViewHeadingReportPageGroupKey: string = undefined;
      let activeViewHeadingKey: string = undefined;

      if (currentReport) {
         activeViewHeadingReportPageGroupKey = currentReport.viewHeadingReportPageGroupKey
         activeViewHeadingKey = currentReport.viewHeadingKey;
      }

      return {
         activeViewHeadingKey,
         activeViewHeadingReportPageGroupKey
      };
   }, [currentReport]);

   const getReportPageGroupPanels = (headingReportPageGroupKey: string) => {
      const filteredReports = headingReportPageGroupTree[headingReportPageGroupKey];

      if (!(filteredReports?.length > 0)) return;

      let items = [];

      items.push({
         style: { background: colorWheel.mediumGrey },
         key: headingReportPageGroupKey,
         label: <li>
            <Typography.Text
               title={filteredReports[0].reportPageGroup}
               ellipsis={true}
               style={expandedTitleStyle}
            >
               {filteredReports[0].reportPageGroup}
            </Typography.Text>
         </li>,
         children: <ReportCollectionMenu
            isCollapsed={menuIsCollapsed}
            reports={filteredReports}
            showBreadcrumb={false} />
      })

      return items as CollapseProps['items'];
   }

   const getHeadingPanels = (heading: KeyValue) => {
      const items = [];
      const panelItems: JSX.Element[] = [];
      const headingReportPageGroupSet: KeyValue[] = getHeadingReportPageGroupSet(headingTree[heading.key]);

      headingReportPageGroupSet.map(headingReportPageGroup => {
         panelItems.push(<div
            key={headingReportPageGroup.key}
            style={{ background: colorWheel.lightGrey }}
         >
            <Collapse
               key={headingReportPageGroup.key}
               defaultActiveKey={activeViewHeadingReportPageGroupKey}
               expandIconPosition='end'
               style={{
                  border: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',

               }}
               items={getReportPageGroupPanels(headingReportPageGroup.key)}
            />
         </div>
         )
      })

      items.push({
         key: heading.key,
         label: <Typography.Text
            title={heading.value}
            ellipsis={true}
            style={expandedTitleStyle}
         >
            {heading.value}
         </Typography.Text>,
         children: panelItems
      });
      return items as CollapseProps['items'];
   }

   return <>
      {headingSet.map(heading => {
         return (
            <Collapse
               key={heading.key}
               defaultActiveKey={activeViewHeadingKey}
               expandIconPosition='end'
               style={{
                  border: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  background: colorWheel.figmaGrey
               }}
               items={getHeadingPanels(heading)}
            />
         )
      })}
   </>;
}



const collapsedSelectedStyle: React.CSSProperties = {
   color: colorWheel.plumPurple,
   background: colorWheel.figmaGrey,
   borderLeft: `solid 4px ${colorWheel.plumPurple}`,
   cursor: 'pointer',
   padding: '4px 0 4px 6px',
   margin: '0' // override ant style
}

const collapsedNonSelectedStyle: React.CSSProperties = {
   padding: '4px 0 4px 10px',
   color: colorWheel.plumPurple,
   borderColor: colorWheel.plumPurple,
   cursor: 'pointer',
   margin: '0' // override ant style
}

const BrowseHeadingsContentCollapsed: React.FC<BrowseHeadingsContentProps> = (props) => {
   const { headingSet } = props;
   const {
      headingTree,
      headingReportPageGroupTree,
      powerBIReportId
   } = useReports();
   
   const getReportPageGroupContent = (headingReportPageGroupKey: string) => {
      const filteredReports = headingReportPageGroupTree[headingReportPageGroupKey];

      if (!(filteredReports?.length > 0)) return;

      return filteredReports.map(report => {
         return <Link
            key={report.id}
            to={buildReportUrl(
               report.isCertified ? View_Browse_Certified : View_Browse_Uncertified,
               undefined,
               undefined,
               report.id)}
            title={report.reportPageName}
         >
            <h4
               key={report.id}
               style={powerBIReportId === report.id ? collapsedSelectedStyle : collapsedNonSelectedStyle}
            >
               {report.reportPageName?.substring(0, 1)}
            </h4>
         </Link>
      })
   }

   const getHeadingContent = (heading: KeyValue): React.ReactNode => {
      const headingReportPageGroupSet: KeyValue[] = getHeadingReportPageGroupSet(headingTree[heading.key]);

      return headingReportPageGroupSet.map(headingReportPageGroup => {
         return <div key={headingReportPageGroup.key}>
            <h4 style={{
               background: colorWheel.lightGrey,
               borderBottom: `solid 1px ${colorWheel.neutral3}`,
               padding: '4px 0 4px 10px',
               fontWeight: 'bold'
            }}
               title={headingReportPageGroup.value}
               key={headingReportPageGroup.key}>
               {headingReportPageGroup.value.substring(0, 1)}
            </h4>
            {getReportPageGroupContent(headingReportPageGroup.key)}
         </div>
      });
   }

   

   return <>
      {headingSet?.map(heading => {
         return <div key={heading.key}>
            <h4 style={{
               background: colorWheel.figmaGrey,
               borderBottom: `solid 1px ${colorWheel.neutral3}`,
               padding: '4px 0 4px 10px',
               fontWeight: 'bold'
            }}
               title={heading.value}
               key={heading.key}>
               {heading.value.substring(0, 1)}
            </h4>
            {getHeadingContent(heading)}
         </div>
      })}
   </>;
}
import * as React from 'react';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { ColumnsType } from 'antd/lib/table';
import {  dateComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { antSortOptions } from '../shared/AntComponents/Table/table.functions';
import { formatDate, formatDateLong } from '../../functions/time.functions';
import { FaxAuditLogQueryResult, FaxLogViewModel } from '../../store/fax/FaxLogModels';
import { useApiContext } from '../../store/ApiContext';
import { useFaxAuditLogs } from '../../store/fax/FaxFetcher';
import TruncateText from '../shared/TruncateText';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Spinner from '../Spinner';
import { useErrorContext } from '../../store/ErrorContext';
import { DocuSignSearchResult, DocuSignSearchResultEvent } from '../../store/docusign/DocuSignRequestModels';
import FaxAuditLogRow from '../fax/FaxAuditLogRow';


interface IProps {
   docuSignRequest: DocuSignSearchResult;
}

const DocuSignEventsRow: React.FC<IProps> = (props) => {
   const { docuSignRequest } = props;

   const columns: ColumnsType<DocuSignSearchResultEvent> = [
      {
         dataIndex: 'docuSignEventId',
         title: 'Id',
         width: '8rem',
         key: 'docuSignEventId',
         sorter: (a, b) => numberComparer(a.docuSignEventId, b.docuSignEventId),
         sortDirections: antSortOptions,
      },
      {
         dataIndex: 'eventType',
         title: 'Event Type',
         key: 'eventType',
         sorter: (a, b) => stringComparer(a.eventType, b.eventType),
         sortDirections: antSortOptions,
      },
      {
         dataIndex: 'eventTime',
         title: 'Event Time',
         key: 'eventTime',
         sorter: (a, b) => dateComparer(a.eventTime, b.eventTime),
         sortDirections: antSortOptions,
         render: (text, record) => formatDateLong(record.eventTime)
     },
     
      {
         dataIndex: 'recipientRole',
         title: 'Role',
         key: 'recipientRole',
         sorter: (a, b) => stringComparer(a.recipientRole, b.recipientRole),
         sortDirections: antSortOptions,
      },
      {
         dataIndex: 'recipientName',
         title: 'Name',
         key: 'recipientName',
         sorter: (a, b) => stringComparer(a.recipientName, b.recipientName),
         sortDirections: antSortOptions
      },
      {
         dataIndex: 'recipientEmail',
         title: 'Email',
         key: 'recipientEmail',
         sorter: (a, b) => stringComparer(a.recipientEmail, b.recipientEmail),
         sortDirections: antSortOptions
      },
      {
         dataIndex: 'recipientAccessCode',
         title: 'Access Code',
         key: 'recipientAccessCode',
         sorter: (a, b) => stringComparer(a.recipientAccessCode, b.recipientAccessCode),
         sortDirections: antSortOptions
      },
   ];   

   return <MinimalistTable
      showTitle={false}
      rowKey='docuSignEventId'
      data={docuSignRequest.docuSignEvents}
      columns={columns}
      size='small'
      showHeader={true}
      hidePaging={true}

   />;
}

export default DocuSignEventsRow;
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import colorWheel from '../../Theme/ColorWheel';
import { boolComparer, numberComparer, stringComparer } from '../../functions/comparer.functions';
import { formatBoolean } from '../../functions/format.functions';
import { useApiContext } from '../../store/ApiContext';
import { updateUserMainUserProfiles, useUserMainViewModels } from '../../store/auth/UserMainFetcher';
import { UserMainViewModel } from '../../store/auth/UserMainModel';
import { useFetchPractice } from '../../store/practice/PracticeFetcher';
import { Practice, formatPracticeName } from '../../store/practice/PracticeModel';
import { useFetchUserProfiles } from '../../store/practice/UserProfileFetcher';
import { UserProfile } from '../../store/practice/UserProfileModel';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { ActionButton } from '../shared/Buttons';
import ExistingUserProfileEditor from './shared/ExistingUserProfileEditor';

interface IProps {
   searchText: string;
   practiceId?: number;
   onNewUserProfileFromCopy: (userProfileId: number, userMainId: number) => void;
   onNewUserProfileFromEmail: (userMainId: number) => void;
   onNewUserMain: (userProfileId?: number) => void;
};

interface UserMainTable extends UserMainViewModel {
   rowKey: string;
}

interface UserProfileTable extends UserProfile {
   userMainId?: number;
   userMainEmailAddress?: string;
}

const FilteredUserMainList: React.FC<IProps> = (props) => {
   const { searchText,
      practiceId,
      onNewUserProfileFromEmail,
      onNewUserProfileFromCopy,
      onNewUserMain
   } = props;

   const cleanSearchText = (searchText ?? '').trim().toLocaleLowerCase();

   const { httpGet, httpPut } = useApiContext();
   const { userMainViewModels } = useUserMainViewModels(httpGet);
   const { userProfiles } = useFetchUserProfiles(httpGet);
   const { practices } = useFetchPractice(httpGet);
   const navigate = useNavigate();

   const [userMainTable, setUserMainTable] = React.useState<UserMainTable[]>([]);
   const [filteredItems, setFilteredItems] = React.useState<UserMainTable[]>([]);
   const [selectedUserProfileId, setSelectedUserProfileId] = React.useState<number>(undefined);
   const [isUserProfileEditorOpen, setIsUserProfileEditorOpen] = React.useState<boolean>(false);
   const [practice, setPractice] = React.useState<Practice>(undefined);

   React.useEffect(() => {
      const buildTables = () => {
         if (userMainViewModels?.length > 0 && userProfiles?.length > 0) {
            const spentUserProfileIds: number[] = [];
            const _maintbl = userMainViewModels.map((m) => {
               const umt = {
                  ...m,
                  rowKey: `U_${m.id}`
               } as UserMainTable;

               if (m?.userProfileIds) spentUserProfileIds.push(...m?.userProfileIds);

               return umt;
            })

            const listOfUserProfilesNotInUsedProfileIds = userProfiles.filter(y => !spentUserProfileIds.includes(y.userProfileId));
            listOfUserProfilesNotInUsedProfileIds.map((p) => {
               const umt = {
                  userProfileIds: [p.userProfileId],
                  rowKey: `P_${p.userProfileId}`
               } as UserMainTable;

               _maintbl.push(umt);

               return umt;
            })

            setUserMainTable(_maintbl);
         }
      }
      buildTables();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userMainViewModels, userProfiles]);

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userMainViewModels, cleanSearchText]);

   React.useEffect(() => {
      if (!practiceId || !practices) return;

      setPractice(practices.find(y => y.id === practiceId));
   }, [practiceId, practices])

   const createFilteredItems = () => {
      if (!userMainTable || userMainTable.length === 0 ||
         !cleanSearchText || cleanSearchText.length === 0) return;

      let newList = userMainTable.filter(model => {
         return filterUserMain(model) ||
            filterUserProfiles(model);
      }) ?? [];
      setFilteredItems(newList);
   }

   const filterUserMain = (m: UserMainTable): boolean => {
      return cleanSearchText?.length > 0 && (
         m.upn?.toLocaleLowerCase().indexOf(cleanSearchText) > -1 ||
         m.emailAddress?.toLocaleLowerCase().indexOf(cleanSearchText) > -1
      );
   }

   const filterUserProfiles = (m: UserMainTable): boolean => {
      if ((cleanSearchText ?? '').length === 0 ||
         !m.userProfileIds || m.userProfileIds.length === 0) return false;

      const matches = userProfiles.filter(y => m.userProfileIds.includes(y.userProfileId));
      return matches.some(y => (
         y.emailAddress?.toLocaleLowerCase().indexOf(cleanSearchText) > -1 ||
         y.firstName?.toLocaleLowerCase().indexOf(cleanSearchText) > -1 ||
         y.lastName?.toLocaleLowerCase().indexOf(cleanSearchText) > -1 ||
         y.externalUpn?.toLocaleLowerCase().indexOf(cleanSearchText) > -1 ||
         y.externalFirstName?.toLocaleLowerCase().indexOf(cleanSearchText) > -1 ||
         y.externalLastName?.toLocaleLowerCase().indexOf(cleanSearchText) > -1
      ))
   }

   const onUserProfileEditorClose = () => {
      setSelectedUserProfileId(undefined);
      setIsUserProfileEditorOpen(false);
   }

   const associateUserProfileToUserMain = (userProfileId: number, userMainId: number) => {
      const userMain = userMainViewModels?.find(y => y.id === userMainId);
      const userProfileIds = userMain.userProfileIds ? [...userMain.userProfileIds, userProfileId] : [userProfileId];
      updateUserMainUserProfiles(httpPut, userMainId, userProfileIds);
   }

   const renderPracticesLink = (): JSX.Element => {
      return <a target="_blank" rel="noopener"
         onClick={() => navigate('/content-tool/PRACTICE')}>
         Practices
      </a>
   }

   // We are assuming that any 'record' needing this menu will only have 1 User Profile so keep that in mind for this function
   const renderAssociateUserProfileToUserMainMenu = (record: UserMainTable): MenuProps => {
      if (record.userProfileIds?.length > 1) throw Error(`Expecting 1 userProfileId.  Found ${record.userProfileIds.length}`);

      let items: MenuProps['items'];
      const thisUserProfile = userProfiles?.find(y => y.userProfileId === record.userProfileIds[0]);
      const userMainsMatchingEmail = userMainViewModels?.filter(y => y.emailAddress === thisUserProfile.emailAddress);

      if (!userMainsMatchingEmail || userMainsMatchingEmail?.length === 0) {
         items = [{
            key: 0,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => onNewUserMain(thisUserProfile?.userProfileId)}>
                  {`No User Mains match Email ${thisUserProfile?.emailAddress ?? '[no email address on profile]'}.  Click here to create a new User Main`}
               </a>
            )
         }]
      } else {
         items = userMainsMatchingEmail.map((userMain) => {
            return ({
               key: userMain.id,
               label: (
                  <a target="_blank" rel="noopener"
                     onClick={() => associateUserProfileToUserMain(record.userProfileIds[0], userMain.id)}>
                     {`Associate with User Main with Email ${userMain.emailAddress}`}
                  </a>
               )
            })
         });
      }

      return { items } as MenuProps;
   }

   const buildNewUserProfileMenu = (record: UserMainTable): MenuProps => {
      let items: MenuProps['items'];

      items = [{
         key: 0,
         label: (
            <a target="_blank" rel="noopener"
               onClick={() => onNewUserProfileFromEmail(record.id)}>
               {`New User Profile for ${record.emailAddress} at Practice ${formatPracticeName(practice.name, practice.id)}`}
            </a>
         )
      }];
      if (!record.userProfileIds) return { items } as MenuProps;

      record.userProfileIds.map((userProfileId) => {
         const thisUserProfile = userProfiles?.find(y => y.userProfileId === userProfileId);
         items.push({
            key: userProfileId,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => onNewUserProfileFromCopy(userProfileId, record.id)}>
                  {`Copy from Profile ID ${userProfileId} at Practice ${formatPracticeName(thisUserProfile.practiceName, thisUserProfile.practiceId)}`}
               </a>
            )

         })
      });

      return { items } as MenuProps;
   }

   const userMaincolumns: ColumnsType<UserMainTable> = [
      //{
      //   dataIndex: 'rowKey',
      //   title: 'rowKey',
      //   key: 'rowKey',
      //},
      {
         dataIndex: 'id',
         title: 'Id',
         key: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend']
      },
      {
         dataIndex: 'emailAddress',
         title: 'Email',
         key: 'emailAddress',
         sorter: (a, b) => stringComparer(a.emailAddress, b.emailAddress),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            return <>
               {!record.id && record.userProfileIds &&
                  // Not viewing from a Practice and we have UserProfiles that aren't attached to User Mains.  Maybe one exists that should match so let's present that
                  // We are assuming that any 'record' like this will only have 1 User Profile so keep that in mind for this function
                  <Dropdown menu={renderAssociateUserProfileToUserMainMenu(record)} trigger={['click']}>
                     <Button>User Main Options...</Button>
                  </Dropdown>
               }
               {record.id &&
                  <Link
                     to={{ pathname: `/user/userdetail/${record.id}` }}
                     title={`Edit User Profile for ${record.emailAddress}`}>
                     {record.emailAddress}
                  </Link>
               }
            </>
         }
      },
      {
         dataIndex: 'accessRoleIds',
         title: 'Role Count',
         key: 'accessRoleIds',
         render: (text, record) =>
            <Tag key='ccc' color={`${record?.accessRoleIds?.length >= 0 ? colorWheel.ivyGreen : colorWheel.neutral3}`}>
               {record?.accessRoleIds?.length}
            </Tag>
      },
      {
         dataIndex: 'distinctPracticeIds',
         title: 'Practices',
         key: 'distinctPracticeIds',
         render: (text, record) => <>{record?.distinctPracticeIds?.join(', ')}</>,
         ellipsis: true,
         width: '10%'
      },
      {
         dataIndex: 'isDisabled',
         title: 'Disabled',
         key: 'isDisabled',
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val)
      },
      {
         title: 'User Profile',
         dataIndex: 'userProfileIds',
         width: '60%',
         render: (text, record) => {
            const rows = userProfiles.filter(y => record.userProfileIds?.includes(y.userProfileId))
               .map((profile): UserProfileTable => {
                  return {
                     ...profile,
                     userMainId: record?.id,
                     userMainEmailAddress: record?.emailAddress
                  } as UserProfileTable
               })
            const showAddToPractice = practiceId && !rows?.some(y => y.practiceId === practiceId);
            return <>
               {showAddToPractice && record.id &&
                  <div style={{ paddingBottom: '10px' }}>
                     <Dropdown menu={buildNewUserProfileMenu(record)} trigger={['click']}>
                        <Button>Add User Profile Options...</Button>
                     </Dropdown>
                  </div>
               }
               {rows?.length > 0 &&
                  <MinimalistTable
                     showHeader={true}
                     rowKey='userProfileId'
                     columns={userProfileColumns}
                     data={rows}
                     bordered={true}
                     size='small'
                  />
               }
               {!practiceId && record.id && (!rows || rows?.length == 0) &&
                  <div style={{ paddingTop: '10px' }}>
                     To Add this User to a Practice, navigate to the {renderPracticesLink()} and utilitize the "Add New User" feature at the Practice
                     {/*(I expect this will become frustrating quickly and will need replaced)*/}
                  </div>
               }
            </>
         }
      }
   ];

   const userProfileColumns: ColumnsType<UserProfileTable> = [
      {
         dataIndex: 'userProfileId',
         title: 'Id',
         key: 'userProfileId',
         sorter: (a, b) => numberComparer(a.userProfileId, b.userProfileId),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend',
         width: '5%'
      },
      {
         dataIndex: 'practiceName',
         title: 'Practice',
         key: 'practiceName',
         width: '5%',
         render: (text, record) => {
            return <span>
               {
                  record?.practiceId === practiceId &&
                  <Tag color={`${record?.practiceId === practiceId ? colorWheel.ivyGreen : colorWheel.neutral3}`}>
                     {record?.practiceId}
                  </Tag>
               }
               {
                  record?.practiceId !== practiceId &&
                  <>{record?.practiceId}</>
               }
            </span>
         }
      },
      {
         dataIndex: 'externalUpn',
         title: 'External',
         key: 'externalFullName',
         width: '40%',
         render: (text, record) => {
            return <div style={{ backgroundColor: colorWheel.white }}>
               Email: {record.externalUpn}<br />
               Name:{record.externalFullName}
            </div>
         }
      },
      {
         dataIndex: 'emailAddress',
         title: 'Details',
         key: 'emailAddress',
         width: '40%',
         render: (text, record) => {
            return <div style={{ backgroundColor: colorWheel.white }}>
               Email: {record?.emailAddress ?? 'N/A'}<br />
               Name: {record.firstName} {record.lastName}
            </div>
         }
      },
      {
         dataIndex: 'isDisabled',
         title: 'Disabled',
         key: 'isDisabled',
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: ['ascend', 'descend'],
         width: '5%',
         render: (val) => formatBoolean(val)
      },
      {
         dataIndex: '',
         title: '',
         key: 7,
         fixed: 'right',
         width: '5%',
         render: (text, record) => {
            return <>
               {record.practiceId !== practiceId &&
                  <ActionButton
                     title='Preview'
                     icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
                     onClick={() => {
                        setSelectedUserProfileId(record.userProfileId);
                        setIsUserProfileEditorOpen(true);
                     }} />
               }
            </>
         }
      }
   ]

   return (<Space direction="vertical">

      {cleanSearchText?.length >= 0 &&
         <>
            <Title level={5}>Matching User Records</Title>
            {/* TODO: maybe a new class or rename .fund-status everywhere */}
            <MinimalistTable
               style={{ margin: 0 }}
               className='fund-status'
               rowKey='rowKey'
               titleText='User Main Records'
               columns={userMaincolumns}
               data={filteredItems}
               showHeader={true}
               size='small'
            />
            {isUserProfileEditorOpen &&
               <ExistingUserProfileEditor
                  onCloseEditor={onUserProfileEditorClose}
                  existingUserProfileId={selectedUserProfileId}
                  isEditorOpen={isUserProfileEditorOpen}
                  isEditorReadOnly={true} // No editing from here, only viewing
               />
            }
         </>}
   </Space>);
}

export default FilteredUserMainList;


import * as React from 'react';
import { DocuSignSearchResultEvent} from '../../store/docusign/DocuSignRequestModels';
import { DocuSignEventTypes } from '../../store/docusign/DocuSignHelper';
import ImageContainer from '../shared/AntComponents/ImageContainer';

import { UIStates } from '../shared/AntComponents/AntComponentsEnums';
import {
   SmallSuccessMessage, SmallWarningMessage, SmallErrorMessage
} from '../shared/AntComponents/Typography/SmallMessages';

interface IProps {
   resultEvents: DocuSignSearchResultEvent[];
}

export const SignatureStatus: React.FC<IProps> = (props) => {
   const { resultEvents } = props;


   const getIcon = (category: UIStates) => {
      switch (category) {
         case UIStates.Success:
            return <ImageContainer className='fontawesomeSuccessDefault' iconName='check' styles={{ width: 10, paddingBottom: 3 }} />
         case UIStates.Error:
            return <ImageContainer className='fontawesomeErrorDefault' iconName='octagon-exclamation' styles={{ width: 14, paddingBottom: 3 }} />
         case UIStates.Warning:
            return <ImageContainer className='fontawesomeWarningDefault' iconName='hourglass-half' styles={{ width: 11, paddingBottom: 3 }} />
         default:
            return <ImageContainer className='fontawesomeErrorDefault' iconName='octagon-exclamation' styles={{ width: 14, paddingBottom: 30 }} />
      }
   }

   const getStatusDetails = () => {
      var statusDetails = {
         icon: <ImageContainer
            iconName={'hourglass-half'}
            className={'fontawesomeWarningDefault'}
            styles={{ width: 10, paddingBottom: 3 }}
         />,
         message: <SmallWarningMessage fontWeight='semibold' textColor='warningDefault' dataAttr='awaitingSignatureMessage'>Awaiting Signature</SmallWarningMessage>
      }
      if (resultEvents?.some(e => e.eventType === DocuSignEventTypes.RecipientCompleted)) {
         statusDetails = {
            icon: getIcon(UIStates.Success),
            message: <SmallSuccessMessage fontWeight='semibold' textColor='successDefault' dataAttr='awaitingSignatureMessage'>Signed</SmallSuccessMessage>
         };
      }
      else if (resultEvents?.some(e => e.eventType === DocuSignEventTypes.RecipientDeclined)) {
         statusDetails = {
            icon: getIcon(UIStates.Error),
            message: <SmallErrorMessage fontWeight='semibold' textColor='errorDefault' dataAttr='awaitingSignatureMessage'>Declined to Sign</SmallErrorMessage>
         };
      }
      else if (resultEvents?.some(e => e.eventType === DocuSignEventTypes.RecipientAutoresponded)) {
         statusDetails = {
            icon: getIcon(UIStates.Error),
            message: <SmallErrorMessage fontWeight='semibold' textColor='errorDefault' dataAttr='awaitingSignatureMessage'>Email Failed</SmallErrorMessage>
         };
      }
      else if (resultEvents?.some(e => e.eventType === DocuSignEventTypes.RecipientAuthenticationFailed)) {
         statusDetails = {
            icon: getIcon(UIStates.Error),
            message: <SmallErrorMessage fontWeight='semibold' textColor='errorDefault' dataAttr='awaitingSignatureMessage'>Access Code Violation</SmallErrorMessage>
         };
      }
      return statusDetails;
   }

   const status = getStatusDetails();

   return <div
      style={{
         display: 'flex',
         flexDirection: 'row',
         justifyContent: 'flex-start',
         alignItems: 'center',
         gap: '8px'
      }}
   >
      {status.icon}
      {status.message}
   </div>

}


const getApplicationStatus = (docuSignEvents: DocuSignSearchResultEvent[]) => {
   return docuSignEvents.some(e => e.eventType === DocuSignEventTypes.RecipientDeclined)
      ? 'Application Off Track'
      : 'Awaiting Signature'
}

export const RecordStatus: React.FC<IProps> = (props) => {
   const { resultEvents } = props;

   const status = getApplicationStatus(resultEvents);
   switch (status.toLowerCase()) {
      case 'application off track':
         return <SmallErrorMessage fontWeight='semibold'>Application Off Track</SmallErrorMessage>;
      case 'awaiting signature':
         return <SmallWarningMessage fontWeight='semibold'>Awaiting Signature</SmallWarningMessage>;
      default:
         return <SmallWarningMessage fontWeight='semibold'>Awaiting Signature</SmallWarningMessage>
   }
}
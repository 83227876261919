import * as React from 'react';
import colorWheel from '../../../../Theme/ColorWheel';

/*
Borrowed from AP
*/
declare type WarningTextColor = 'warningDefault' | 'warningTextAltColor';

export type FontWeight = 'regular' | 'semibold' | 'bold';

export const getWeightOpenSans = (value: string) => {
   switch (value) {
      case 'regular':
         return 400
      case 'semibold':
         return 600
      case 'bold':
         return 700

      default:
         return 400
   }
}

export interface ISmallWarningMessage {
   children: string;
   textColor?: WarningTextColor;
   dataAttr?: string;
   fontWeight?: FontWeight;
}


export const SmallWarningMessage: React.FC<ISmallWarningMessage> = props => {
   const { children, textColor, dataAttr, fontWeight } = props;

   const getColor = (value: string) => {
      switch (value) {
         case 'warningDefault':
            return colorWheel.warningDefault;
         case 'warningTextAltColor':
            return colorWheel.warningTextAltColor;

         default:
            return colorWheel.warningDefault;
      }
   }

   return <p className='smallWarningMessage' style={{ fontWeight: getWeightOpenSans(fontWeight), color: getColor(textColor || 'warningDefault') }} data-warningmessage={dataAttr || 'smallWarningMessage'}>
      {children}
   </p>
}


declare type SuccessTextColor = 'successDefault' | 'successTextAltColor';

export interface ISmallSuccessMessage {
   children: string;
   textColor?: SuccessTextColor;
   dataAttr?: string;
   fontWeight?: FontWeight;
}
export const SmallSuccessMessage: React.FC<ISmallSuccessMessage> = props => {
   const { children, textColor, dataAttr, fontWeight } = props;

   const getColor = (value: string) => {
      switch (value) {
         case 'successDefault':
            return colorWheel.successDefault;
         case 'successTextAltColor':
            return colorWheel.successTextAltColor;

         default:
            return colorWheel.successDefault;
      }
   }

   return <p className='smallSuccessMessage' style={{ fontWeight: getWeightOpenSans(fontWeight), color: getColor(textColor || 'successDefault') }} data-successmessage={dataAttr || 'smallSuccessMessage'}>
      {children}
   </p>
}


declare type ErrorTextColor = 'errorDefault' | 'errorTextAltColor';

export interface ISmallErrorMessage {
   children: string;
   textColor?: ErrorTextColor;
   dataAttr?: string;
   fontWeight?: FontWeight;
}

export const SmallErrorMessage: React.FC<ISmallErrorMessage> = props => {
   const { children, textColor, dataAttr, fontWeight } = props;

   const getColor = (value: string) => {
      switch (value) {
         case 'errorDefault':
            return colorWheel.errorDefault;
         case 'errorTextAltColor':
            return colorWheel.errorTextAltColor;

         default:
            return colorWheel.errorDefault;
      }
   }

   return <p className='smallErrorMessage' style={{ fontWeight: getWeightOpenSans(fontWeight), color: getColor(textColor || 'errorDefault') }} data-errormessage={dataAttr || 'smallErrorMessage'}>
      {children}
   </p>
}
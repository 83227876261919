import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import { InputField } from '../../shared/InputLibrary';
import { CancelButton, SaveButton, DeleteButton } from '../../shared/Buttons';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useFetchGenericDrug, saveGenericDrug, deleteGenericDrug, genericDrugBaseUrl, genericDrugSaveUrl, genericDrugDeleteUrl } from '../../../store/drug/GenericDrugFetcher';
import { GenericDrug } from '../../../store/drug/GenericDrugModel';
import { useApiContext } from '../../../store/ApiContext';
import Spinner from '../../Spinner';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   genericDrugId: number
   isOpen: boolean;
   closeEditor: () => void;
};

const yupGenericDrugSchema = yup.object({
   id: yup.number()
      .notRequired(),
   genericName: yup.string()
      .max(128)
      .required('Description is required'),
});

interface IGenericDrugYup extends yup.Asserts<typeof yupGenericDrugSchema> { }
const __formId = "frmGenericDrug";

const GenericDrugEditor: React.FC<IProps> = (props) => {
   const { closeEditor, genericDrugId, isOpen } = props;
   const _keys: string[] = genericDrugId ? [genericDrugSaveUrl, genericDrugDeleteUrl(genericDrugId)] : [genericDrugSaveUrl];
   const { removeErrors } = useErrorContext();
   const { control, handleSubmit, formState: { errors }, reset, getValues } = useForm<IGenericDrugYup>({
      resolver: yupResolver<yup.AnyObject>(yupGenericDrugSchema),
      shouldFocusError: true,
   });
   const { httpPost, httpDelete, httpGet } = useApiContext();
   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState<boolean>(false);
   const { genericDrugs } = useFetchGenericDrug(httpGet);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   React.useEffect(() => {
      let resetVal = {};
      if (genericDrugId && genericDrugs?.length) {
         resetVal = genericDrugs?.find(y => y.id === genericDrugId);
      }
      reset(resetVal);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [genericDrugId, genericDrugs]);

   const onSubmit = (data: IGenericDrugYup) => {

      const item = data as unknown as GenericDrug;

      setIsFormSaving(true);
      saveGenericDrug(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Generic Drug', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      setIsFormSaving(false);
      setIsDeleteDialogOpen(false);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   // Handles deleting the genericDrug record
   const handleDelete = async () => {
      setIsDeleteDialogOpen(false);
      deleteGenericDrug(httpDelete, getValues().id)
         .then(handleClose)
         .catch(err => console.error('Error Deleting Generic Drug', err));
   }

   if (isFormSaving) return <Spinner message='Saving Generic Drug...' />


   const html = (
      <Dialog
         scrollingContent={true}
         title={`Generic Drug Editor`}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               <DeleteButton
                  disabled={!getValues()?.id || isDeleteDialogOpen}
                  onClick={() => setIsDeleteDialogOpen(true)} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={!!errors.genericName}
                  loading={isFormSaving} />
            </>
         }>
         <div>
            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }
            <ApiErrorDisplay
               title='Error saving Generic Drug'
               keys={_keys}
            />

            {/* NOTE: Form id is required on submit button since it is outside the form */}
            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               {getValues()?.id &&
                  <InputField
                     label='Id'
                     control={control}
                     name='id'
                     type='text'
                     error={errors?.id}
                     disabled={true}
                  />
               }
               <InputField
                  control={control}
                  name='genericName'
                  label='Generic Name'
                  type='text'
                  error={errors?.genericName}
                  maxLength={128}
                  required={true}
               />
            </Form>

         </div>

         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <CancelButton onClick={() => setIsDeleteDialogOpen(false)} />
                  <DeleteButton
                     disabled={isFormSaving}
                     onClick={() => handleDelete()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Generic Drug (${getValues()?.id}) ${getValues()?.genericName || ''}?`}
         </Dialog>

      </Dialog>
   )
   return html;
}

export default GenericDrugEditor;

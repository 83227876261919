import { DocuSignSearchResultEvent } from "./DocuSignRequestModels";

/*
Borrowed from AP
*/
export const DocuSignRoles = {
   Patient: 'patient',
   Provider: 'provider'
}

export const DocuSignRoleCodes = {
   DOB: 'dob',
   NPI: 'npi'
}

export const getDocuSignRoleCode = (docusignRole: string) => {
   switch (docusignRole) {
      case DocuSignRoles.Patient:
         return DocuSignRoleCodes.DOB;
      case DocuSignRoles.Provider:
         return DocuSignRoleCodes.NPI;
      default:
         return null;
   }
}

export const getDocuSignRoleDisplay = (docusignRole: string) => {
   switch (docusignRole) {
      case DocuSignRoles.Patient:
         return "Patient";
      case DocuSignRoles.Provider:
         return "Healthcare Provider";
      default:
         return !docusignRole ? null : docusignRole.charAt(0).toUpperCase() + docusignRole.toLowerCase().slice(1);
   }
}

export const getDocusignRoleCodeLabel = (docusignRole: string) => {
   switch (docusignRole) {
      case DocuSignRoles.Patient:
         return "Patient Code";
      case DocuSignRoles.Provider:
         return "Healthcare Provider Code (NPI)";
      default:
         return !docusignRole ? null : docusignRole.charAt(0).toUpperCase() + docusignRole.toLowerCase().slice(1);
   }
}

export const DocuSignEventTypes = {
   EnvelopeCompleted: "envelope-completed",
   EnvelopeResent: "envelope-resent",
   EnvelopeSent: "envelope-sent",
   EnvelopeVoided: "envelope-voided",
   RecipientAuthenticationFailed: "recipient-authenticationfailed",
   RecipientAutoresponded: "recipient-autoresponded",
   RecipientCompleted: "recipient-completed",
   RecipientDeclined: "recipient-declined",
   RecipientDelivered: "recipient-delivered",
   RecipientResent: "recipient-resent",
   RecipientSent: "recipient-sent"
}

export const getEventDescription = (docusignEventType: string) => {
   switch (docusignEventType) {
      case DocuSignEventTypes.RecipientAuthenticationFailed:
         return 'Recipient Authentication Failed';
      case DocuSignEventTypes.RecipientAutoresponded:
         return 'Recipient Auto-responded';
      case DocuSignEventTypes.RecipientCompleted:
         return 'Signature Completed';
      case DocuSignEventTypes.RecipientDeclined:
         return 'Signature Declined';
      case DocuSignEventTypes.RecipientDelivered:
         return 'Signature Request Received';
      case DocuSignEventTypes.RecipientResent:
         return 'Signature Request Re-sent';
      case DocuSignEventTypes.RecipientSent:
         return 'Signature Request Sent';
      default:
         return docusignEventType;
   }
}

const _validResendStatuses = ['Created', 'Sent', 'Delivered', 'Correct'];


export const allSignaturesCompleted = (recipients: DocuSignSearchResultEvent[]) => {
   if (recipients && recipients?.length > 0) {
      const roles = [...new Set(recipients.map(y => y.recipientRole))];
      if (roles.every(role => recipients.filter(r =>
         r.recipientRole === role &&
         r.eventType === DocuSignEventTypes.RecipientCompleted)?.length > 0)) {
         return true;
      }
   }
   return false;
}

export const recipientHasDeclined = (recipients: DocuSignSearchResultEvent[]) => {
   if (recipients && recipients?.length > 0 && recipients.some(r =>
         r.eventType === DocuSignEventTypes.RecipientDeclined)) {
         return true;
      }
   return false;
}

export const canResend = (recipients: DocuSignSearchResultEvent[]) => {
   if (allSignaturesCompleted(recipients) || recipientHasDeclined(recipients)) {
      return false;
   }
   return true;
}

export const getLastEvent = (events: DocuSignSearchResultEvent[]) => 
   events?.length > 0 ? events?.reduce((a, c?) => a.eventTime > c.eventTime ? a : c) : undefined; 

export const getPatientEvents = (events: DocuSignSearchResultEvent[]) =>
   events?.filter(dr => dr.recipientRole?.toLowerCase() === DocuSignRoles.Patient);

export const getProviderEvents = (events: DocuSignSearchResultEvent[]) =>
   events?.filter(dr => dr.recipientRole?.toLowerCase() === DocuSignRoles.Provider);

import * as React from 'react';
import {
   List, Form, Input, Select, Radio, DatePicker,
   Space, Switch, Checkbox, ColProps
} from 'antd';
import Icon from "@ant-design/icons";
import { IOptionItem } from '../../functions/option.functions';
import { colorWheel } from '../../Theme/ColorWheel';
import { minSqlDate, maxSqlDate } from '../../functions/form.validators';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import {
   formatDate, getEndOfDay, getStartOfDay, getDayjs,
   DateRangeFormat, getDateRangeString
} from 'src/functions/time.functions';
import useDebounce from '../../functions/debounce';

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

interface IPlainError {
   error?: string | string[] | undefined;
}

export type TValue = string | number | boolean;
export type TValues = TValue | (TValue)[];
export interface IBasicProps {
   name?: string;
   onChange?: (value: TValues) => void;
   onBlur?: () => void;
   label?: string;
   tooltip?: React.ReactNode;
   required?: boolean;
   disabled?: boolean;
   style?: React.CSSProperties;
   containerStyle?: React.CSSProperties;
   autoFocus?: boolean;
   clearable?: boolean;
   // All other props
   [x: string]: any;
}

interface IInputProps {
   value?: string | number;
   type?: string;
   maxLength?: number;
   placeholder?: string;
}

interface ITextAreaProps {
   value?: string | number;
   rows?: number;
   maxLength?: number;
   placeholder?: string;
   showCount?: boolean;
}

interface IDropDownProps {
   value?: string | number | boolean | (string | number | boolean)[];
   onChangeOption?: (value: TValues, option: IOptionItem | IOptionItem[]) => void;
   multiple?: boolean;
   options: IOptionItem[];
   placeholder?: string;
   search?: boolean;
   loading?: boolean;
}

interface IRadioListProps {
   value?: string | number | boolean;
   options: IOptionItem[];
   direction?: 'horizontal' | 'vertical';
}

interface ICheckboxProps {
   checked: boolean;
   size?: 'small' | 'default';
}

interface IDateProps {
   onChangeDate?: (value: Date) => void;
   value?: Date;
   dateFormat?: string;
   minDate?: Date;
   maxDate?: Date;
   placeholder?: string;
}

interface IDateRangeProps {
   onChangeDateRange?: (value: Date[]) => void;
   dateFormat?: string;
   defaultFromDate?: Date;
   defaultToDate?: Date;
}

interface ICheckboxListProps {
   value?: (string | number | boolean)[];
   onChangeOption?: (value: TValues, option: IOptionItem | IOptionItem[]) => void;
   options: IOptionItem[];
}

const labelStyles = (containerStyle: React.CSSProperties) => ({ fontWeight: 600, opacity: 0.8, ...containerStyle } as React.CSSProperties);

// interesting alternative is to float label inside input controls: https://codesandbox.io/s/antd-float-label-wrx4d
export const BasicInputField: React.FC<IBasicProps & IPlainError & IInputProps> = (props) => {
   const { error, name, value, onChange, onBlur, label, required, disabled, placeholder,
      type, maxLength, style, containerStyle, autoFocus, tooltip, clearable } = props;
   const length = maxLength ? maxLength : 524288;
   const styleProp = style ? { style: style } : {};

   return (
      <Form.Item
         style={labelStyles(containerStyle)}
         validateStatus={error ? 'error' : 'success'}
         required={required}
         label={label}
         colon={false}
         labelCol={{ span: 24 }}
         tooltip={tooltip}
      >
         <Input
            autoFocus={autoFocus}
            onChange={(changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
               onChange && onChange(changeEvent.target.value);
            }}
            onBlur={() => {
               onBlur && onBlur();
            }}
            value={value ?? ''}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            type={type ?? 'text'}
            maxLength={length}
            allowClear={clearable}
            {...styleProp}
         />
         <BasicErrorDisplay error={error} />
      </Form.Item>
   )
}

export const DebouncedBasicInputField: React.FC<IBasicProps & IPlainError & IInputProps> = (props) => {

   const { value, onChange } = props;
   const debouncedInputRef = React.useRef(null);
   const [rawValue, setRawValue] = React.useState<string|number>(value);
   const debouncedValue = useDebounce(rawValue);


   React.useEffect(() => {
      /* If we have a rawValue but we lack a value, then its likely that value's state was manipulated elsewhere to be 
         "cleared" / "reset" (likely as part of a filter reset), so let's match it.  Otherwise we'll display the prior value
         stored in rawValue even though (whats likely a filteredInfo object) value is empty */
      if (!value && rawValue) {
         setRawValue(undefined);
      }
      // we really only want this to run when value changes
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [value])

   React.useEffect(() => {
      onChange && onChange(rawValue)
      if (!debouncedValue) {
         debouncedInputRef?.current?.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [debouncedValue])

   return (<BasicInputField
      {...props}
      value={rawValue}
      clearable={true}
      onChange={(e) => setRawValue(e as string|number)}
   />)
}

export const BasicTextAreaField: React.FC<IBasicProps & IPlainError & ITextAreaProps> = (props) => {
   const { error, name, onChange, value, autoFocus,
      onBlur, label, required, disabled, placeholder,
      rows, maxLength, style, containerStyle, showCount, tooltip } = props;
   const length = maxLength ? maxLength : 524288;

   const widthStyle = { width: '100%' };
   const styleProp = { style: { ...widthStyle, ...style } };

   const { TextArea } = Input;
   return (
      <Form.Item
         style={labelStyles(containerStyle)}
         validateStatus={error ? 'error' : 'success'}
         required={required}
         label={label}
         colon={false}
         labelCol={{ span: 24 }}
         tooltip={tooltip}
      >
         <TextArea
            onChange={(changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
               onChange && onChange(changeEvent.target.value);
               return;
            }}
            onBlur={() => {
               onBlur && onBlur();
            }}
            autoFocus={autoFocus}
            value={value ?? ''}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            maxLength={length}
            rows={rows ? rows : 3}
            {...styleProp}
            showCount={showCount ? true : false}
         />
         <BasicErrorDisplay error={error} />
      </Form.Item>
   )
}

export const BasicDropdownField: React.FC<IBasicProps & IPlainError & IDropDownProps> = (props) => {
   const { error, name, value, onChange, onChangeOption, onBlur, label, required, disabled, placeholder,
      autoFocus, multiple, options, search, clearable, style, containerStyle, tooltip, loading } = props;
   const _placeHolder = placeholder ?? ((label) ? ` - Select a ${(placeholder ?? label)} -` : undefined);
   const styleProp = style ? { style: style } : {};

   const antOptions = options as unknown as Array<typeof Option>;

   const multipleProp: { mode?: 'multiple' } | undefined = multiple ? { mode: 'multiple' } : undefined;

   return (<Form.Item
      style={labelStyles(containerStyle)}
      validateStatus={error ? 'error' : 'success'}
      required={required}
      label={label}
      colon={false}
      labelCol={{ span: 24 }}
      tooltip={tooltip}
   >
      <Select
         autoFocus={autoFocus}
         id={name}
         value={value ?? (multiple ? [] : value)}
         placeholder={_placeHolder}
         options={antOptions}
         {...multipleProp}
         showSearch={search !== undefined ? search : true} // default to true
         optionFilterProp='label'
         allowClear={clearable ?? false}
         onBlur={() => {
            onBlur && onBlur();
         }}
         onChange={(value, option) => {
            onChange && onChange(value);
            if (onChangeOption) {
               if (multiple) {
                  let optionItems: IOptionItem[] = [];
                  if (option) {
                     if (Array.isArray(option)) {
                        optionItems = [...(option as unknown as IOptionItem[])]
                     } else {
                        optionItems.push(option as unknown as IOptionItem);
                     }
                  }
                  onChangeOption(value, option as unknown as IOptionItem);
               } else {
                  onChangeOption(value, option as unknown as IOptionItem);
               }
            }
         }}
         disabled={disabled}
         loading={loading}
         {...styleProp}
      />
      <BasicErrorDisplay error={error} />
   </Form.Item>
   )
}

export const BasicRadioListField: React.FC<IBasicProps & IPlainError & IRadioListProps> = (props) => {
   const { error, name, onChange, onBlur, label, required, disabled,
      autoFocus, options, style, direction, value, containerStyle, tooltip } = props;
   const styleProp = style ? { style: style } : {};

   return (<Form.Item
      style={labelStyles(containerStyle)}
      validateStatus={error ? 'error' : 'success'}
      required={required}
      label={label}
      colon={false}
      labelCol={{ span: 24 }}
      tooltip={tooltip}
   >
      <div style={{ paddingTop: 10, paddingLeft: 10 }}>
         <Radio.Group
            id={name}
            onChange={(changeEvent) => {
               onChange && onChange(changeEvent.target.value);
            }}
            value={value}
            {...styleProp}
         >
            <Space direction={direction ?? 'horizontal'} size={[0, 0]}>
               {options && options.map((option, idx) =>
                  <Radio key={option.value ?? idx}
                     autoFocus={autoFocus && idx === 0}
                     value={option.value as number | string}
                     checked={option.value === value}
                     disabled={disabled}
                  >
                     {option.label}
                  </Radio>
               )}
            </Space>
         </Radio.Group>
      </div>
      <BasicErrorDisplay error={error} />
   </Form.Item>
   )
}

export const BasicCheckboxField: React.FC<IBasicProps & IPlainError & ICheckboxProps> = (props) => {
   const { error, onChange, label, required, disabled,
      autoFocus, style, containerStyle, checked, size, tooltip } = props;
   const styleProp = style ? { style: style } : {};

   return (<Form.Item
      style={labelStyles(containerStyle)}
      validateStatus={error ? 'error' : 'success'}
      required={required}
      label={label}
      colon={false}
      labelCol={{ span: 24 }}
      tooltip={tooltip}
   >
      <div style={{ paddingTop: 10, paddingLeft: 10 }}>
         <Switch
            checked={checked}
            onChange={(checked) => {
               onChange && onChange(checked);
            }}
            autoFocus={autoFocus}
            disabled={disabled}
            size={size ?? 'default'}
            {...styleProp}
         />
      </div>
      <BasicErrorDisplay error={error} />
   </Form.Item>
   )
}

export const BasicIconField: React.FC<IBasicProps & { icon?: React.ReactNode }> = (props) => {
   const { label, required,
      style, containerStyle, tooltip } = props;

   const iconStyles = {
      color: colorWheel.white,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 2,
      fontSize: 20
   }

   return (
      <Form.Item
         style={labelStyles(containerStyle)}
         validateStatus={'success'}
         required={required}
         label={label}
         colon={false}
         labelCol={{ span: 24 }}
         tooltip={tooltip}
      >
         <Icon
            style={{
               ...iconStyles,
               ...(style ?? {})
            }}
            component={Icon}
         />
      </Form.Item>
   )
}

export const BasicDatePickerField: React.FC<IBasicProps & IPlainError & IDateProps> = (props) => {
   const { error, name, onChange, onChangeDate, onBlur, label, required, disabled, value,
      autoFocus, dateFormat, minDate, maxDate, placeholder, containerStyle, clearable, tooltip } = props;

   //'YYYY-MM-DD' is api format, 'MM/DD/YYYY' is display format - 'YYYY-MM-DD' is the api format
   const format = dateFormat ? dateFormat : 'MM/DD/YYYY';
   const minimumDate = minDate ? minDate : minSqlDate;
   const maximumDate = maxDate ? maxDate : maxSqlDate;

   return (<Form.Item
      style={labelStyles(containerStyle)}
      validateStatus={error ? 'error' : 'success'}
      required={required}
      label={label}
      colon={false}
      labelCol={{ span: 24 }}
      tooltip={tooltip}
   >
      <div className='datewrapper'>
         <DatePicker
            autoFocus={autoFocus}
            allowClear={clearable ? true : false}
            className='fullWidthDatePicker'
            disabled={disabled}
            name={name}            
            format={format}
            value={value && getDayjs(formatDate(value))}
            disabledDate={d => !d || d.isAfter(getEndOfDay(maximumDate)) || d.isBefore(getStartOfDay(minimumDate))}
            onChange={(date, dateString) => {
               onChange && onChange(dateString);
               onChangeDate && onChangeDate(date?.isValid ? date.toDate() : undefined);
            }}
            onBlur={() => {
               onBlur && onBlur();
            }}
            placeholder={placeholder}
         />
      </div>
      <BasicErrorDisplay error={error} />
   </Form.Item>
   )
}

export const BasicDateRangePickerField: React.FC<IBasicProps & IPlainError & IDateRangeProps> = (props) => {
   const { error, name, onChange,  onBlur, label, required, disabled, 
      autoFocus, placeholder, containerStyle, clearable, tooltip,
      onChangeDateRange, dateFormat, defaultFromDate, defaultToDate,  } = props;

   //'YYYY-MM-DD' is api format, 'MM/DD/YYYY' is display format - 'YYYY-MM-DD' is the api format
   const format = dateFormat ? dateFormat : DateRangeFormat;
   const _today: Date = new Date();

   //Support for Date RangePicker
   const _getSundayBeforeLast = (fromDate: Date): Date => {
      const sundayBeforeLast = new Date(fromDate);
      sundayBeforeLast.setDate(fromDate.getDate() - fromDate.getDay() - 7);
      return sundayBeforeLast;
   }

  // const fromDate = defaultFromDate ? defaultFromDate : _getSundayBeforeLast(_today);
   const fromDate = defaultFromDate ? defaultFromDate :undefined;
   const toDate = defaultToDate ? defaultToDate : _today;
   return (<Form.Item
      style={labelStyles(containerStyle)}
      validateStatus={error ? 'error' : 'success'}
      required={required}
      label={label}
      colon={false}
      labelCol={{ span: 24 }}
      tooltip={tooltip}
   >
      <div className='datewrapper'>
         <DatePicker.RangePicker
            autoFocus={autoFocus}
            allowClear={clearable ? true : false}
            className='fullWidthDatePicker'
            disabled={disabled}
            name={name}
            format={format}
            defaultValue={fromDate ? [getDayjs(fromDate), getDayjs(toDate)] : undefined}
            onChange={(e) => {
               let dateRange: Date[];
               let formattedAsString: string;

               if (e?.length === 2) {
                  dateRange = [e[0]?.toDate(), e[1]?.toDate()];
                  formattedAsString = getDateRangeString(dateRange);
               }
              
               onChange && onChange(formattedAsString);
               onChangeDateRange && onChangeDateRange(dateRange);
            }}
            onBlur={() => {
               onBlur && onBlur();
            }}
            placeholder={placeholder}
         />
      </div>
      <BasicErrorDisplay error={error} />
   </Form.Item>
   )
}

export const BasicFieldWrapper: React.FC<IBasicProps & IPlainError &
{
   field?: React.ReactNode
   labelCol?: ColProps
}> = (props) => {
   const { label, required, error, containerStyle, tooltip, field, labelCol } = props;

   return (
      <Form.Item
         style={labelStyles(containerStyle)}
         validateStatus={error ? 'error' : 'success'}
         required={required}
         label={label}
         colon={false}
         labelCol={labelCol ?? { span: 24 }}
         tooltip={tooltip}
      >
         {field &&
            <BasicFieldDisplay field={field} />
         }
         <BasicErrorDisplay error={error} />
      </Form.Item>
   )
}

export const BasicFieldDisplay: React.FC<{ style?: React.CSSProperties, field: React.ReactNode }> = (props) => {
   const { field, style } = props;
   return (
      <span style={{ color: colorWheel.graniteGrey, ...style }}>
         {field}
      </span>
   )
}

export const BasicYesInput: React.FC<IBasicProps & IPlainError & IInputProps> = (props) => {
   const { name, label, required, error, onChange, onBlur, disabled, style, containerStyle, autoFocus,
      value, type, maxLength, placeholder } = props;

   const [radioValue, setRadioValue] = React.useState(value === 'Yes' ? true : false);

   return (
      <Space direction='horizontal'>
         <BasicRadioListField

            options={[{ label: 'Yes', value: 'Yes' }, { label: 'Other Value', value: '' }]}
            label={label}
            name={name}
            value={value && value === 'Yes' ? 'Yes' : ''}
            onChange={(val) => {
               setRadioValue(val === 'Yes');
               onChange && onChange(val);
            }}
            error={null}
            direction='horizontal'
         />
         {!radioValue &&
            <BasicInputField
               onChange={onChange}
               onBlur={onBlur}
               name={`${name}_value`}
               required={required}
               maxLength={maxLength}
               placeholder={placeholder}
               label='Other'
               value={value}
               error={error}
               type={type}
            />
         }
      </Space >
   )
}

export const BasicCheckboxListField: React.FC<IBasicProps & IPlainError & ICheckboxListProps> = (props) => {
   const { error, name, value, onChange, onChangeOption, onBlur, label, required, disabled,
      options, style, containerStyle, tooltip } = props;
   const styleProp = style ? { style: style } : {};
   const antOptions = options.map(item => ({ label: item.label, value: item.value as unknown } as CheckboxOptionType));

   return (<Form.Item
      style={labelStyles(containerStyle)}
      validateStatus={error ? 'error' : 'success'}
      required={required}
      label={label}
      colon={false}
      labelCol={{ span: 24 }}
      tooltip={tooltip}
   >
      <CheckboxGroup
         name={name}
         options={antOptions}
         value={value}
         onChange={(value) => {
            onBlur && onBlur();
            onChange && onChange(value);
            if (onChangeOption) {
               const optionItems = options.filter(y => value.includes(y.value));
               onChangeOption(value, optionItems);
            }
         }}
         disabled={disabled}
         {...styleProp}
      />

      <BasicErrorDisplay error={error} />
   </Form.Item>
   )
}


export const BasicErrorDisplay: React.FC<IPlainError> = (props) => {
   const { error } = props;
   const errArr: string[] = error && Array.isArray(error) ? error as string[] : (error ? [error as string] : []);

   if (errArr.length === 0) return null;

   return <List key='error_lst'>
      {errArr.map((err, idx) => <List.Item key={`error_${idx}`}><div className='error-text'>{err}</div></List.Item>)}
   </List>
}

export const colorWheel = {
   //New UX colors

   //Neutral
   white: '#FFF',
   lightMediumGrey: '#FAFAFA',
   lightGrey: '#FBFBFB',
   mediumGrey: '#EBEBEB',
   figmaGrey: '#f7f7f7f7', // from the figma for the analytics menu redesign
   neutral3: '#BCBEC0',
   graniteGrey: '#747679',
   textGrey: '#373534',
   darkGrey: '#333333',
   black: '#000',

   //Sky Blue
   skyBlue1: '#F5FBFD',
   skyBlue2: '#CCEDF8',
   skyBlue3: '#CCEDF8',
   skyBlue: '#419BD8', //sky blue replacement
   skyBlue5: '#005777',

   //Steel Blue
   steelBlue1: '#F5F8FB',
   steelBlue2: '#3379A9',
   steelBlue: '#005893',
   steelBlue4: '#004676',
   steelBlue5: '#003E67',

   //Purple
   purple1: '#FBF8FB',
   plumPurple: '#71277A',
   purple3: '#39143D',

   //Khaki
   khaki1: '#FCFBF7',
   khakiTan: '#DED3B0',
   khaki3: '#DED3B0',
   khaki4: '#9B947B',
   khaki5: '#6F6A58',
   khaki6: '#433F35',

   //Red
   red1: '#FAE6E6',
   red2: '#EB9999',
   red: '#CC0000', //wineRed replacement
   red4: '#A30000',
   red5: '#8F0000',
   red6: '#520000',

   //Orange
   orange1: '#FEFBF8',
   tangerineOrange: '#F68C3F',
   orange3: '#D26C00',
   orange4: '#B05C03',
   orange5: '#9E5303',

   //Yellow
   yellow1: '#FFFFF5',
   yellow2: '#F5F751',
   ocherYellow: '#D9C700', //ocherYellow replacement
   yellow4: '#AC9106',
   yellow5: '#876800',

   //Green
   green1: '#F9FCF8',
   green2: '#92E998',
   ivyGreen: '#008540', //ivyGreen replacement
   green4: '#006A33',

   ///*font awesome - borrowed DesignSystemColors from AP */
   warningDefault: '#C98734',
   warningTextAltColor: '#7B4D07',
   errorDefault: '#CA3824',
   errorTextAltColor: '#7E1903',
   successDefault: '#318721',
   successTextAltColor: '#244D10',
}

export const graphColors = [
   colorWheel.steelBlue,
   colorWheel.tangerineOrange,
   colorWheel.skyBlue,
   colorWheel.plumPurple,
   colorWheel.graniteGrey,
   colorWheel.ivyGreen,
   colorWheel.textGrey,
   colorWheel.ocherYellow,
   colorWheel.red,
   colorWheel.khakiTan
]

export default colorWheel;

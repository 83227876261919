import { TPatch } from "../../functions/httpClient";

export const auth0MaintenanceBaseUrl = 'api/auth/Auth0Maintenance';
export const verifyAllUsersUrl = `${auth0MaintenanceBaseUrl}/VerifyAllUsers`;


export const verifyAllUsers = async (httpPatch: TPatch): Promise<number> => {
   let updated: number = 0;

   await httpPatch<number>(verifyAllUsersUrl)
      .then((response) => {
         updated = response;
      });

   return updated;
}

import useSWR from 'swr';
import { ContentClassificationXRef, ContentClassificationXRefSearchFilter } from './ContentClassificationXRefModel';
import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { IStatus, NameAndId, PagedList } from '../models';
import { useMemo } from 'react';
import { createKey, mutateSearch, swrOptions } from '../../functions/swr.functions';

export const contentClassificationBaseUrl = `api/XRef/ContentClassificationXRef`;
const saveInsurancePayerUrl = `${contentClassificationBaseUrl}/SavePayerClass`;
const verifyInsurancePayerUrl = `${contentClassificationBaseUrl}/VerifyList`;
const apInsuranceTypeUrl = `${contentClassificationBaseUrl}/GetAPInsuranceClassTypes`;
const bulkUpdateInsuranceClassUrl = `${contentClassificationBaseUrl}/BulkUpdateInsuranceClass`;
const insurancePayerSearchUrl = `${contentClassificationBaseUrl}/Search`
const practicesUrl = `${contentClassificationBaseUrl}/GetPractices`

//keeping last key to trigger revalidate 
let _lastFetchKey: string;

export function useContentClassificationXrefSearch(httpPost: TPost, search: ContentClassificationXRefSearchFilter): IStatus & { pagedResult: PagedList<ContentClassificationXRef> } {
   const params = useMemo(() => {
      return search
   }, [search]);

   _lastFetchKey = createKey(insurancePayerSearchUrl, params);

   const fetcher = () => httpPost<PagedList<ContentClassificationXRef>>(insurancePayerSearchUrl, params);

   const { data, isLoading, error } = useSWR<PagedList<ContentClassificationXRef>, HttpError>(
      _lastFetchKey,
      fetcher,
      { ...swrOptions, }
   );

   return {
      pagedResult: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchAPInsurancePayerType(httpGet: TGet): IStatus & { apInsurancePayerTypes: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      apInsuranceTypeUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      apInsurancePayerTypes: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchContentClassificationXRefPractices(httpGet: TGet): IStatus & { practices: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      practicesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      practices: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const updateContentClassificationXRefItem = async (httpPost: TPost, item: ContentClassificationXRef) => {
   const result = await httpPost(saveInsurancePayerUrl, item);
   mutateSearch(insurancePayerSearchUrl, _lastFetchKey);
   return result;
}

export const verifyContentClassificationXRefList = async (httpPost: TPost, idList: number[]) => {
   const result = await httpPost(verifyInsurancePayerUrl, idList);
   mutateSearch(insurancePayerSearchUrl, _lastFetchKey);
   return result;
}


export const bulkUpdateContentClassificationXRefList = async (httpPost: TPost, idList: number[], insuranceClass: string) => {
   const result = await httpPost(bulkUpdateInsuranceClassUrl, { insuranceClassCurrent: insuranceClass, idList: idList });
   mutateSearch(insurancePayerSearchUrl, _lastFetchKey);
   return result;
}
import useSWR from 'swr';
import { useMemo } from 'react';
import { ServiceCategory } from './ServiceCategoryModel';
import { HttpError, TGet } from '../../functions/httpClient';
import { IStatus } from '../models';
import { IOptionItem } from '../../functions/option.functions';
import { stringComparer } from '../../functions/comparer.functions';

export const serviceCategoryBaseUrl = `api/program/serviceCategory`;

const swrOptions = {
   refreshInterval: 0,
   revalidateOnFocus: false,
   dedupingInterval: 60000
};

export function useServiceCategories(httpGet: TGet): IStatus & { serviceCategories: ServiceCategory[] } {
   const { data, isLoading, error } = useSWR<ServiceCategory[], HttpError>(
      serviceCategoryBaseUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      serviceCategories: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useServiceCategoryOptionsVerbose(httpGet: TGet): { serviceCategoryOptions: IOptionItem[] } {
   const { serviceCategories } = useServiceCategories(httpGet);

   const options = useMemo(() => {
      if (serviceCategories?.length > 0) {

         const sorted = [...serviceCategories];
         sorted.sort((a: ServiceCategory, b: ServiceCategory) => stringComparer(String(a.initials), String(b.initials)));

         const options = sorted.map((o): IOptionItem => ({
            label: `[${o.initials}] ${o.serviceDescription}`,
            value: o.id
         } as IOptionItem));
         return options;
      }
      return [];
   }, [serviceCategories]);

   return {
      serviceCategoryOptions: options
   };
}

export function useServiceCategoryOptions(httpGet: TGet): { serviceCategoryOptions: IOptionItem[] } {
   const { serviceCategories } = useServiceCategories(httpGet);

   const options = useMemo(() => {
      if (serviceCategories?.length > 0) {

         const sorted = [...serviceCategories];
         sorted.sort((a: ServiceCategory, b: ServiceCategory) => stringComparer(String(a.initials), String(b.initials)));

         const options = sorted.map((o): IOptionItem => ({
            label: o.initials,
            value: o.id
         } as IOptionItem));
         return options;
      }
      return [];

   }, [serviceCategories]);

   return {
      serviceCategoryOptions: options
   };
}

export function useCoPayServiceCategoryOptions(httpGet: TGet): { serviceCategoryOptions: IOptionItem[] } {
   const { serviceCategories } = useServiceCategories(httpGet);

   const options = useMemo(() => {
      if (serviceCategories?.length > 0) {

         const sortedCoPay = serviceCategories.filter(y => y.serviceDescription.toLocaleLowerCase().includes('co-pay'));
         sortedCoPay.sort((a: ServiceCategory, b: ServiceCategory) => stringComparer(String(a.initials), String(b.initials)));

         const options = sortedCoPay.map((o): IOptionItem => ({
            label: o.initials,
            value: o.id
         } as IOptionItem));
         return options;
      }
      return [];

   }, [serviceCategories]);

   return {
      serviceCategoryOptions: options
   };
}
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Divider, Dropdown, Form, MenuProps, Row, Skeleton, Space, Tag } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import colorWheel from '../../../Theme/ColorWheel';
import '../../../Theme/antdesignOverrides.css';
import { getDomainFromEmail } from '../../../functions/auth.functions';
import { boolComparer, numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { validateEmail } from '../../../functions/form.validators';
import { formatBoolean } from '../../../functions/format.functions';
import { IOptionItem } from '../../../functions/option.functions';
import { useApiContext } from '../../../store/ApiContext';
import { useFetchPracticeFederation } from '../../../store/auth/PracticeFederationFetcher';
import { useUserMainViewModels } from '../../../store/auth/UserMainFetcher';
import { UserMainViewModel } from '../../../store/auth/UserMainModel';
import { CreateNewPracticeUser } from '../../../store/auth/UserModel';
import {
   approveUser,
   approveUserUrl,
   denyAccessUrl,
   sendDenyRequest,
   useFetchUserPendingAccess
} from '../../../store/auth/UserPendingAccessFetcher';
import { UserPendingAccessModel, UserPendingAccessModelStatusType } from '../../../store/auth/UserPendingAccessModel';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { formatPracticeName } from '../../../store/practice/PracticeModel';
import { useFetchUserProfiles } from '../../../store/practice/UserProfileFetcher';
import { UserProfile, formatPractice } from '../../../store/practice/UserProfileModel';
import { useFetchUserRoles, useUserRoleOptions } from '../../../store/practice/UserRoleFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Dialog from '../../Dialog';
import HighlightSearchText from '../../HighlightSearchText';
import Spinner from '../../Spinner';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import MinimalistTable from '../../shared/AntComponents/Table/MinimalistTable';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { BasicFieldWrapper } from '../../shared/BasicInputLibrary';
import { ActionButton, CancelButton, DeleteButton, SaveButton } from '../../shared/Buttons';
import { CheckboxField, DropdownField, FieldWrapper, InputField } from '../../shared/InputLibrary';
import ExistingUserProfileEditor from './ExistingUserProfileEditor';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
   isEditorOpen: boolean;
   userPendingAccessId: number;
   closeEditor: () => void;
}

const dialogContentStyle = {
   maxHeight: '90vh',
   verticalAlign: 'top',
   width: '100%',

}

const _keys: string[] = [approveUserUrl, denyAccessUrl];

const approveNewPracticeUserYup = yup.object({
   userMainId: yup.number().notRequired(),
   practiceId: yup.number().required('Practice is a required field'),
   externalObjectId: yup.string().notRequired(),
   externalNameIdentifier: yup.string().notRequired(),
   externalUpn: yup.string().notRequired(),
   firstName: yup.string().required(),
   lastName: yup.string().required(),
   title: yup.string().notRequired(),
   emailAddress: yup.string().required().test('emailAddress', 'A valid Email Address is required', value => validateEmail(value)),
   phone: yup.string().notRequired(),
   phoneExt: yup.string().notRequired(),
   fax: yup.string().notRequired(),
   isAdmin: yup.boolean().notRequired(),
   userRoles: yup.array().min(1, "At least one User Role must be selected").required("required"),
   delayInvite: yup.boolean().notRequired(),
   isFollowedByAllowed: yup.boolean().notRequired(),
});

interface IApproveNewPracticeUserYup extends yup.Asserts<typeof approveNewPracticeUserYup> { }

interface UserMainTable extends UserMainViewModel {
   rowKey: string;
}

interface UserProfileTable extends UserProfile {
   userMainId?: number;
   userMainEmailAddress?: string;
}

// "Select"ing a User Profile ultimately means that we'll overwrite its current values with what's saved in the form
enum AllowedUserProfileActions {
   NoUserProfileSelection = 0,
   SelectAndOverwriteWithProfile = 1,
   SelectAndKeepAccessRequest = 2,
   CopyFromUserProfileAndCreateNew = 3
};

const __formId = "formApproveNewUserEditor";

const ApproveNewUserEditor: React.FC<IProps> = (props) => {
   const { isEditorOpen, userPendingAccessId, closeEditor } = props;
   const { httpGet, httpPost } = useApiContext();
   const { userRoleOptions: optionsFromStore } = useUserRoleOptions(httpGet);
   const { userProfiles } = useFetchUserProfiles(httpGet);
   const { userMainViewModels } = useUserMainViewModels(httpGet);
   const { userPendingAccessList } = useFetchUserPendingAccess(httpGet, UserPendingAccessModelStatusType.Pending);
   const { practiceFederations } = useFetchPracticeFederation(httpGet);
   const { practiceOptions: practiceOptionsFromStore } = usePracticeOptions(httpGet);
   const { userRoles } = useFetchUserRoles(httpGet);
   const { removeErrors } = useErrorContext();

   // state related to the grids / search results
   const [isUserMainCollapseOpen, setIsUserMainCollapseOpen] = React.useState(false);
   const [isUserProfileCollapseOpen, setIsUserProfileCollapseOpen] = React.useState(true);
   const [areSearchesLoading, setAreSearchesLoading] = React.useState(true);
   const [userProfileAction, setUserProfileAction] = React.useState<AllowedUserProfileActions>(AllowedUserProfileActions.NoUserProfileSelection);
   const [selectedUserProfile, setSelectedUserProfile] = React.useState<UserProfile>(undefined);

   const [previewUserProfileId, setPreviewUserProfileId] = React.useState<number>(undefined);
   const [isPreviewUserProfileOpen, setIsPreviewUserProfileOpen] = React.useState<boolean>(false);
   const [hasSavedSuccessfully, setHasSavedSuccessfully] = React.useState(false);
   const [savedUserProfile, setSavedUserProfile] = React.useState<UserProfile>(undefined);
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { control, handleSubmit, formState: { errors }, reset } = useForm<IApproveNewPracticeUserYup>({
      resolver: yupResolver<yup.AnyObject>(approveNewPracticeUserYup),
      shouldFocusError: true,
   });

   const {
      userPendingAccessRecord,
      practiceOptions,
      singleMatchingPracticeId,
      hasExactUpnMatch,
      matchingUserMains,
      independentMatchingUserProfiles
   } = React.useMemo(() => {

      let userPendingAccessRecord: UserPendingAccessModel;// = {} as UserPendingAccessModel;
      let practiceOptions: IOptionItem[] = [];
      let singleMatchingPracticeId: number;
      let hasExactUpnMatch = false;
      let matchingUserMains: UserMainTable[] = [];
      let independentMatchingUserProfiles: UserProfile[] = [];
      let userMainTable: UserMainTable[] = [];

      if (userPendingAccessId && userPendingAccessList && practiceOptionsFromStore && practiceFederations) {
         userPendingAccessRecord = userPendingAccessList.find(y => y.id === userPendingAccessId);

         if (userPendingAccessRecord) {
            const emailDomain = getDomainFromEmail(userPendingAccessRecord.upn);
            const matchingPracticeFederations = practiceFederations.filter(y => y.domain === emailDomain);

            // Build PracticeOptions
            const tempPracticeOptions = [
               {
                  label: 'Practices that do not match Domain:',
                  options: practiceOptionsFromStore.filter(y => !matchingPracticeFederations.some(mp => mp?.practiceId === Number(y.value)))
               }
            ];

            if (matchingPracticeFederations?.length > 0) {
               tempPracticeOptions.unshift({
                  label: 'Practices matching Domain:',
                  options: practiceOptionsFromStore.filter(y => matchingPracticeFederations.some(mp => mp.practiceId === Number(y.value)))
               });

               if (matchingPracticeFederations.length === 1) {
                  singleMatchingPracticeId = matchingPracticeFederations[0].practiceId;
               }
            }
            practiceOptions = tempPracticeOptions;
         }


         // Build UserMainTable collections for search results
         if (userMainViewModels?.length > 0) {

            if (userProfiles?.length > 0) {
               const spentUserProfileIds: number[] = [];
               userMainTable = userMainViewModels.map((m) => {
                  const umt = {
                     ...m,
                     rowKey: `U_${m.id}`
                  } as UserMainTable;

                  if (m?.userProfileIds) spentUserProfileIds.push(...m?.userProfileIds);

                  return umt;
               })

               const listOfUserProfilesNotInUsedProfileIds = userProfiles.filter(y => !spentUserProfileIds.includes(y.userProfileId));
               listOfUserProfilesNotInUsedProfileIds.map((p) => {
                  const umt = {
                     userProfileIds: [p.userProfileId],
                     rowKey: `P_${p.userProfileId}`
                  } as UserMainTable;

                  userMainTable.push(umt);
               })
            }

            if (userPendingAccessRecord) {
               // Search for matches based on the AccessRequest
               const upnMatchingUserMain = userMainTable.find(y => y.upn?.toLocaleLowerCase() === userPendingAccessRecord.upn?.toLocaleLowerCase());
               hasExactUpnMatch = upnMatchingUserMain !== undefined;
               if (hasExactUpnMatch) {
                  setIsUserMainCollapseOpen(true);
                  setIsUserProfileCollapseOpen(false);
                  matchingUserMains = [userMainTable.find(y => y.id === upnMatchingUserMain.id)];
               } else {
                  const matchingUserMainTables: UserMainTable[] = [];
                  const userProfileIdsFromUserMains: number[] = [];
                  userMainViewModels.forEach(model => {
                     if (model.userProfileIds) userProfileIdsFromUserMains.push(...model.userProfileIds);
                     const addToMatches = filterUserMain(userPendingAccessRecord, model) || filterUserProfiles(userProfiles, userPendingAccessRecord, model);

                     if (addToMatches) {
                        const matchingUserMainTable = userMainTable.find(y => y.id === model.id);
                        if (matchingUserMainTable) matchingUserMainTables.push(matchingUserMainTable);
                     }
                  });

                  const availableMatchingUserProfiles = userProfiles.filter(y => filterIndependentUserProfiles(userPendingAccessRecord, y, userProfileIdsFromUserMains));
                  setIsUserProfileCollapseOpen(availableMatchingUserProfiles?.length > 0);

                  matchingUserMains = matchingUserMainTables;
                  independentMatchingUserProfiles = availableMatchingUserProfiles;
               }
            }
         }
         setAreSearchesLoading(false);
      }

      return { userPendingAccessRecord, practiceOptions, singleMatchingPracticeId, hasExactUpnMatch, matchingUserMains, independentMatchingUserProfiles, userMainTable };

      // for "filterUserProfiles"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userPendingAccessId,
      userPendingAccessList,
      userMainViewModels,
      userProfiles,
      practiceFederations,
      practiceOptionsFromStore])

   React.useEffect(() => {
      let resetValue: CreateNewPracticeUser;

      if (!userPendingAccessRecord || !practiceOptions) return;

      if (selectedUserProfile && (
         userProfileAction === AllowedUserProfileActions.SelectAndOverwriteWithProfile ||
         userProfileAction === AllowedUserProfileActions.CopyFromUserProfileAndCreateNew)) {
         resetValue = {
            externalUpn: userPendingAccessRecord.upn,
            emailAddress: selectedUserProfile.emailAddress,
            firstName: selectedUserProfile.firstName,
            lastName: selectedUserProfile.lastName,
            title: selectedUserProfile.title,
            phone: selectedUserProfile.phone,
            phoneExt: selectedUserProfile.phoneExt,
            userRoles: selectedUserProfile.userRoles,
            isFollowedByAllowed: selectedUserProfile.isFollowedByAllowed,
            isAdmin: selectedUserProfile.isAdmin
         } as CreateNewPracticeUser;
      } else {
         resetValue = {
            userPendingAccessId: userPendingAccessRecord.id,
            isFollowedByAllowed: true,
            firstName: userPendingAccessRecord.firstName,
            lastName: userPendingAccessRecord.lastName,
            title: userPendingAccessRecord.titleOrRole,
            phone: userPendingAccessRecord.phone,
            externalUpn: userPendingAccessRecord.upn,
            emailAddress: userPendingAccessRecord.emailAddress,
            delayInvite: true
         } as CreateNewPracticeUser;
      }

      // If we've selected a User Profile (not copied!) then we're disabling the Practice dropdown and using the current practice
      // As well as assigning the UserProfileId so that we don't create a new one
      if (userProfileAction === AllowedUserProfileActions.SelectAndKeepAccessRequest ||
         userProfileAction === AllowedUserProfileActions.SelectAndOverwriteWithProfile) {
         resetValue.practiceId = selectedUserProfile.practiceId;
         resetValue.overwriteUserProfileId = selectedUserProfile.userProfileId;

         // If we haven't selected a User Profile and a single Practice matches the Access Request domain then we'll pre-select that while allowing the User to change it
      } else if (singleMatchingPracticeId) {
         resetValue.practiceId = singleMatchingPracticeId;
      }

      reset(resetValue);
      // for "reset"
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [userPendingAccessRecord,
      practiceOptions,
      selectedUserProfile,
      userProfileAction,
      singleMatchingPracticeId]);

   const handleClose = () => {
      setSelectedUserProfile(undefined);
      setHasSavedSuccessfully(false);
      setUserProfileAction(AllowedUserProfileActions.NoUserProfileSelection);
      setSavedUserProfile(undefined);
      removeErrors({ keys: _keys });
      closeEditor();
   }

   const handleDismissAsDuplicate = () => {
      setIsFormSaving(true);
      sendDenyRequest(httpPost,
         {
            pendingUserId: userPendingAccessRecord.id,
            statusReason: 'Duplicate'
         })
         .then(handleClose)
         .catch(err => console.error('Error Denying User', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const renderUserRoles = (userModel: UserProfile): React.ReactNode => {
      if (userModel?.userRoles?.length > 0) {
         const returnArr: JSX.Element[] = [];
         let index = 1;
         userModel.userRoles.forEach(r => {
            returnArr.push(
               <div key={`${userModel.userProfileId}-${index}`}>
                  {`${userRoles?.find(role => r === role.userRoleId).roleName}${index === userModel.userRoles.length ? '' : ','} `}
               </div>);
            index++;
         })
         return returnArr;
      } else {
         return <></>;
      }
   }

   const onPreviewUserClose = () => {
      setPreviewUserProfileId(undefined);
      setIsPreviewUserProfileOpen(false);
   }

   const onSubmit = (data: IApproveNewPracticeUserYup): void => {
      const userToApprove = data as unknown as CreateNewPracticeUser;

      const callApiToApproveUser = () => {
         setIsFormSaving(true);

         approveUser(httpPost, userToApprove)
            .then((userProfile) => {
               setHasSavedSuccessfully(true);
               setSavedUserProfile(userProfile);
            })
            .catch(err => console.error('Error Approving User', err))
            .finally(() => {
               setIsFormSaving(false);
            })
      }

      if (selectedUserProfile &&
         (userProfileAction === AllowedUserProfileActions.SelectAndKeepAccessRequest || userProfileAction === AllowedUserProfileActions.SelectAndOverwriteWithProfile) &&
         !isDataBeingSubmittedSameAsSelected(userToApprove)) {
         if (confirm(`Are you sure you want to overwrite ${selectedUserProfile.lastName}, ${selectedUserProfile.firstName} | ${formatPractice(selectedUserProfile)}`)) {
            callApiToApproveUser();
         }
      } else {
         callApiToApproveUser();
      }
   }

   const isDataBeingSubmittedSameAsSelected = (submittingData: CreateNewPracticeUser): boolean => {
      const areUserRolesSame = (): boolean => {
         if (selectedUserProfile.userRoles?.length === submittingData.userRoles?.length) {
            let innerCheck = true;

            for (var c = 0; c++; c < selectedUserProfile.userRoles.length) {
               if (!submittingData.userRoles.includes(selectedUserProfile.userRoles[c])) {
                  innerCheck = false;
                  break;
               }
            }

            return innerCheck;

         } else {
            return false;
         }
      }

      return selectedUserProfile &&
         selectedUserProfile.practiceId === submittingData.practiceId &&
         selectedUserProfile.emailAddress === submittingData.emailAddress &&
         selectedUserProfile.firstName === submittingData.firstName &&
         selectedUserProfile.lastName === submittingData.lastName &&
         selectedUserProfile.title === submittingData.title &&
         selectedUserProfile.phone === submittingData.phone &&
         selectedUserProfile.phoneExt === submittingData.phoneExt &&
         selectedUserProfile.fax === submittingData.fax &&
         selectedUserProfile.isAdmin === submittingData.isAdmin &&
         selectedUserProfile.isFollowedByAllowed === submittingData.isFollowedByAllowed &&
         areUserRolesSame();

   }

   const getSelectedUserProfileHeader = () => {
      if (selectedUserProfile) {
         if (userProfileAction === AllowedUserProfileActions.SelectAndOverwriteWithProfile ||
            userProfileAction === AllowedUserProfileActions.SelectAndKeepAccessRequest) {
            return 'Selected Profile:';
         } else if (userProfileAction === AllowedUserProfileActions.CopyFromUserProfileAndCreateNew) {
            return 'Copied Profile:';
         }
      } else {
         return 'Select an Independent User Profile above';
      }
   }

   const rowGutter: Gutter = 4;
   const fieldColSize = 3;
   const accessRequestColSize = 6;
   const selectedUserProfileColSize = 6;
   const inputFieldColSize = 8;
   const rowStyle = { borderTop: `solid 1px ${colorWheel.mediumGrey}`, padding: 1 };
   const fieldColStyle = { background: `${colorWheel.mediumGrey}` };
   const bottomMarginZero = { marginBottom: '0px' };

   const html = (
      <>
         <Dialog
            scrollingContent={true}
            title={`Approve New Practice User: ${userPendingAccessRecord?.upn}`}
            open={isEditorOpen}
            size='fullscreen'
            style={dialogContentStyle}
            actionButtons={
               <>
                  {hasSavedSuccessfully &&
                     <ActionButton buttonText={'Close'} onClick={handleClose} />
                  }
                  {!hasSavedSuccessfully &&
                     <Space>
                        <CancelButton disabled={isFormSaving} onClick={handleClose} />
                        <SaveButton
                           formId={__formId}
                           disabled={Object.keys(errors).length > 0 || hasExactUpnMatch}
                           loading={isFormSaving}
                           onClick={() => null} />
                        {hasExactUpnMatch &&
                           <ActionButton
                              key={'dialog-button-dismiss'}
                              buttonText='Dismiss as Duplicate'
                              onClick={handleDismissAsDuplicate}
                           />
                        }
                     </Space>
                  }
               </>
            }>

            {/*<div style={dialogContentStyle} className="site-drawer-render-in-current-wrapper">
              ^ This one has the class to support the Antd drawer functionality */}
            <div style={dialogContentStyle} className="approve-new-user-dialog">
               {/* NOTE: 'approve-new-user-dialog' is also used as part of the antDesignOverrides.css to override certain
                * form styles in this dialog from ant to help remove padding / condense the form */}

               {errors && Object.keys(errors).length > 0 && <>
                  <TitleError
                     text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
               }

               <ApiErrorDisplay
                  title='Error saving User'
                  keys={_keys}
               />

               {isFormSaving &&
                  <Spinner message={'Please do not attempt to refresh this page or navigate away while saving.  This dialog will close when saving is completed.'} />
               }

               {hasSavedSuccessfully && savedUserProfile &&
                  <>
                     <h3>New User Approved and Saved!</h3>
                     <p>Click the link below to navigate to the User you just created, or click "Close" below to return to the Dashboard</p>
                     <Link
                        to={{
                           pathname: `/practice/${savedUserProfile.practiceId}/user/${savedUserProfile.userProfileId}`
                        }}
                        title={`Navigate to ${savedUserProfile.lastName}, ${savedUserProfile.firstName}`}>
                        {`${savedUserProfile.lastName}, ${savedUserProfile.firstName}`}
                     </Link>
                  </>
               }

               {!hasSavedSuccessfully &&
                  <>

                     <div>
                        <UserMainPreview
                           userPendingAccessRecord={userPendingAccessRecord}
                           areSearchesLoading={areSearchesLoading}
                           setPreviewUserProfileId={setPreviewUserProfileId}
                           setIsPreviewUserProfileOpen={setIsPreviewUserProfileOpen}
                           matchingUserMains={matchingUserMains}
                           userProfiles={userProfiles}
                           hasExactUpnMatch={hasExactUpnMatch}
                           handleDismissAsDuplicate={handleDismissAsDuplicate}
                           isUserMainCollapseOpen={isUserMainCollapseOpen}
                           setIsUserMainCollapseOpen={setIsUserMainCollapseOpen}
                        />
                        <UserProfilePreview
                           userPendingAccessRecord={userPendingAccessRecord}
                           areSearchesLoading={areSearchesLoading}
                           setPreviewUserProfileId={setPreviewUserProfileId}
                           setIsPreviewUserProfileOpen={setIsPreviewUserProfileOpen}
                           selectedUserProfile={selectedUserProfile}
                           setSelectedUserProfile={setSelectedUserProfile}
                           setUserProfileAction={setUserProfileAction}
                           independentMatchingUserProfiles={independentMatchingUserProfiles}
                           isUserProfileCollapseOpen={isUserProfileCollapseOpen}
                           setIsUserProfileCollapseOpen={setIsUserProfileCollapseOpen}
                        />

                        <Divider>Create New User Below</Divider>

                        <div style={{ maxHeight: '35vh', overflowY: 'auto' }}>
                           <Form id={__formId} onFinish={handleSubmit(onSubmit)} style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                              <Row gutter={rowGutter} style={{ background: `${colorWheel.mediumGrey}` }}>
                                 <Col span={fieldColSize}>
                                    <BasicFieldWrapper label='Field' />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper label='Access Request Information' />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    <BasicFieldWrapper
                                       containerStyle={bottomMarginZero}
                                       label={getSelectedUserProfileHeader()}
                                       field={
                                          selectedUserProfile &&
                                          <Tag style={{ backgroundColor: `${colorWheel.yellow2}` }} >
                                             {selectedUserProfile.lastName}, {selectedUserProfile.firstName} | {formatPractice(selectedUserProfile)}
                                          </Tag>
                                       } />
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <BasicFieldWrapper
                                       containerStyle={bottomMarginZero}
                                       label='New User Information'
                                       field={(userProfileAction === AllowedUserProfileActions.SelectAndOverwriteWithProfile
                                          || userProfileAction === AllowedUserProfileActions.SelectAndKeepAccessRequest) &&
                                          <Tag style={{ backgroundColor: `${colorWheel.yellow2}` }}>
                                             <b>NOTE:</b> Overwriting Selected User Profile
                                          </Tag>
                                       } />
                                 </Col>
                              </Row>

                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'UPN:'} required={true} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper
                                       name='upnRequest'
                                       disabled={true}
                                       field={userPendingAccessRecord?.upn}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='externalUpn'
                                          disabled={true}
                                          field={selectedUserProfile.externalUpn}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize} >
                                    <InputField
                                       control={control}
                                       error={errors?.externalUpn}
                                       name='externalUpn'
                                       disabled={true}
                                       required={true}
                                       tooltip={'UPN is provided from the external authority and cannot be changed.'}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Practice:'} required={true} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper
                                       name='practiceRequest'
                                       disabled={true}
                                       field={userPendingAccessRecord?.requestedPracticeName}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='practiceId'
                                          disabled={true}
                                          field={formatPractice(selectedUserProfile)}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <DropdownField
                                       containerStyle={bottomMarginZero}
                                       control={control}
                                       error={errors?.practiceId}
                                       name={'practiceId'}
                                       options={practiceOptions}
                                       disabled={userProfileAction === AllowedUserProfileActions.SelectAndKeepAccessRequest || userProfileAction === AllowedUserProfileActions.SelectAndOverwriteWithProfile}
                                       search
                                       required={true}
                                       tooltip={'Please select a Practice.  Practices matching the Domain are grouped separately from those that do not'}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Email:'} required={true} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper
                                       name='emailRequest'
                                       disabled={true}
                                       field={userPendingAccessRecord?.emailAddress}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='externalUpn'
                                          disabled={true}
                                          field={selectedUserProfile.emailAddress}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <InputField
                                       control={control}
                                       error={errors?.emailAddress}
                                       name='emailAddress'
                                       required={true}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'First Name:'} required={true} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper
                                       name='firstNameRequest'
                                       disabled={true}
                                       field={userPendingAccessRecord?.firstName}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='firstName'
                                          disabled={true}
                                          field={selectedUserProfile.firstName}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <InputField
                                       control={control}
                                       error={errors?.firstName}
                                       name='firstName'
                                       required={true}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Last Name:'} required={true} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper
                                       name='lastNameRequest'
                                       disabled={true}
                                       field={userPendingAccessRecord?.lastName}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='lastName'
                                          disabled={true}
                                          field={selectedUserProfile.lastName}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <InputField
                                       control={control}
                                       error={errors?.lastName}
                                       name='lastName'
                                       required={true}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Title:'} required={true} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper
                                       name='titleRequest'
                                       disabled={true}
                                       field={userPendingAccessRecord?.titleOrRole}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='title'
                                          disabled={true}
                                          field={selectedUserProfile.title}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <InputField
                                       control={control}
                                       error={errors?.title}
                                       name='title'
                                       required={true}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'phone:'} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize}>
                                    <BasicFieldWrapper
                                       name='phoneRequest'
                                       disabled={true}
                                       field={userPendingAccessRecord?.phone}
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='phone'
                                          disabled={true}
                                          field={selectedUserProfile.phone}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <InputField
                                       control={control}
                                       error={errors?.phone}
                                       name='phone'
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Phone Ext:'} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize} />
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='phoneExt'
                                          disabled={true}
                                          field={selectedUserProfile.phoneExt}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <InputField
                                       control={control}
                                       error={errors?.phoneExt}
                                       name='phoneExt'
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Fax:'} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize} />
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='fax'
                                          disabled={true}
                                          field={selectedUserProfile.fax}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <InputField
                                       control={control}
                                       error={errors?.fax}
                                       name='fax'
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Is Followed By Allowed:'} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize} />
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='isFollowedByAllowed'
                                          disabled={true}
                                          field={formatBoolean(selectedUserProfile.isFollowedByAllowed)}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <CheckboxField
                                       control={control}
                                       error={errors?.isFollowedByAllowed}
                                       name='isFollowedByAllowed'
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Admin:'} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize} />
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='isAdmin'
                                          disabled={true}
                                          field={formatBoolean(selectedUserProfile.isAdmin)}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <CheckboxField
                                       control={control}
                                       error={errors?.isAdmin}
                                       name='isAdmin'
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label='User Roles:' required={true} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize} />
                                 <Col span={selectedUserProfileColSize}>
                                    {selectedUserProfile &&
                                       <BasicFieldWrapper
                                          name='userRoles'
                                          disabled={true}
                                          field={renderUserRoles(selectedUserProfile)}
                                          containerStyle={bottomMarginZero}
                                       />
                                    }
                                    {!selectedUserProfile && getSkeleton()}
                                 </Col>
                                 <Col span={inputFieldColSize}>
                                    <DropdownField
                                       control={control}
                                       error={errors?.userRoles}
                                       name='userRoles'
                                       options={optionsFromStore}
                                       multiple={true}
                                       required={true}
                                       containerStyle={{ ...bottomMarginZero, width: '100%' }}
                                    />
                                 </Col>
                              </Row>
                              <Row gutter={rowGutter} style={rowStyle}>
                                 <Col span={fieldColSize} style={fieldColStyle}>
                                    <BasicFieldWrapper label={'Delay Invite:'} containerStyle={bottomMarginZero} />
                                 </Col>
                                 <Col span={accessRequestColSize} />
                                 <Col span={selectedUserProfileColSize} />
                                 <Col span={inputFieldColSize}>
                                    <CheckboxField
                                       control={control}
                                       error={errors?.delayInvite}
                                       name='delayInvite'
                                       containerStyle={bottomMarginZero}
                                    />
                                 </Col>
                              </Row>
                           </Form>
                        </div>
                     </div>
                  </>
               }
            </div>
         </Dialog>

         {isPreviewUserProfileOpen &&
            <ExistingUserProfileEditor
               onCloseEditor={onPreviewUserClose}
               existingUserProfileId={previewUserProfileId}
               isEditorOpen={isPreviewUserProfileOpen}
               isEditorReadOnly={true} // No editing from here, only viewing
            />
         }
      </>
   );

   return html;
}

export default ApproveNewUserEditor;

const filterUserMain = (userPendingAccessRecord: UserPendingAccessModel, m: UserMainViewModel): boolean => {
   return userPendingAccessRecord && (
      m.emailAddress?.toLocaleLowerCase().indexOf(userPendingAccessRecord.emailAddress?.toLocaleLowerCase()) > -1 ||
      m.upn?.toLocaleLowerCase().indexOf(userPendingAccessRecord.upn?.toLocaleLowerCase()) > -1);
}

const filterUserProfiles = (userProfiles: UserProfile[], userPendingAccessRecord: UserPendingAccessModel, m: UserMainViewModel): boolean => {
   if (!userPendingAccessRecord ||
      !m.userProfileIds || m.userProfileIds.length === 0) return false;

   const matches = userProfiles.filter(y => m.userProfileIds.includes(y.userProfileId));
   return matches.some(y => (
      y.emailAddress?.toLocaleLowerCase().indexOf(userPendingAccessRecord.emailAddress) > -1 ||
      y.firstName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.firstName) > -1 ||
      y.lastName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.lastName) > -1 ||
      y.externalUpn?.toLocaleLowerCase().indexOf(userPendingAccessRecord.upn) > -1 ||
      y.externalFirstName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.externalFirstName) > -1 ||
      y.externalLastName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.externalLastName) > -1
   ))
}

const filterIndependentUserProfiles = (userPendingAccessRecord: UserPendingAccessModel, m: UserProfile, userProfileIdsFromUserMains: number[]): boolean => {
   if (!userPendingAccessRecord) return false;

   if (userProfileIdsFromUserMains?.includes(m.userProfileId)) return false;

   return m.emailAddress?.toLocaleLowerCase().indexOf(userPendingAccessRecord.emailAddress) > -1 ||
      m.firstName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.firstName) > -1 ||
      m.lastName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.lastName) > -1 ||
      m.externalUpn?.toLocaleLowerCase().indexOf(userPendingAccessRecord.upn) > -1 ||
      m.externalFirstName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.externalFirstName) > -1 ||
      m.externalLastName?.toLocaleLowerCase().indexOf(userPendingAccessRecord.externalLastName) > -1
}


const getSkeleton = (): React.ReactNode => {
   return <Skeleton title={false} paragraph={{ rows: 1 }} />
}

interface ICollapseProps {
   title: string;
   isExpanded: boolean;
   setIsExpanded: (isExpanded: boolean) => void;
   children: React.ReactNode;
   isLoading: boolean;
}

const CustomCollapse: React.FC<ICollapseProps> = (props) => {
   const { title, isExpanded, children, isLoading, setIsExpanded } = props;
   const [buttonIconType, setButtonIconType] = React.useState<CustomIconType>(undefined);

   React.useEffect(() => {
      setButtonIconType(isExpanded ?
         CustomIconType.CaretUpFilled :
         CustomIconType.CaretDownFilled);
   }, [isExpanded])

   if (isLoading) return <Spinner />

   const html = (
      <div style={{ border: `1px solid ${colorWheel.mediumGrey}`, padding: '5px' }}>
         <ActionButton
            buttonText={title}
            onClick={() => setIsExpanded(!isExpanded)}
            title={`Click to expand / collapse ${title}`}
            icon={<CustomIcon type={buttonIconType} />}
         />
         {isExpanded && children}
      </div>
   );

   return html;
}


interface IUserMainPreviewProps {
   userPendingAccessRecord: UserPendingAccessModel;
   areSearchesLoading: boolean;
   setPreviewUserProfileId: (id: number) => void;
   setIsPreviewUserProfileOpen: (isOpen: boolean) => void;
   matchingUserMains: UserMainTable[];
   userProfiles: UserProfile[];
   hasExactUpnMatch: boolean;
   handleDismissAsDuplicate: () => void;
   isUserMainCollapseOpen: boolean;
   setIsUserMainCollapseOpen: (isOpen: boolean) => void;
}

const UserMainPreview: React.FC<IUserMainPreviewProps> = (props) => {
   const { userPendingAccessRecord, areSearchesLoading, 
      setPreviewUserProfileId, setIsPreviewUserProfileOpen,
      matchingUserMains, userProfiles, 
      hasExactUpnMatch, handleDismissAsDuplicate,
      isUserMainCollapseOpen, setIsUserMainCollapseOpen } = props;

   const userMainColumns: ColumnsType<UserMainViewModel> = [
      {
         dataIndex: 'id',
         title: 'Id',
         key: 'id',
         sorter: (a, b) => numberComparer(a.id, b.id),
         sortDirections: ['ascend', 'descend']
      },
      {
         dataIndex: 'upn',
         title: 'UPN',
         key: 'upn',
         sorter: (a, b) => stringComparer(a.upn, b.upn),
         sortDirections: ['ascend', 'descend'],
         render: (text, record) => {
            return <Space key={`upn-space-${record?.id}`} >
               {record?.id &&
                  <Link
                     to={{ pathname: `/user/userdetail/${record?.id}` }}
                     title={`Edit User Profile for ${record?.upn}`}>
                     {<HighlightSearchText searchString={userPendingAccessRecord.upn} targetString={record.upn} />}
                  </Link>
               }
               {hasExactUpnMatch &&
                  <ActionButton
                     key={`grid-button-dismiss-${record?.id}`}
                     buttonText='Dismiss as Duplicate'
                     onClick={handleDismissAsDuplicate}
                  />
               }
            </Space>
         }
      },
      {
         dataIndex: 'accessRoleIds',
         title: 'Role Count',
         key: 'accessRoleIds',
         render: (text, record) => record?.accessRoleIds?.length
      },
      {
         dataIndex: 'practiceIds',
         title: 'Practices',
         key: 'practiceIds',
         render: (text, record) => record?.distinctPracticeIds?.join(',')
      },
      {
         dataIndex: 'isDisabled',
         title: 'Disabled',
         key: 'isDisabled',
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val)
      }
   ];

   const embeddedUserProfileColumns: ColumnsType<UserProfileTable> = [
      {
         dataIndex: 'userProfileId',
         title: 'Id',
         key: 'userProfileId',
         sorter: (a, b) => numberComparer(a.userProfileId, b.userProfileId),
         sortDirections: ['ascend', 'descend'],
         defaultSortOrder: 'ascend'
      },
      {
         dataIndex: 'practiceName',
         title: 'Practice',
         key: 'practiceName',
         render: (text, record) => formatPractice(record)
      },
      {
         dataIndex: 'externalUpn',
         title: 'External',
         key: 'externalFullName',
         render: (text, record) => {
            return <div>
               Email: {<HighlightSearchText searchString={userPendingAccessRecord.upn} targetString={record.externalUpn} />} <br />
               Name:  {<HighlightSearchText searchString={userPendingAccessRecord.firstName} targetString={record.externalFirstName} />} {<HighlightSearchText searchString={userPendingAccessRecord.lastName} targetString={record.externalLastName} />}
            </div>
         }
      },
      {
         dataIndex: 'emailAddress',
         title: 'Details',
         key: 'emailAddress',
         render: (text, record) => {
            return <div>
               Email: {<HighlightSearchText searchString={userPendingAccessRecord.emailAddress} targetString={record.emailAddress ?? 'N/A'} />} <br />
               Name: {<HighlightSearchText searchString={userPendingAccessRecord.firstName} targetString={record.firstName} />} {<HighlightSearchText searchString={userPendingAccessRecord.lastName} targetString={record.lastName} />}
            </div>
         }
      },
      {
         dataIndex: 'isDisabled',
         title: 'Disabled',
         key: 'isDisabled',
         sorter: (a, b) => boolComparer(a.isDisabled, b.isDisabled),
         sortDirections: ['ascend', 'descend'],
         render: (val) => formatBoolean(val)
      },
      {
         dataIndex: '',
         title: '',
         key: 7,
         fixed: 'right',
         render: (text, record) => {
            return <ActionButton
               title='Preview'
               icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
               onClick={() => {
                  setPreviewUserProfileId(record.userProfileId);
                  setIsPreviewUserProfileOpen(true);
               }} />
         }
      }
   ]

   return <div style={{ maxHeight: '18vh', overflowY: 'auto' }}>
         <CustomCollapse
            key={'user-main-collapse'}
            title={`User Main Search Results (${matchingUserMains?.length})`}
            isExpanded={isUserMainCollapseOpen}
            setIsExpanded={setIsUserMainCollapseOpen}
            isLoading={areSearchesLoading}
            children={<MinimalistTable
               key={'user-main-table'}
               className='fund-status'
               rowKey='rowKey'
               columns={userMainColumns}
               data={matchingUserMains}
               showHeader={true}
               size='small'
               expandedRowRender={(record: UserMainViewModel) => {
                  const rows = userProfiles?.filter(y => record?.userProfileIds?.includes(y.userProfileId))
                     .map((profile): UserProfileTable => {
                        return {
                           ...profile,
                           userMainId: record?.id,
                           userMainEmailAddress: record?.emailAddress
                        } as UserProfileTable
                     })

                  return <MinimalistTable
                     key={'user-main-table'}
                     showHeader={true}
                     rowKey='userProfileId'
                     columns={embeddedUserProfileColumns}
                     data={rows}
                     bordered={true}
                     size='small'
                  />
               }}
            />}
         />
      </div>
}


interface IUserProfilePreviewProps {
   userPendingAccessRecord: UserPendingAccessModel;
   areSearchesLoading: boolean;
   setPreviewUserProfileId: (id: number) => void;
   setIsPreviewUserProfileOpen: (isOpen: boolean) => void;   
   selectedUserProfile: UserProfile;
   setSelectedUserProfile: (userProfile: UserProfile) => void;
   setUserProfileAction: (action: AllowedUserProfileActions) => void;
   independentMatchingUserProfiles: UserProfile[];
   isUserProfileCollapseOpen: boolean;
   setIsUserProfileCollapseOpen: (isOpen: boolean) => void;
}

const UserProfilePreview: React.FC<IUserProfilePreviewProps> = (props) => {

   const { userPendingAccessRecord, areSearchesLoading,
      setPreviewUserProfileId, setIsPreviewUserProfileOpen,
      independentMatchingUserProfiles, selectedUserProfile, setSelectedUserProfile, setUserProfileAction,
      isUserProfileCollapseOpen, setIsUserProfileCollapseOpen } = props;

   const independentUserProfileColumns: ColumnsType<UserProfile> = [
      {
         dataIndex: 'userProfileId',
         key: 'userProfileId',
         title: 'UserProfileId'
      },
      {
         dataIndex: 'externalUpn',
         title: 'External',
         key: 'externalFullName',
         render: (text, record) => {
            return <div>
               Email: {<HighlightSearchText searchString={userPendingAccessRecord.upn} targetString={record.externalUpn} />} <br />
               Name:  {<HighlightSearchText searchString={userPendingAccessRecord.firstName} targetString={record.externalFirstName} />} {<HighlightSearchText searchString={userPendingAccessRecord.lastName} targetString={record.externalLastName} />}
            </div>
         }
      },
      {
         dataIndex: 'emailAddress',
         title: 'Details',
         key: 'emailAddress',
         render: (text, record) => {
            return <div>
               Email: {<HighlightSearchText searchString={userPendingAccessRecord.emailAddress} targetString={record.emailAddress} />} <br />
               Name: {<HighlightSearchText searchString={userPendingAccessRecord.firstName} targetString={record.firstName} />} {<HighlightSearchText searchString={userPendingAccessRecord.lastName} targetString={record.lastName} />}
            </div>
         }
      },
      {
         dataIndex: 'practice',
         title: 'Practice',
         key: 'practice',
         render: (text, record) => formatPracticeName(record.practiceName, record.practiceId)
      },
      {
         dataIndex: '',
         width: '200px',
         title: () => {
            if (selectedUserProfile) {
               return <DeleteButton buttonText={'Deselect User Profile'}
                  title={'Deselect User Profile'}
                  disabled={!selectedUserProfile}
                  hasIcon={false}
                  onClick={() => {
                     setSelectedUserProfile(undefined)
                     setUserProfileAction(AllowedUserProfileActions.NoUserProfileSelection);
                  }}
               />
            } else {
               return <> </>;
            }
         },
         render: (text, record) => {
            return <Space>
               <Dropdown menu={renderIndependentUserProfileOptions(record)} trigger={['click']}>
                  <Button>User Profile Options...</Button>
               </Dropdown>
               <ActionButton
                  title='Preview'
                  icon={<CustomIcon type={CustomIconType.EyeOutlined} />}
                  onClick={() => {
                     setPreviewUserProfileId(record.userProfileId);
                     setIsPreviewUserProfileOpen(true);
                  }} />
            </Space>
         }
      }
   ]

   const renderIndependentUserProfileOptions = (record: UserProfile): MenuProps => {
      let items: MenuProps['items'];

      items = [
         {
            key: 0,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => {
                     setSelectedUserProfile(record);
                     setUserProfileAction(AllowedUserProfileActions.SelectAndOverwriteWithProfile)
                  }}>
                  Select this User Profile <b>and overwrite</b> Access Request information <i>(can still be edited)</i>
               </a>
            )
         },
         {
            key: 1,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => {
                     setSelectedUserProfile(record)
                     setUserProfileAction(AllowedUserProfileActions.SelectAndKeepAccessRequest)
                  }}>
                  Select this User Profile <b>without</b> overwriting Access Request information <i>(can still be edited)</i>
               </a>
            )
         },
         {
            key: 2,
            label: (
               <a target="_blank" rel="noopener"
                  onClick={() => {
                     setSelectedUserProfile(record)
                     setUserProfileAction(AllowedUserProfileActions.CopyFromUserProfileAndCreateNew);
                  }}>
                  Copy values from this User Profile <b>but do not overwrite the User Profile; Create a new User Profile</b>
               </a>
            )
         }
      ]

      return { items } as MenuProps;
   }

   return <div style={{ maxHeight: '18vh', overflowY: 'auto' }}>
      <CustomCollapse
         key={'user-profile-collapse'}
         title={`Independent User Profile Search Results (${independentMatchingUserProfiles?.length})`}
         isExpanded={isUserProfileCollapseOpen}
         setIsExpanded={setIsUserProfileCollapseOpen}
         isLoading={areSearchesLoading}
         children={<MinimalistTable
            key={'user-profile-table'}
            className='fund-status'
            rowKey='userProfileId'
            columns={independentUserProfileColumns}
            data={independentMatchingUserProfiles}
            showHeader={true}
            size='small'
         />}
      />
   </div>
}
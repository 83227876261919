import { Card, Col, Popover, Row } from 'antd';
import * as React from 'react';
import CustomIcon, { CustomIconType } from '../components/shared/AntComponents/CustomIcon';
import colorWheel from '../Theme/ColorWheel';

interface IProps {
   title?: string;
   subTitle?: string | React.ReactNode;
   infoText?: string | React.ReactNode;
   children?: React.ReactNode;
   closeFunc?: () => void;
   headingCss?: string; // just here to transition smoothly for now, afaik should be removed later
   style?: React.CSSProperties;
}

const titleContent: React.FC<string> = (title: string, infoText: string | React.ReactNode) => {
    return (
        <>
            <h3 style={{ color: colorWheel.white, margin: 0, padding: 0, display: 'inline' }}>{title}</h3>
            &nbsp;
            {infoText &&
                <Popover content={infoText}
                    title={<Row>
                        <Col span={1}>
                                <CustomIcon type={CustomIconType.InfoCircleFilled} style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.5em',
                                    color: colorWheel.skyBlue,
                                    borderRadius: 25
                                    }} />
                        </Col>
                        <Col span={23}>
                            {title} 
                        </Col>
                    </Row>}
                    style={{ maxWidth: 200, display: 'inline' }}
                    overlayStyle={{
                        width: "30vw"
                    }}
                >
                    <span>
                        <CustomIcon type={CustomIconType.InfoCircleFilled} style={{
                        color: colorWheel.white,
                        backgroundColor: colorWheel.skyBlue,
                        borderRadius: 25
                        }} />
                    </span>
                </Popover>}
        </>
    );
}

const ContentCard: React.FC<IProps> = (props) => {
   return (
      <div className="ap-contentcard-container" style={props.style ?? props.style}>
         <Card 
            className="ap-contentcard"
            //headStyle={{ backgroundColor: colorWheel.plumPurple }}
            styles={{ header: { backgroundColor: colorWheel.plumPurple } }}
            title={props.title && titleContent(props.title, props?.infoText)}
            extra={props.title && <h3 style={{ color: colorWheel.white, margin: 0, padding: 0 }}>{props.subTitle}</h3>}>
            {props.closeFunc &&
                <h3 style={{ display: 'inline' }}><span onClick={props.closeFunc} className="close-button">&times;</span></h3>
            }
            {props.children}
         </Card>
      </div>
    );
}

export default ContentCard;
import { Alert, Col, Row, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { UnsafeAppInsights } from './InsightsContext';
import { useUserContext } from './auth/authContext';
import ImageContainer from './components/shared/AntComponents/ImageContainer';
import * as React from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useFetchSetting } from './store/SettingsFetcher';
import { KnownSettings } from './store/SettingsModel';

interface IPageNotFoundProps {
   title: string;
}

const PageNotFound: React.FC<IPageNotFoundProps> = (props) => {
   const { title } = props;
   const appInsights = UnsafeAppInsights;
   const { user } = useUserContext();
   const { Title } = Typography;
   const emailString: string = useFetchSetting(KnownSettings.SupportEmail);
   const phoneString: string = useFetchSetting(KnownSettings.SupportPhone);

  React.useMemo(() => {
      // Given that we should only render this component when the route doesn't have a match, tell AppInsights someone got lost
      appInsights.trackException({
         error: { name: 'PageNotFound', message: `PageNotFound, Path: ${window.location.href}` },
         severityLevel: SeverityLevel.Error,
         properties: { title }
      })
   }, [])

   const html = (
      <Row>
            <Col span={5}>
               <ImageContainer imageSrc='/meep404.png' styles={{ 'width': 275, padding: '1rem' }} />
            </Col>
            <Col span={14}>
               <Title>
                  <div style={{ paddingTop: 20 }}>
                     Meep! {title}
                  </div>
               </Title>

               You have attempted to access a resource that does not exist.

               <Alert style={{ padding: '10px', margin: '10px 0' }}
                  message="There are a couple of things you can try:"
                  description={<ul>
                     <li>
                        Return to the&nbsp;
                        <Link
                           to={'/'}
                        >
                           <span>Dashboard</span>
                        </Link>
                     </li>
                     <li>Close and Reopen your browser</li>
                     <li>{`Contact our support staff at ${phoneString} or ${emailString}`}</li>
                  </ul>}
                  type="info"
               />

               If you think this was a mistake, please contact support and provide them with a screenshot of this page to help us quickly identify and resolve any issues.

               <Row gutter={[16, 16]} style={{ marginTop: '8px' }}>
                  {user && <>
                     <Col span={5} style={{ textAlign: 'end' }}>
                        <Tag>User </Tag>
                     </Col>
                     <Col span={19}>
                        {user.username}
                     </Col>
                  </>
                  }
                  <Col span={5} style={{ textAlign: 'end' }}>
                     <Tag>Location </Tag>
                  </Col>
                  <Col span={19}>
                     {window.location.href}
                  </Col>
                  {appInsights && appInsights?.context &&
                     <>
                        <Col span={5} style={{ textAlign: 'end' }}>
                           <Tag>Session Id</Tag>
                        </Col>
                        <Col span={19}>
                           <code>{appInsights.context.getSessionId()}</code>
                        </Col>
                     </>
                  }
                  {appInsights && appInsights?.context?.telemetryTrace &&
                     <>
                        <Col span={5} style={{ textAlign: 'end' }}>
                           <Tag>Operation Id</Tag>
                        </Col>
                        <Col span={19}>
                           <code>{appInsights.context.telemetryTrace.traceID}</code>
                        </Col>
                     </>
                  }
                  <Col span={5} style={{ textAlign: 'end' }}>
                     <Tag>UTC Timestamp </Tag>
                  </Col>
                  <Col span={19}>
                     <code>{dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}</code>
                  </Col>
               </Row>
            </Col>
            <Col span={5}>

            </Col>
      </Row>);
   return html;
}

export default PageNotFound;



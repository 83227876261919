import * as React from 'react';
import { Form, Row, Col, Divider } from 'antd';
import { CustomIconType } from '../../shared/AntComponents/CustomIcon'
import { BasicFieldWrapper } from '../../shared/BasicInputLibrary';
import Dialog from '../../Dialog';
import { SaveButton, CancelButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import { usePracticeOptions } from '../../../store/practice/PracticeFetcher';
import { useAssistanceProgramNameOptions, useFetchAssistancePrograms} from '../../../store/program/AssistanceProgramFetcher';
import { useBrandOptions } from '../../../store/drug/BrandFetcher';
import { IntrinsiqPayerToAP, IntrinsiqPayerToAPBulkMap } from '../../../store/xref/IntrinsiqPayerToAPModel';
import {
   intrinsiqPayerBaseUrl,
   bulkMapIntrinsiqPayerToAP
} from '../../../store/xref/IntrinsiqPayerToAPFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import { TitleError } from '../../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../../store/ApiContext';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormMultiSelectInput } from '../../shared/MultiSelect/FormMultiSelectInput';
import { useErrorContext } from '../../../store/ErrorContext';

interface IProps {
    isOpen: boolean;
    closeEditor: () => void;
    bulkSourceItems: IntrinsiqPayerToAP[]
};

const dialogContentStyle = {
    height: '300px',
    maxHeight: '95vh',
}

interface IIntrinsiqBulkEditor {
   additionalPrograms: number[];
}

const yupIntrinsiqPayerBulkEditorSchema = yup.object({
   additionalPrograms: yup.array().test('arraySize', 'At least one Assistance Program must be selected', value => value?.length > 0)
})

interface IIntrinsiqPayerBulkEditorYup extends yup.Asserts<typeof yupIntrinsiqPayerBulkEditorSchema> { } 

const __formId = "frmIntrinsiqPayerToApXRefBulk";
const MAPPED_STATUS = 'Mapped';
const _keysLike: string[] = [intrinsiqPayerBaseUrl];

const IntrinsiqPayerToApXRefBulkEditor: React.FC<IProps> = (props) => {
   const { isOpen, closeEditor, bulkSourceItems } = props;

   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { practiceOptions } = usePracticeOptions(httpGet);
   const { assistancePrograms } = useFetchAssistancePrograms(httpGet);
   const { assistanceProgramNameOptions } = useAssistanceProgramNameOptions(httpGet);
   const { brandOptions } = useBrandOptions(httpGet);
   const _addNewRef = React.useRef(null);

   const { control, handleSubmit, getValues, trigger, reset, formState: { errors, } } = useForm<IIntrinsiqPayerBulkEditorYup>({
      resolver: yupResolver<yup.AnyObject>(yupIntrinsiqPayerBulkEditorSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      reset({
         additionalPrograms: []
      } as IIntrinsiqBulkEditor);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onSubmit = (data: IIntrinsiqPayerBulkEditorYup) => {
      setIsFormSaving(true);

      const viewModel: IntrinsiqPayerToAPBulkMap = {
         sourceRecords: bulkSourceItems ?? [],
         additionalProgramIds: data.additionalPrograms
      };

      bulkMapIntrinsiqPayerToAP(httpPost, viewModel)
         .then(handleClose)
         .catch(err => console.error('Error calling saveIntrinsiqPayerToAP', err))
         .finally(() => setIsFormSaving(false));
   }

   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving && isOpen) {
      return <Spinner message='Saving Bulk IntrinsiqPayerToAP...' />
   }

   const html = (
      <Dialog
         scrollingContent={true}
         title={'IntrinsiqPayerToAP Bulk Update'}
         open={isOpen}
         size="large"
         actionButtons={
            <> 
               <CancelButton onClick={() => handleClose()} loading={isFormSaving} />
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 && <>
               <TitleError
                  text='Please correct the errors below' />
               {console.log('--------------Form Errors: -------------------------')}
               {console.log(errors)}
            </>
            }

            <ApiErrorDisplay
               title='Error saving IntrinsiqPayerToAP'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               {bulkSourceItems?.map((field, index) => {
                  return <Row gutter={[16, 16]} key={field.id}>
                     <Col span={8}>
                        <BasicFieldWrapper
                           field={practiceOptions.find(y => y.value === field.practiceId)?.label}
                           label='Practice'
                           placeholder={field?.practiceId === undefined ? ' - none -' : `${field?.practiceId}`}
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicFieldWrapper
                           name='payeeName'
                           field={field.payeeName}
                           label='payeeName'
                           type='text'
                           disabled={true}
                        />
                     </Col>
                     <Col span={8}>
                        <BasicFieldWrapper
                           name='brandId'
                           field={brandOptions.find(y => y.value === field.brandId)?.label}
                           label='brandId'
                           placeholder={field?.brandId === undefined ? ' - none -' : `${field?.brandId}`}
                           disabled={true}
                        />
                     </Col>
                  </Row>
               })}
               <Divider>Additional XRef Records</Divider>
               <FormMultiSelectInput
                  options={assistanceProgramNameOptions}
                  searchable
                  allowSelectAll
                  label={`Select Additional Programs (${getValues()?.additionalPrograms?.length ?? 0} selected)`}
                  searchPlaceholder={'Seach for Programs'}
                  popoverTitle={'Select Additional Programs'}
                  control={control}
                  name={'additionalPrograms'}
                  error={errors?.additionalPrograms}
                  customIcon={CustomIconType.PlusOutlined}
                  showRecordCount
                  trigger={trigger}
                  displayListStartingLength={200}
                  showOkButton
               />
               <p><strong>NOTE:</strong> Each Additional Program selected here will be created with a <strong>Status</strong> of <strong>Mapped</strong></p>
               
            </Form>
            <div style={{ height: 0 }} ref={_addNewRef} />
         </div>

      </Dialog>
   )
   return html;
}

export default IntrinsiqPayerToApXRefBulkEditor;


import * as React from 'react';
import { useParams } from 'react-router-dom';
import { isInRole } from '../../../functions/auth.functions';
import { FieldTypes } from '../../../functions/form.contracts';
import { isValidHttpUrl } from '../../../functions/form.validators';
import { truncateStringStart } from '../../../functions/format.functions';
import Card from '../../../layouts/Card';
import { KnownSettings } from '../../../store/SettingsModel';
import { CohortItemStatusType, getItemStatus } from '../../../store/cohort/CohortItemStatus';
import {
   programSummaryDetailBaseUrl,
   useFetchProgramSummaryDetail
} from '../../../store/cohort/ProgramSummaryDetailFetcher';
import {
   ProgramServiceDiseaseOrBrandLookup, ProgramSummaryDataGroup,
   ProgramSummaryDetail,
   getCohortItemStatusArray,
   getColumnCohortItemStatusArray
} from '../../../store/cohort/ProgramSummaryDetailModel';
import EmptyModal from '../../EmptyModal';
import Spinner from '../../Spinner';

import { Col, Row } from 'antd';
import colorWheel from '../../../Theme/ColorWheel';
import Restricted from '../../../auth/Restricted';
import { useUserContext } from '../../../auth/authContext';
import { numberComparer, stringComparer } from '../../../functions/comparer.functions';
import { IOptionItem } from '../../../functions/option.functions';
import { useApiContext } from '../../../store/ApiContext';
import { useErrorContext } from '../../../store/ErrorContext';
import { cohortItemBaseUrl, cohortItemSaveAnswersUrl, cohortItemSaveReviewsUrl, saveCohortAnswers, saveCohortReviews } from '../../../store/cohort/CohortItemFetcher';
import {
   CohortItem,
   CohortItemAnswer, CohortItemAnswerRequest,
   CohortItemReview, CohortItemReviewRequest
} from '../../../store/cohort/CohortItemModel';
import { cohortItemStatuses } from '../../../store/cohort/CohortItemStatus';
import { isCohortPublished } from '../../../store/cohort/CohortSummaryModel';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { BasicCheckboxListField, BasicDropdownField, BasicInputField } from '../../shared/BasicInputLibrary';
import { ISelectedProgramSummaryDetail, useCohortContext } from '../CohortContext';
import ItemStatusIndicator from '../ItemStatusIndicator';
import DataGroupEditor from '../editor/DataGroupEditor';
import './ScrollableTable.css';
import BenefitPeriodSummaryDisplay from "./BenefitPeriodSummaryDisplay";
import { BENEFIT_PERIOD_GROUP_ID } from "../../../store/cohort/CohortItemModel";

interface IModalEditor {
   opened: boolean;
   dataGroups?: ProgramSummaryDataGroup[];
}

interface IModalConfirm {
   opened: boolean;
   title: string;
   message?: string;
   newCohortItemStatus: CohortItemStatusType;
}

const errorKeys = { keys: [programSummaryDetailBaseUrl, cohortItemBaseUrl, cohortItemSaveAnswersUrl, cohortItemSaveReviewsUrl] };

const ProgramSummaryDetailList: React.FC = () => {

   const { cohortId: cohortIdRaw } = useParams();
   const { userRoles } = useUserContext();

   const { httpGet, httpPost } = useApiContext();

   const {
      cohortId, setCohortId, setCardTitle, setCardSubText,
      cohortSummaryFromDate, selectedProgramSummaryDetail,
      selectedCohortItemStatusIds, setSelectedCohortItemStatusIds,
      currentCohort
   } = useCohortContext();
   const { removeErrors } = useErrorContext();

   const clearErrors = () => {
      removeErrors(errorKeys);
   }

   const [modalEditor, setModalEditor] = React.useState<IModalEditor>({ opened: false });
   const [modalConfirm, setModalConfirm] = React.useState<IModalConfirm>({ opened: false, title: 'modal confirm', newCohortItemStatus: CohortItemStatusType.New });

   const [assistanceProgramFilter, setAssistanceProgramFilter] = React.useState<number>(selectedProgramSummaryDetail.assistanceProgramId);
   const [assistanceServiceFilter, setAssistanceServiceFilter] = React.useState<number>(selectedProgramSummaryDetail.assistanceServiceId);
   const [diseaseOrBrandFilter, setDiseaseOrBrandFilter] = React.useState<number>(selectedProgramSummaryDetail.diseaseOrBrandId);
   const [urlFilter, setUrlFilter] = React.useState<string>();
   const [beforeUrlFilter, setBeforeUrlFilter] = React.useState<ISelectedProgramSummaryDetail>();

   const [sortField,] = React.useState<string>('diseaseOrBrand');
   const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');
   const [filteredItems, setFilteredItems] = React.useState<ProgramSummaryDetail[]>([]);
   const [programServiceDiseaseOrBrandLookup, setProgramServiceDiseaseOrBrandLookup] = React.useState<ProgramServiceDiseaseOrBrandLookup[]>([]);
   const [filteredDetailAssistanceProgramOptions, setFilteredDetailAssistanceProgramOptions] = React.useState<IOptionItem[]>([]);
   const [filteredDetailAssistanceServiceOptions, setFilteredDetailAssistanceServiceOptions] = React.useState<IOptionItem[]>([]);
   const [filteredDetailDiseaseOrBrandOptions, setfilteredDetailDiseaseOrBrandOptions] = React.useState<IOptionItem[]>([]);

   React.useEffect(() => {
      //NOTE: setting context cohortid here becuase the context is initialized 
      // above react router - useParams will not fire outside of the Route render
      const setCohortIdEffect = () => {
         if (cohortIdRaw) {
            setCohortId(Number(cohortIdRaw));
         }
      }
      setCohortIdEffect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cohortIdRaw]);

   const { results, isLoading } = useFetchProgramSummaryDetail(httpGet, cohortId);

   React.useEffect(() => {
      const changeCohort = (): void => {
         const isAdmin: boolean = isInRole(userRoles, [KnownSettings.CohortAdmin]);
         if (currentCohort) {
            setCardTitle(currentCohort.name)

            if (isAdmin) {
               setCardSubText(`${currentCohort.recommendedChanges} Remaining of ${currentCohort.acceptedChanges + currentCohort.rejectedChanges + currentCohort.recommendedChanges} Changes - ${currentCohort.totalItems} Items`)
            } else {
               setCardSubText(`Completed ${currentCohort.totalItems - currentCohort.newItems} of ${currentCohort.totalItems} Items`)
            }
         }
      }
      changeCohort();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cohortId, currentCohort]);

   const handleEditDataGroup = (dataGroup: ProgramSummaryDataGroup) => {
      setModalEditor({
         ...modalEditor,
         opened: true,
         dataGroups: [{ ...dataGroup }]
      });
   };

   const handleEditDataGroupColumn = (rows: ProgramSummaryDetail[], dataGroupId: number) => {
      const dataGroups: ProgramSummaryDataGroup[] = [];
      rows.reduce((acc: ProgramSummaryDataGroup[], item) => {
         item.dataGroups.forEach(dg => {
            if (dg.dataGroupId === dataGroupId && dg.cohortItems?.length > 0) {
               dataGroups.push(dg);
            }
         })
         return acc;
      }, []);

      setModalEditor({
         ...modalEditor,
         opened: true,
         dataGroups: [...dataGroups]
      });
   };

   const handleModalEditorClose = () => {
      clearErrors();
      //close editor 
      setModalEditor({
         ...modalEditor,
         opened: false,
      });

   }

   const handleConfirmRow = (row: ProgramSummaryDetail, nextStatus: CohortItemStatusType) => {
      const dataGroups: ProgramSummaryDataGroup[] = row.dataGroups;
      setModalConfirm({
         ...modalConfirm,
         opened: true,
         title: `Saving All Items in row as ${getItemStatus(nextStatus)}`,
         message: `Saving ${getItemStatus(nextStatus)}`,
         newCohortItemStatus: nextStatus
      });
      handleConfirm(dataGroups, nextStatus);
   };

   const handleConfirmColumn = (rows: ProgramSummaryDetail[], dataGroupIndex: number, nextStatus: CohortItemStatusType) => {
      const dataGroupName = rows[0].dataGroups[dataGroupIndex].dataGroupName;
      const dataGroups: ProgramSummaryDataGroup[] = rows.map(y => y.dataGroups[dataGroupIndex]);

      setModalConfirm({
         ...modalConfirm,
         opened: true,
         title: `Edit ${dataGroupName}: ${dataGroups.length} Item${dataGroups.length > 1 ? 's' : ''}`,
         message: `Saving ${dataGroups.length} Item${dataGroups.length > 1 ? 's' : ''} as ${getItemStatus(nextStatus)}`,
         newCohortItemStatus: nextStatus
      });

      handleConfirm(dataGroups, nextStatus);
   };

   const handleConfirm = (dataGroups: ProgramSummaryDataGroup[], nextStatus: CohortItemStatusType) => {
      if (nextStatus === CohortItemStatusType.Audited_Confirm_No_Change || nextStatus === CohortItemStatusType.Recommended_Change)
         handleConfirmAnswer(dataGroups);
      else if (nextStatus === CohortItemStatusType.Accepted_Change || nextStatus === CohortItemStatusType.Rejected_Change)
         handleConfirmReview(dataGroups, nextStatus);
   }

   const _acceptedPriorStates = [CohortItemStatusType.Recommended_Change, CohortItemStatusType.Rejected_Change];

   const handleConfirmReview = (dataGroups: ProgramSummaryDataGroup[], nextStatus: CohortItemStatusType) => {
      //build answer list that contains all items in this datagroup.  
      let cohortReviews: CohortItemReview[] = [];
      for (let group = 0; group < dataGroups.length; group++) {
         try {
            for (let item = 0; item < dataGroups[group].cohortItems.length; item++) {
               const cohortItem: CohortItem = dataGroups[group].cohortItems[item];
               if ((nextStatus === CohortItemStatusType.Accepted_Change && _acceptedPriorStates.includes(cohortItem.cohortItemStatusId))
                  || (nextStatus === CohortItemStatusType.Rejected_Change && cohortItem.cohortItemStatusId === CohortItemStatusType.Recommended_Change)) {
                  let review: CohortItemReview = { id: cohortItem.cohortItemId, cohortItemStatusId: nextStatus };
                  cohortReviews.push(review);
               }
            }
         }
         catch (err) {
            console.error(err);
            console.error(`DataGroup index missing: [${group}]`);
         }
      }

      if (cohortReviews.length > 0) {
         const reviewRequest: CohortItemReviewRequest = {
            cohortId: cohortId,
            cohortItemReviews: cohortReviews
         };

         saveCohortReviews(httpPost, reviewRequest, cohortSummaryFromDate)
            .then(() => {
               handleModalConfirmClose();
            }).catch((err) => {
               setModalConfirm((currentState) => {
                  return { ...currentState, opened: true }
               });

               console.error(`error: ${err?.message.substring(0, 41)} ... [check logs for more details]`);
            });
      }
   }

   const handleConfirmAnswer = (dataGroups: ProgramSummaryDataGroup[]) => {
      //build answer list that contains all items in this datagroup.  
      let cohortAnswers: CohortItemAnswer[] = [];
      for (let group = 0; group < dataGroups.length; group++) {

         try {
            for (let item = 0; item < dataGroups[group].cohortItems.length; item++) {

               const cohortItem: CohortItem = dataGroups[group].cohortItems[item];
               if (cohortItem.cohortItemStatusId === CohortItemStatusType.New) {
                  //check if item exists in current answers -> user changed the value
                  let answer: CohortItemAnswer = { id: cohortItem.cohortItemId, answer: cohortItem.value };
                  cohortAnswers.push(answer);
               }
            }
         }
         catch (err) {
            console.error(err);
            console.error(`DataGroup index missing: [${group}]`);
         }
      }
      if (cohortAnswers.length > 0) {
         const answerRequest: CohortItemAnswerRequest = {
            cohortId: cohortId,
            cohortItemAnswers: cohortAnswers
         };

         saveCohortAnswers(httpPost, answerRequest, cohortSummaryFromDate)
            .then(() => {
               handleModalConfirmClose();
            }).catch((err) => {
               setModalConfirm((currentState) => {
                  return { ...currentState, opened: true }
               });

               console.error(`error: ${err?.message.substring(0, 41)} ... [check logs for more details]`);
            });
      }
   }

   const handleModalConfirmClose = () => {
      clearErrors();

      //close editor 
      setModalConfirm({
         ...modalConfirm,
         opened: false,
         title: 'modal confirm closed',
         message: 'done',
         newCohortItemStatus: CohortItemStatusType.New
      });
   }

   React.useEffect(() => {
      //this method is to pare down the list for the three drop down lists - removes duplicates and filters by status
      const loadProgramServiceDiseaseOrBrandLookup = () => {
         if (results?.length > 0) {
            const tempList: ProgramServiceDiseaseOrBrandLookup[] = [];
            results.reduce((acc: ProgramSummaryDetail[], item) => {

               //only add if not already in list
               if (!tempList.some(y => y.assistanceProgramId === item.assistanceProgramId &&
                  y.assistanceServiceId === item.assistanceServiceId &&
                  y.diseaseOrBrandId === item.diseaseOrBrandId)) {

                  //only add if items fall in current status selection - could be part of parent if, but it was getting a bit complex
                  if ((!selectedCohortItemStatusIds || selectedCohortItemStatusIds.length === 0) ||
                     item.dataGroups.some(y => y.cohortItems.some(x => selectedCohortItemStatusIds.includes(x.cohortItemStatusId)))) {
                     const option = {
                        assistanceProgramId: item.assistanceProgramId,
                        programName: item.programName,
                        assistanceServiceId: item.assistanceServiceId,
                        assistanceServiceName: item.assistanceServiceName,
                        diseaseOrBrandId: item.diseaseOrBrandId,
                        diseaseOrBrand: item.diseaseOrBrand
                     } as ProgramServiceDiseaseOrBrandLookup

                     tempList.push(option);
                  }

               }
               return acc;
            }, []);

            tempList.sort((a: ProgramServiceDiseaseOrBrandLookup, b: ProgramServiceDiseaseOrBrandLookup) => numberComparer(Number(a.assistanceProgramId), Number(b.assistanceProgramId)));
            tempList.sort((a: ProgramServiceDiseaseOrBrandLookup, b: ProgramServiceDiseaseOrBrandLookup) => stringComparer(String(a.programName), String(b.programName)));
            setProgramServiceDiseaseOrBrandLookup(tempList);
         }
      }
      loadProgramServiceDiseaseOrBrandLookup();
   }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [results, selectedCohortItemStatusIds, urlFilter]);

   ///////////////////dependent drop downs///////////////////////
   React.useEffect(() => {
      const loadFilteredDetailAssistanceProgramOptions = () => {
         if (programServiceDiseaseOrBrandLookup?.length > 0) {
            const options: IOptionItem[] = [];
            programServiceDiseaseOrBrandLookup.reduce((acc: IOptionItem[], item) => {
               if (!options.some(y => y.value === item.assistanceProgramId)) {
                  const option = {
                     label: `[${item.assistanceProgramId}] ${item.programName}`,
                     value: item.assistanceProgramId
                  } as IOptionItem

                  options.push(option);
               }
               return acc;
            }, []);
            setFilteredDetailAssistanceProgramOptions(options);

            //if there is a url filter, and no program selected, then don't set the value
            const apFilterIndex = options.findIndex(y => y.value === assistanceProgramFilter);
            //console.log('loadFilteredDetailAssistanceProgramOptions', 'assistanceProgramFilter', assistanceProgramFilter, 'apFilterIndex', apFilterIndex, 'urlFilter', urlFilter)
            if (!urlFilter && apFilterIndex === -1) {
               setAssistanceProgramFilter(options[0]?.value as number);
            } else if (apFilterIndex === -1 && assistanceProgramFilter) {
               setAssistanceProgramFilter(undefined);
            }
         }
      }
      loadFilteredDetailAssistanceProgramOptions();
   }// eslint-disable-next-line react-hooks/exhaustive-deps
      , [programServiceDiseaseOrBrandLookup]);


   React.useEffect(() => {
      //this method will filter service and disease\brand drop down lists based on filtered items for selected program
      const loadFilteredDetailOptions = () => {
         if (programServiceDiseaseOrBrandLookup?.length > 0) {

            const programMatches = programServiceDiseaseOrBrandLookup.filter(y => y.assistanceProgramId === assistanceProgramFilter);
            programMatches.sort((a: ProgramServiceDiseaseOrBrandLookup, b: ProgramServiceDiseaseOrBrandLookup) => numberComparer(a.assistanceServiceId, b.assistanceServiceId));
            programMatches.sort((a: ProgramServiceDiseaseOrBrandLookup, b: ProgramServiceDiseaseOrBrandLookup) => stringComparer(a.assistanceServiceName, b.assistanceServiceName));

            const serviceOptions: IOptionItem[] = [];
            programMatches.reduce((acc: IOptionItem[], item) => {
               if (!serviceOptions.some(y => y.value === item.assistanceServiceId) &&
                  (!assistanceProgramFilter || item.assistanceProgramId === assistanceProgramFilter)) {

                  const option = {
                     label: `[${item.assistanceServiceId}] ${item.assistanceServiceName}`,
                     value: item.assistanceServiceId
                  } as IOptionItem

                  serviceOptions.push(option);
               }
               return acc;
            }, []);

            const serviceMatches = assistanceServiceFilter ? programMatches.filter(y => y.assistanceServiceId === assistanceServiceFilter) : programMatches;
            serviceMatches.sort((a: ProgramServiceDiseaseOrBrandLookup, b: ProgramServiceDiseaseOrBrandLookup) => numberComparer(a.diseaseOrBrandId, b.diseaseOrBrandId));
            serviceMatches.sort((a: ProgramServiceDiseaseOrBrandLookup, b: ProgramServiceDiseaseOrBrandLookup) => stringComparer(a.diseaseOrBrand, b.diseaseOrBrand));

            const diseaseOrBrandOptions: IOptionItem[] = [];
            serviceMatches.reduce((acc: IOptionItem[], item) => {
               if (!diseaseOrBrandOptions.some(y => y.value === item.diseaseOrBrandId) &&
                  (!assistanceProgramFilter || item.assistanceProgramId === assistanceProgramFilter) &&
                  (!assistanceServiceFilter || item.assistanceServiceId === assistanceServiceFilter)) {

                  const option = {
                     label: `[${item.diseaseOrBrandId}] ${item.diseaseOrBrand}`,
                     value: item.diseaseOrBrandId
                  } as IOptionItem

                  diseaseOrBrandOptions.push(option);
               }
               return acc;
            }, []);

            setFilteredDetailAssistanceServiceOptions(serviceOptions);
            //if there is a url filter, and no service selected, then don't set the value           
            const asvcFilterIndex = serviceOptions.findIndex(y => y.value === assistanceServiceFilter);
            if (!urlFilter && asvcFilterIndex === -1) {
               setAssistanceServiceFilter(serviceOptions.length === 1 ? serviceOptions[0]?.value as number : undefined);
            } else if (urlFilter && asvcFilterIndex === -1 && assistanceServiceFilter) {
               setAssistanceServiceFilter(undefined);
            }

            setfilteredDetailDiseaseOrBrandOptions(diseaseOrBrandOptions);
            //if there is a url filter, and no diseaseOrBrand selected, then don't set the value
            const dbFilterIndex = diseaseOrBrandOptions.findIndex(y => y.value === diseaseOrBrandFilter);
            if (!urlFilter && dbFilterIndex === -1) {
               setDiseaseOrBrandFilter(diseaseOrBrandOptions.length === 1 ? diseaseOrBrandOptions[0]?.value as number : undefined);
            } else if (urlFilter && dbFilterIndex === -1 && diseaseOrBrandFilter) {
               setDiseaseOrBrandFilter(undefined);
            }
         }
      }
      loadFilteredDetailOptions();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [programServiceDiseaseOrBrandLookup,
      filteredItems,
      assistanceProgramFilter,
      assistanceServiceFilter,
      diseaseOrBrandFilter,
   ]);

   //////////////////////////////////////////

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [results,
      assistanceProgramFilter,
      assistanceServiceFilter,
      diseaseOrBrandFilter,
      selectedCohortItemStatusIds,
      urlFilter,
      sortField,
      sortDirection
   ]);

   const createFilteredItems = () => {
      if (!results || results.length <= 0) return;

      let newList = results.filter(model => {
         return filterAssistanceProgram(model) &&
            filterAssistanceService(model) &&
            filterDiseaseOrBrand(model) &&
            filterCohortItemStatusIds(model) &&
            filterUrl(model);
      }) ?? [];

      newList = sortItems(newList);

      setFilteredItems(newList);
   }

   const filterAssistanceProgram = (p: ProgramSummaryDetail): boolean => {
      return !assistanceProgramFilter || p.assistanceProgramId === assistanceProgramFilter ? true : false;
   }

   const filterAssistanceService = (p: ProgramSummaryDetail): boolean => {
      return !assistanceServiceFilter || p.assistanceServiceId === assistanceServiceFilter ? true : false;
   }

   const filterDiseaseOrBrand = (p: ProgramSummaryDetail): boolean => {
      return !diseaseOrBrandFilter || p.diseaseOrBrandId === diseaseOrBrandFilter ? true : false;
   }

   const filterCohortItemStatusIds = (p: ProgramSummaryDetail): boolean => {
      if (!selectedCohortItemStatusIds || selectedCohortItemStatusIds.length === 0) return true;
      return p.dataGroups.some(y => y.cohortItems.some(x => selectedCohortItemStatusIds.includes(x.cohortItemStatusId)));
   }

   const filterUrl = (p: ProgramSummaryDetail): boolean => {

      if (!urlFilter) return true;
      const result = p.dataGroups.some(y => y.cohortItems.some(x =>
         x.fieldType === FieldTypes.url &&
         x.value?.toLowerCase().indexOf(urlFilter.toLowerCase()) > -1));
      return result;
   }

   const sortItems = (inList: ProgramSummaryDetail[]) => {
      if (!inList || inList.length <= 0) return;
      return inList.sort((a: ProgramSummaryDetail, b: ProgramSummaryDetail) => {
         let sortValue: number;

         switch (sortField) {
            case "diseaseOrBrand":
               sortValue = stringComparer(a.diseaseOrBrand, b.diseaseOrBrand);
               break;
            default:
               sortValue = stringComparer(a.diseaseOrBrand, b.diseaseOrBrand);
         }

         return sortDirection === 'asc' ? sortValue : sortValue * -1;
      });
   }

   const renderModalEditor = (): React.ReactNode => {
      if (!modalEditor.opened)
         return
      
      return <DataGroupEditor
         key={22222}
         cohort={currentCohort}
         dataGroups={modalEditor.dataGroups ? [...modalEditor.dataGroups] : []}
         onClose={handleModalEditorClose}
         isOpen={modalEditor.opened}
      />
   };

   const renderModalStatusUpdateConfirm = (): React.ReactNode => {
      const html = (<EmptyModal
         key={22223}
         show={modalConfirm.opened}
         modalCss="medium">
         <Card
            title={modalConfirm.title}
            headingCss=""
            closeFunc={handleModalConfirmClose}>
            <div>
               <ApiErrorDisplay
                  title='Error loading data - try refreshing the browser or add additional search criteria.'
                  keys={errorKeys.keys} />

               <div className="padded-container">
                  <span>{`Saving Cohort Items as ${modalConfirm.newCohortItemStatus}`}</span>
                  <Spinner />
               </div>

            </div>
         </Card>
      </EmptyModal>)
      return html;
   };

   const setUrlFilterFunc = (url: string) => {
      setBeforeUrlFilter({
         assistanceProgramId: assistanceProgramFilter,
         assistanceServiceId: assistanceServiceFilter,
         diseaseOrBrandId: diseaseOrBrandFilter
      } as ISelectedProgramSummaryDetail);

      setAssistanceProgramFilter(undefined);
      setAssistanceServiceFilter(undefined);
      setDiseaseOrBrandFilter(undefined);
      setUrlFilter(url);
   }
   if (!currentCohort) {
      return <Spinner message='loading selected cohort..' />;
   }

   const isPublished = isCohortPublished(currentCohort);

   ////NOTE: this is used for the header and footer. if the row contains a different result, then the table will look wonky-*should not happen
   const dataGroupLayout: { idx: number, dataGroupId: number, dataGroupName: string }[] = [];
   filteredItems?.forEach(row => {
      row.dataGroups.forEach((dg, idx) => {
         if (dg.cohortItems?.length > 0 && !dataGroupLayout.some(x => x.dataGroupId === dg.dataGroupId)) {
            dataGroupLayout.push({ idx: idx, dataGroupId: dg.dataGroupId, dataGroupName: dg.dataGroupName });
         }
      })
   })

   const html = (
      <React.Fragment key={'asdf'}>
         <div style={{ padding: 10 }} className='cohortProgramSummary'>
            <Row gutter={[16, 16]}>
               <Col span={11}>
                  <BasicInputField
                     label='Url'
                     name="urlFilter"
                     value={urlFilter}
                     onChange={(e) => {
                        setUrlFilter(e as string)
                     }}
                  />
               </Col>
               <Col span={1} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', marginBottom: 20 }}>
                  {urlFilter &&
                     <span className="icon-edit" onClick={(e) => {
                        setAssistanceProgramFilter(beforeUrlFilter?.assistanceProgramId);
                        setAssistanceServiceFilter(beforeUrlFilter?.assistanceServiceId);
                        setDiseaseOrBrandFilter(beforeUrlFilter?.diseaseOrBrandId);
                        setUrlFilter(undefined);
                     }}>&#10060;</span>
                  }
               </Col>
               <Col span={12}>
                  <BasicCheckboxListField
                     label='Record State'
                     name="cohortItemStatusIds"
                     value={selectedCohortItemStatusIds}
                     options={cohortItemStatuses}
                     onChange={(e) => setSelectedCohortItemStatusIds(e as number[])}
                  />
               </Col>
            </Row>
            <Row gutter={[16, 16]}>
               <Col span={8}>
                  <BasicDropdownField
                     name='assistanceProgramId'
                     label='Assistance Program'
                     placeholder={' - Assistance Program -'}
                     multiple={false}
                     options={filteredDetailAssistanceProgramOptions}
                     value={assistanceProgramFilter}
                     search={true}
                     clearable={false}
                     onChange={(e) => {
                        setAssistanceProgramFilter(e as number);
                        setAssistanceServiceFilter(undefined);
                        setDiseaseOrBrandFilter(undefined);
                     }}
                  />
               </Col>
               <Col span={8}>
                  <BasicDropdownField
                     name='assistanceServiceId'
                     label='Assistance Service'
                     placeholder={' - Assistance Service -'}
                     multiple={false}
                     options={filteredDetailAssistanceServiceOptions}
                     value={assistanceServiceFilter}
                     search={true}
                     clearable={true}
                     onChange={(e) => {
                        setAssistanceServiceFilter(e as number);
                        setDiseaseOrBrandFilter(undefined);
                     }}
                  />
               </Col>
               <Col span={8}>
                  <BasicDropdownField
                     name='diseaseOrBrandId'
                     label='Disease Type / Brand'
                     placeholder={' - Disease Type / Brand -'}
                     multiple={false}
                     options={filteredDetailDiseaseOrBrandOptions}
                     value={diseaseOrBrandFilter}
                     search={true}
                     clearable={true}
                     onChange={(e) => setDiseaseOrBrandFilter(e as number)}
                  />
               </Col>
            </Row>
         </div>

         {isLoading &&
            <Spinner message='loading selected program summary detail data...' />
         }

         {filteredItems?.length > 0 &&
            <div className='table-scroll'>
               <table>
                  <thead>
                     <tr style={{ backgroundColor: colorWheel.mediumGrey }}>
                        <th style={{ width: 25, height: 35 }}>&nbsp;</th>
                        <th style={{ width: 150 }}>
                           <span onClick={() => setSortDirection((sortField === 'diseaseOrBrand' && sortDirection === 'asc') ? 'desc' : 'asc')}>
                              <>
                                 Disease Type \ Brand
                                 <CustomIcon style={{ color: colorWheel.skyBlue }} type={(sortField === 'diseaseOrBrand' && sortDirection === 'asc') ? CustomIconType.CaretUpFilled : CustomIconType.CaretDownFilled} />
                              </>
                           </span>
                        </th>
                        {dataGroupLayout.map((dataGroup, index: number) =>
                           <th key={index}>
                              {dataGroup.dataGroupName}
                           </th>
                        )}
                        <th style={{ width: 142 }}></th>
                     </tr>
                     {!isPublished &&
                        <tr>
                           <th style={{ width: 25 }}>&nbsp;</th>
                           <th style={{ width: 150, borderRight: `1px solid ${colorWheel.mediumGrey}` }}>&nbsp;</th>
                           {dataGroupLayout.map((dataGroup, index) =>
                              <th key={index} style={{ borderRight: `1px solid ${colorWheel.mediumGrey}`, borderBottom: `1px solid ${colorWheel.mediumGrey}` }}>
                                 <div className="link" style={{ whiteSpace: 'nowrap' }} onClick={() => handleEditDataGroupColumn(filteredItems, dataGroup.dataGroupId)}>Edit All <span className="icon-edit">&#9998;</span></div>
                              </th>
                           )}
                           <th style={{ width: 142 }}>&nbsp;</th>
                        </tr>
                     }
                  </thead>
                  <tbody style={{ maxHeight: 'calc(100vh - 415px)' }}>
                     {filteredItems.map((row: ProgramSummaryDetail, index: number) => {
                        const statusArray: number[] = getCohortItemStatusArray(row.dataGroups);
                        return (<tr key={index} style={{ backgroundColor: `${index % 2 === 0 ? colorWheel.white : colorWheel.lightGrey}` }}>
                           <td style={{ width: 25, backgroundColor: colorWheel.mediumGrey }} valign='top'>
                              <span title={`Program: ${row.programName} Id: ${row.assistanceProgramId}`}>
                                 <ItemStatusIndicator itemStatusArray={statusArray} />
                              </span>
                           </td>
                           <td style={{ width: 150, backgroundColor: colorWheel.mediumGrey }} valign='top'>
                              [{row.assistanceServiceId}] {row.diseaseOrBrand}
                           </td>
                           {dataGroupLayout.map(dgl => {
                              const dataGroup = row.dataGroups.find(y => y.dataGroupId === dgl.dataGroupId);
                              return (<td key={`${row.recordId}_${dgl.idx}`} style={{ wordBreak: 'break-all', padding: 3, borderRight: `1px solid ${colorWheel.mediumGrey}` }} valign='top'>
                                 {dataGroup.dataGroupId === BENEFIT_PERIOD_GROUP_ID &&
                                    <BenefitPeriodSummaryDisplay cohortItems={dataGroup.cohortItems} />
                                 }
                                 {dataGroup.dataGroupId !== BENEFIT_PERIOD_GROUP_ID && dataGroup.cohortItems?.map((item: CohortItem, fieldIdx: number) => {
                                    return (<React.Fragment key={fieldIdx}>
                                       <div>
                                          <span className='ant-form-item-label' title={item.labelText}>
                                             <label>{truncateStringStart(item.labelText, 9)}:</label>
                                          </span>
                                          {PrintValue(item.fieldType, item.value, setUrlFilterFunc)}
                                       </div>
                                    </React.Fragment>)
                                 }
                                 )}
                                 <ItemStatusIndicator itemStatusArray={getCohortItemStatusArray([dataGroup])} />
                                 {
                                    !isPublished && dataGroup.cohortItems?.length > 0 &&
                                    <span className="icon-edit" title={`DataGroup [${dataGroup.dataGroupId}] ${dataGroup.dataGroupName}`} onClick={() => handleEditDataGroup(dataGroup)}>&#9998;</span>
                                 }
                              </td>)
                           }
                           )}
                           <td valign='top' style={{ width: 125 }}>
                              {!isPublished && showConfirmChange(statusArray) &&
                                 <div className='link' onClick={() => handleConfirmRow(row, CohortItemStatusType.Audited_Confirm_No_Change)}><span className="icon-edit">&#128065;</span> CONFIRM ROW</div>
                              }
                              {!isPublished &&
                                 <Restricted requiredRoles={[KnownSettings.CohortAdmin]}>
                                    {showAcceptChange(statusArray) &&
                                       <div className='link' onClick={() => handleConfirmRow(row, CohortItemStatusType.Accepted_Change)}><span className="icon-edit">&#10004;</span> ACCEPT ROW</div>//'&#9989;' ✅ //&#10004; ✔ 
                                    }
                                    {showRejectChange(statusArray) &&
                                       <div className='link' onClick={() => handleConfirmRow(row, CohortItemStatusType.Rejected_Change)}><span className="icon-edit">&#9940;</span> REJECT ROW</div>
                                    }
                                 </Restricted>
                              }
                           </td>
                        </tr>)
                     })}
                  </tbody>
                  {!isPublished &&
                     <tfoot>
                        <tr>
                           <td style={{ width: 25 }}>&nbsp;</td>
                           <td style={{ width: 150 }}>&nbsp;</td>
                           {dataGroupLayout.map((dataGroup) => {
                              const statusArray: number[] = getColumnCohortItemStatusArray(filteredItems, dataGroup.idx);
                              return (
                                 <td key={dataGroup.idx} title={dataGroup.dataGroupName} style={{ border: `1px solid ${colorWheel.mediumGrey}` }}>
                                    {showConfirmChange(statusArray) &&
                                       <div className='link'
                                          onClick={() => handleConfirmColumn(filteredItems, dataGroup.idx, CohortItemStatusType.Audited_Confirm_No_Change)}>
                                          <span className="icon-edit">&#128065;</span>
                                          CONFIRM COL
                                       </div>
                                    }
                                    <Restricted requiredRoles={[KnownSettings.CohortAdmin]}>
                                       {showAcceptChange(statusArray) &&
                                          <div className='link' onClick={() => handleConfirmColumn(filteredItems, dataGroup.idx, CohortItemStatusType.Accepted_Change)}><span className="icon-edit">&#10004;</span> ACCEPT COL</div>//'&#9989;' ✅ //&#10004; ✔ 
                                       }
                                       {showRejectChange(statusArray) &&
                                          <div className='link' onClick={() => handleConfirmColumn(filteredItems, dataGroup.idx, CohortItemStatusType.Rejected_Change)}><span className="icon-edit">&#9940;</span> REJECT COL</div>
                                       }
                                    </Restricted>
                                 </td>)
                           }
                           )}
                           <td style={{ width: 142 }}>&nbsp;</td>
                        </tr>
                     </tfoot>
                  }
               </table>
            </div>
         }
      </React.Fragment>
   );

   const _modalEditor = renderModalEditor();
   const _modalConfirm = renderModalStatusUpdateConfirm();

   return (
      <>
         {
            [html,
               _modalEditor,
               _modalConfirm]
         }
      </>)
}

export default ProgramSummaryDetailList;

const PrintValue = (fieldType: string, value: string, handleUrlFilter: (url: string) => void): any => {
   let html = null;
   if (fieldType === FieldTypes.url && isValidHttpUrl(value)) {
      html = <>
         <a href={value} className="" target="_blank" rel="noopener noreferrer">{value}</a>
         <span className="icon-edit" onClick={() => handleUrlFilter(`${value}`)}>&#128270;</span>
      </>
   } else {
      html = <span className="uk-text-muted">{value}</span>
   }
   return html;
}

const showConfirmChange = (statusArray: number[]): boolean => {
   return statusArray.some(y => y === CohortItemStatusType.New);
}

const showAcceptChange = (statusArray: number[]): boolean => {
   const hasAnyNew = statusArray.some(y => y === CohortItemStatusType.New);
   if (hasAnyNew)
      return false;
   else
      return statusArray.some(y =>
         y === CohortItemStatusType.Recommended_Change ||
         y === CohortItemStatusType.Rejected_Change);
}

const showRejectChange = (statusArray: number[]): boolean => {
   const hasAnyNew = statusArray.some(y => y === CohortItemStatusType.New);
   if (hasAnyNew)
      return false;
   else
      return statusArray.some(y => (y === CohortItemStatusType.Recommended_Change));
}

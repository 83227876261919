import { Space, Table, Row, Col, TableProps } from 'antd';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/lib/table';
import * as React from 'react';
import { formatDateTimeString } from '../../../functions/format.functions';
import {
   PracticeServiceToApXRefViewModel,
   xrefStatusOptions,
   PracticeServiceToApXRefSearchFilter,
   NEW_STATUS
} from '../../../store/xref/PracticeServiceToApXRefModel';
import {
   useFetchXRefAssistancePrograms, useFetchXRefPractices, usePracticeServiceToApXRefSearchViewModel
} from '../../../store/xref/PracticeServiceToApXRefFetcher';
import HighlightSearchText from '../../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../../shared/AntComponents/CustomIcon';
import { DebouncedBasicInputField } from '../../shared/BasicInputLibrary';
import { ActionButton, EditButton } from '../../shared/Buttons';
import Spinner from '../../Spinner';
import PracticeServiceToApXRefEditor from './PracticeServiceToApXRefEditor';
import PracticeServiceToApXRefBulkEditor from './PracticeServiceToApXRefBulkEditor';
import { SearchResultsTableWithFilters } from '../../shared/AntComponents/Table/SearchResultsTableWithFilters';
import MultiSelectColumnFilter from '../../shared/AntComponents/Filter/MultiSelectColumnFilter';
import ColumnFilter from '../../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../../shared/AntComponents/Filter/FilteredInfo';
import Restricted from '../../../auth/Restricted';
import { KnownSettings } from '../../../store/SettingsModel';
import { useApiContext } from '../../../store/ApiContext';
import PracticeServiceToApXRefBulkProgramType from './PracticeServiceToApXRefBulkProgramType';
import { IOptionItem } from '../../../functions/option.functions';
import { antSortOptions } from '../../shared/AntComponents/Table/table.functions';

const PRACTICE_TITLE = 'Practice';
const INSURANCE_PLAN_TITLE = 'Insurance Plan';
const ASSISTANCE_PROGRAM_TITLE = 'Assistance Program';
const STATUS_TITLE = 'Status';
type searchFilter = 'practice' | 'insurancePlan' | 'assistanceProgram' | 'status';
const defaultFilterValue: Record<searchFilter, IFilteredInfo> = {
   practice: undefined,
   insurancePlan: undefined,
   assistanceProgram: undefined,
   status: undefined
};
const initSearchFilter: PracticeServiceToApXRefSearchFilter = {
   page: 1,
   pageSize: 25,
   sortDirection: 'desc',
   sortField: 'id',
}
const PracticeServiceToApXRefList: React.FC = () => {
   const { httpGet, httpPost } = useApiContext();
   const [searchFilter, setSearchFilter] = React.useState<PracticeServiceToApXRefSearchFilter>(initSearchFilter);

   const { xRefPractices } = useFetchXRefPractices(httpGet);
   const intrinsiqPayerPracticeOptions = React.useMemo(() => {
      return xRefPractices?.map((o): IOptionItem => ({
         label: `[${o.id}]: ${o.name}`,
         value: o.id
      } as IOptionItem))
   }, [xRefPractices]);

   const { xRefAssistancePrograms } = useFetchXRefAssistancePrograms(httpGet);
   const intrinsiqPayerProgramOptions = React.useMemo(() => {
      return xRefAssistancePrograms?.map((o): IOptionItem => ({
         label: `[${o.id}]: ${o.name}`,
         value: o.id
      } as IOptionItem));
   }, [xRefAssistancePrograms]);

   const [selectedItem, setSelectedItem] = React.useState<PracticeServiceToApXRefViewModel>(undefined);
   const [isEditorOpen, setIsEditorOpen] = React.useState<boolean>(false);
   const [newFromItem, setNewFromItem] = React.useState<PracticeServiceToApXRefViewModel>(undefined);
   const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
   const [bulkItems, setBulkItems] = React.useState<number[]>([]);
   const [isBulkEditorOpen, setIsBulkEditorOpen] = React.useState<boolean>(false);
   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilter, IFilteredInfo>>(defaultFilterValue);
   const [isBulkProgramTypeModalOpen, setIsBulkProgramTypeModalOpen] = React.useState<boolean>(false);

   const { pagedResult } = usePracticeServiceToApXRefSearchViewModel(httpGet, httpPost, searchFilter);

   React.useEffect(() => {
      const newSearchFilter = {
         ...searchFilter,
         insurancePlanLike: filteredInfo?.insurancePlan?.value as string,
         practiceIds: filteredInfo.practice?.values?.map(y => y.value as number),
         assistanceProgramIds: filteredInfo.assistanceProgram?.values?.map(y => y.value as number),
         statuses: filteredInfo.status?.values?.map(y => y.value as string),
      }

      setSearchFilter(newSearchFilter);

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [filteredInfo]);

   const handleOnChange: TableProps<PracticeServiceToApXRefViewModel>['onChange'] = (pagination, filters, sorter, extra) => {
     
      const newSearchFilter = {
         ...searchFilter,
         insurancePlanLike: filteredInfo?.insurancePlan?.value as string,
         practiceIds: filteredInfo.practice?.values?.map(y => y.value as number),
         assistanceProgramIds: filteredInfo.assistanceProgram?.values?.map(y => y.value as number),
         statuses: filteredInfo.status?.values?.map(y => y.value as string),
         page: pagination?.current
      }
      const theSorter = Array.isArray(sorter) ? sorter[0] as SorterResult<PracticeServiceToApXRefViewModel> : sorter as SorterResult<PracticeServiceToApXRefViewModel>;
      if (sorter) {
         if (sorter.hasOwnProperty('field')) {
            newSearchFilter.sortField = theSorter.field as string;
            newSearchFilter.sortDirection = theSorter.order?.indexOf('desc') > -1 ? 'desc' : 'asc';
         }
      }
      onClearBulkSelection(); //reset bulk items on page\sort
      setSearchFilter(newSearchFilter);
   }

   const canBulkEditSelectedBulkItems = (): boolean => {
      if (!bulkItems || bulkItems?.length === 0) return false;

      // We can only bulk edit with records with 'New' status
      const canEdit = pagedResult.data.filter(y => bulkItems.includes(y.id)).every(y => y.status === NEW_STATUS);
      return canEdit;
   }

   const handleCloseEditorClick = () => {
      setIsEditorOpen(false);
      setSelectedItem(undefined);
   }

   const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      const arr = newSelectedRowKeys.map((k) => { return Number(k) });
      setBulkItems(arr);
      setSelectedRowKeys(newSelectedRowKeys);
   }

   const rowSelection: TableRowSelection<object> = {
      selectedRowKeys,
      onChange: onSelectChange,
   };

   const clickBulkEditButton = () => {
      setIsBulkEditorOpen(true);
   }

   const handleBulkEditClose = () => {
      setIsBulkEditorOpen(false);
      if (bulkItems?.length > 0) {
         setBulkItems([]);
      }
      if (selectedRowKeys?.length > 0) {
         setSelectedRowKeys([]);
      }   
   }

   const onClearBulkSelection = () => {
      if (bulkItems?.length > 0) {
         setBulkItems([]);
      }
      if (selectedRowKeys?.length > 0) {
         setSelectedRowKeys([]);
      }      
   }

   const closeBulkProgramTypeModal = () => {
      setIsBulkProgramTypeModalOpen(false);
      setSelectedItem(undefined);
   }

   if (!pagedResult) {
      return <div><Spinner message='loading PRACTICE_SERVICE_TO_AP_XREF...' /></div>
   }

   const tableColumns: ColumnsType<PracticeServiceToApXRefViewModel> = [
      {
         title: 'Id',
         dataIndex: 'id',
         width: 100,
         sorter: true,
         sortDirections: antSortOptions,
         defaultSortOrder: 'descend',
      },
      {
         title: 'PId',
         dataIndex: 'practiceId',
         width: 100,
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            // Since "text" here is actually a number we need to convert it to a string.  "text as string" was resulting in errors when attempting to split in HighlightSearchText so I used String(...)
            <HighlightSearchText searchString={filteredInfo?.practice?.values.some(y => y.value === record.practiceId) ? String(text) : ''} targetString={`${record.practiceId}`} />
      },
      {
         title: <ColumnFilter title={PRACTICE_TITLE}
            filteredInfo={filteredInfo?.practice}
            content={<MultiSelectColumnFilter
               options={intrinsiqPayerPracticeOptions}
               searchPlaceholder={`Filter by ${PRACTICE_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'practice'}
               filteredInfoTitle={PRACTICE_TITLE}
            />}
         />,
         dataIndex: 'practiceName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.practice?.values.some(y => y.value === record.practiceId) ? text : ''} targetString={record.practiceName} />
      },
      {
         title: <ColumnFilter title={INSURANCE_PLAN_TITLE}
            filteredInfo={filteredInfo?.insurancePlan}
            content={<DebouncedBasicInputField
               label={`Filter by ${INSURANCE_PLAN_TITLE}:`}
               placeholder={`Filter by ${INSURANCE_PLAN_TITLE}`}
               value={filteredInfo.insurancePlan?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                  ...filteredInfo,
                  insurancePlan: {
                     title: INSURANCE_PLAN_TITLE,
                     value: e ? e as string : undefined
                  }
               })
               }}
            />}
         />,
         dataIndex: 'insurancePlan',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => {
            return (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <div style={{ overflow: 'auto' }}>
                  <HighlightSearchText searchString={filteredInfo?.insurancePlan?.value as string} targetString={record.insurancePlan} />
               </div>
               <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                  <div>
                     <ActionButton
                        onClick={() => {
                           setSelectedItem(record);
                           setIsBulkProgramTypeModalOpen(true);
                        }}
                        icon={<CustomIcon type={CustomIconType.CopyOutlined} />}
                        title='Bulk Map by Program Type' />
                  </div>
               </Restricted>
            </div>)
         }
      },
      Table.SELECTION_COLUMN,
      {
         title: 'APID',
         dataIndex: 'assistanceProgramId',
         sorter: true,
         sortDirections: antSortOptions,
         width: 100,
      },
      {
         title: <ColumnFilter title={ASSISTANCE_PROGRAM_TITLE}
            filteredInfo={filteredInfo?.assistanceProgram}
            content={<MultiSelectColumnFilter
               options={intrinsiqPayerProgramOptions}
               searchPlaceholder={`Filter by ${ASSISTANCE_PROGRAM_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'assistanceProgram'}
               filteredInfoTitle={ASSISTANCE_PROGRAM_TITLE}
            />}
         />,
         dataIndex: 'programName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.assistanceProgram?.values.some(y => y.value === record.assistanceProgramId) ? text : ''} targetString={record.programName} />
      },
      {
         title: <ColumnFilter title={STATUS_TITLE}
            filteredInfo={filteredInfo?.status}
            content={<MultiSelectColumnFilter
               options={xrefStatusOptions}
               searchPlaceholder={`Filter by ${STATUS_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'status'}
               filteredInfoTitle={STATUS_TITLE}
            />}
         />,
         dataIndex: 'status',
         width: 100,
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo?.status?.values.some(y => y.value === record.status) ? text : ''} targetString={record.status} />
      },
      {
         title: 'Notes',
         dataIndex: 'notes',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => record.notes
      },
      {
         title: 'Modified On',
         dataIndex: 'modifiedOn',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatDateTimeString(record.modifiedOn)
      },
      {
         title: '',
         dataIndex: 'actions',
         fixed: 'right',
         width: 100,
         render: (text, record) => <Space>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <EditButton
                  title='Edit'
                  onClick={() => {
                     setIsEditorOpen(true);
                     setSelectedItem(record);
                     setNewFromItem(undefined);
                  }}
               />
            </Restricted>
            <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <ActionButton
                  title='New From Copy'
                  icon={<CustomIcon type={CustomIconType.CopyOutlined} />}
                  onClick={() => {
                     setIsEditorOpen(true);
                     setSelectedItem(undefined);
                     setNewFromItem({
                        ...record,
                        id: undefined
                     })
                  }}
               />
            </Restricted>
         </Space>
      },
   ];


   const html = (
      <>
         <SearchResultsTableWithFilters
            rowkey={'id'}
            onChange={handleOnChange}
            columns={tableColumns}
            data={pagedResult.data}
            serverSideRowCount={pagedResult.totalRecords}
            currentPage={searchFilter.page}
            currentPageSize={searchFilter.pageSize}
            setCurrentPageSize={newPageSize => {
               setSearchFilter({
                  ...searchFilter,
                  page: 1, //reset the page to keep us on the page
                  pageSize: Number(newPageSize)
               })
            }}
            titleText='PRACTICE_SERVICE_TO_AP_XREF'
            rowSelection={rowSelection}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            fixedHeader={true}
            onFiltersClear={() => setFilteredInfo(defaultFilterValue)}
            size='small'
            scrollY='calc(100vh - 300px)'
            footer={<Row justify="end">
               <Col>
                  <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
                     <Space>
                        <ActionButton
                           buttonText='Clear Bulk Selection'
                           onClick={onClearBulkSelection}
                           disabled={bulkItems.length < 1}
                           icon={<CustomIcon type={CustomIconType.CloseOutlined} />}
                        />
                        <ActionButton
                           title={"Bulk Edit 'New' Status records"}
                           buttonText='Edit Bulk'
                           disabled={bulkItems.length < 1 || !canBulkEditSelectedBulkItems()}
                           onClick={() => clickBulkEditButton()}
                           icon={<CustomIcon type={CustomIconType.EditOutlined} />}
                        />
                     </Space>
                  </Restricted>
               </Col>
            </Row>}
         />

         {isEditorOpen &&
            <PracticeServiceToApXRefEditor
               isOpen={isEditorOpen}
               closeEditor={() => handleCloseEditorClick()}
               editFrom={selectedItem}
               newFrom={newFromItem}
            />
         }
         {isBulkEditorOpen &&
            <PracticeServiceToApXRefBulkEditor
               isOpen={isBulkEditorOpen}
               closeEditor={() => handleBulkEditClose()}
               bulkItemsToEdit={pagedResult.data?.filter(y => bulkItems.includes(y.id))}
            />
         }
         {isBulkProgramTypeModalOpen &&
            <PracticeServiceToApXRefBulkProgramType
               isOpen={isBulkProgramTypeModalOpen}
               record={selectedItem}
               closeEditor={() => closeBulkProgramTypeModal()}
            />
         }
      </>
   )
   return html;
}

export default PracticeServiceToApXRefList;
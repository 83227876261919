import * as React from 'react';
import { useParams } from 'react-router-dom';
import ContentCard from '../../layouts/ContentCard';
import { useLayoutContext } from '../../layouts/LayoutContext';
import FaxIndex from '../fax/FaxIndex';
import FundingStatusIndex from '../foundation/FundingStatusIndex';
import PracticeIndex from '../practice/PracticeIndex';
import PracticeSubscriptionIndex from '../practice/PracticeSubscriptionIndex';
import UserManagementIndex from '../practice/userprofile/UserManagementIndex';
import "./Content.css";
import DashboardContentList from './DashboardContentList';
import DiseaseTypeIndex from './DiseaseTypeIndex';
import ApplicationStatus from './EnrollmentStatusList';
import FoundationList from './FoundationList';
import ManufacturerList from './ManufacturerList';
import BillingCodeIndex from './drug/BillingCodeIndex';
import BrandIndex from './drug/BrandIndex';
import CptIndex from './drug/CptIndex';
import DrugIndex from './drug/DrugIndex';
import GenericDrugIndex from './drug/GenericDrugIndex';
import NdcIndex from './drug/NdcIndex';
import AssistanceProgramIndex from './program/AssistanceProgramIndex';
import AssistanceProgramResourceList from './program/AssistanceProgramResourceList';
import AssistanceServiceBrandDefaultsIndex from './program/AssistanceServiceBrandDefaultsIndex';
import AssistanceServiceEligibilityCriteriaIndex from './program/AssistanceServiceEligibilityCriteriaIndex';
import AssistanceServiceFoundationDefaultsIndex from './program/AssistanceServiceFoundationDefaultsIndex';
import ManualEnrollmentReasonOptionIndex from './program/ManualEnrollmentReasonOptionIndex';
import AssistPointReportManagement from './report/AssistPointReportManagement';
import PowerBIReportIndex from './report/PowerBIReportIndex';
import SpecialtyList from './specialty/SpecialtyList';
import ContentClassificationIndex from './xref/ContentClassificationIndex';
import IntrinsiqPayerToApXRefList from './xref/IntrinsiqPayerToApXRefList';
import PracticeServiceToApXRefList from './xref/PracticeServiceToApXRefList';
import RingCallQueueIndex from "../practice/RingCallQueueIndex";
import DocuSignIndex from '../docusign/DocuSignIndex';

type Props =
   & {
      setCardHeader?: (title: string) => void
   };

//container to allow selecting the proper table list\editor
export const ContentTool: React.FC<Props> = (props: Props) => {

   return (
      <ContentToolIndex {...props} />
   );

}
export default ContentTool;


const ContentToolIndex: React.FC<Props> = (props: Props) => {

   const params = useParams();
   const { tableName } = params;

   // TargetComponent will hold the component to which we will route the browser
   let TargetComponent: React.FC<Props>;

   // will hold the info text that will be displayed when hovering over the "i" icone
   let targetComponentInfoText: React.ReactElement<React.ReactHTML> = undefined;

   const [, setCardTitle] = React.useState<string>("");

   switch (tableName) {
      case "PRACTICE_SERVICE_TO_AP_XREF":
         TargetComponent = PracticeServiceToApXRefList;
         break;
      case "ASSISTPOINT_REPORT_MANAGEMENT":
         TargetComponent = AssistPointReportManagement;
         break;
      case "REPORT_MANAGEMENT":
         TargetComponent = PowerBIReportIndex;
         break;
      case "CONTENT_CLASSIFICATION_XREF":
         TargetComponent = ContentClassificationIndex;
         targetComponentInfoText = <>
            Use this page to assign and maintain 'Assigned Class' field.  The data is loaded from the insurance_payer_class_xref
            table in the analytics database.  The values for the Assigned Class drop down are loaded from insuranceclass_summary
            also in the analytics database.
            <ul>
               <li>"New Only" will filter by records that have an "Assigned Class" of "Unknown".</li>
               <li>Value will automatically save every time a new value is selected in the "Assigned
                  Class" drop down for each row.</li>
            </ul>
         </>;
         break;

      case "MANUAL_ENROLLMENT_REASONS":
         TargetComponent = ManualEnrollmentReasonOptionIndex;
         break;
      case "INTRINSIQPAYERTOAPXREF":
         TargetComponent = IntrinsiqPayerToApXRefList;
         break;
      case "MANUFACTURER":
         TargetComponent = ManufacturerList;
         break;
      case "DASHBOARDCONTENT":
         TargetComponent = DashboardContentList;
         break;
      case "DISEASE_TYPE":
         TargetComponent = DiseaseTypeIndex;
         break;
      case "FOUNDATION":
         TargetComponent = FoundationList;
         break;
      case "FUNDINGSTATUS":
         TargetComponent = FundingStatusIndex;
         break;
      case "ASSISTANCE_PROGRAM_RESOURCE":
         TargetComponent = AssistanceProgramResourceList;
         break;
      case "ASSISTANCE_PROGRAM":
         TargetComponent = AssistanceProgramIndex;
         break;
      case "ENROLLMENT_STATUS":
         TargetComponent = ApplicationStatus;
         break;
      case "PRACTICE":
         TargetComponent = PracticeIndex;
         break;
      case "PRACTICESUBSCRIPTION":
         TargetComponent = PracticeSubscriptionIndex;
         break;
      case "USERMANAGEMENT":
         TargetComponent = UserManagementIndex;
         break;
      case "SPECIALTY":
         TargetComponent = SpecialtyList;
         break;
      case "GENERICDRUG":
         TargetComponent = GenericDrugIndex;
         break;
      case "DRUG":
         TargetComponent = DrugIndex;
         break;
      case "BRAND":
         TargetComponent = BrandIndex;
         break;
      case "NDC":
         TargetComponent = NdcIndex;
         break;
      case "BILLINGCODE":
         TargetComponent = BillingCodeIndex;
         break;
      case "CPT":
         TargetComponent = CptIndex;
         break;
      case "ASSISTANCESERVICEBRANDDEFAULTS":
         TargetComponent = AssistanceServiceBrandDefaultsIndex;
         break;
      case "ASSISTANCESERVICEFOUNDATIONDEFAULTS":
         TargetComponent = AssistanceServiceFoundationDefaultsIndex;
         break;
      case "ASSISTANCESERVICEELIGIBILITYCRITERIA":
         TargetComponent = AssistanceServiceEligibilityCriteriaIndex;
         break;
      case "FAXMANAGEMENT":
         TargetComponent = FaxIndex;
         break;
      case "RINGCALLQUEUE":
         TargetComponent = RingCallQueueIndex;
         break;
      case "DOCUSIGN":
         TargetComponent = DocuSignIndex;
         break;
   }

   const { cardName, cardHeaderTotalCount, cardHeaderFilteredCount } = useLayoutContext();
   const newProps = { ...params, setCardHeader: setCardTitle };

   const subTitle = React.useMemo(() => {
      return cardHeaderTotalCount && cardHeaderFilteredCount >= 0
         ? `Viewing (${cardHeaderFilteredCount}) of (${cardHeaderTotalCount})` : null
   },[cardHeaderTotalCount, cardHeaderFilteredCount])

   return (
      <ContentCard title={cardName}
         subTitle={subTitle}
         infoText={targetComponentInfoText} >
         {
            (TargetComponent === undefined)
               ? <p>Table "{tableName}" not implemented yet.  Add Editor component and submenu link.</p>
               : <TargetComponent {...newProps} />

         }
      </ContentCard>
   )
}

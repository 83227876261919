import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { HttpError, TGet, TPost, TPut } from '../../functions/httpClient';
import { createKey, mutateSearch, swrOptions } from '../../functions/swr.functions';
import { getEndOfDayAsDate, getStartOfDayAsDate } from '../../functions/time.functions';
import { IStatus, NameAndId, PagedList } from '../models';
import { DocuSignSearchFilter, DocuSignSearchResult } from './DocuSignRequestModels';

export const docuSignRequestBaseUrl = `api/DocuSignRequest`;
export const searchDocuSignRequestUrl = `${docuSignRequestBaseUrl}/Search`;
const practicesUrl = `${docuSignRequestBaseUrl}/GetPractices`
const programsUrl = `${docuSignRequestBaseUrl}/GetPrograms`

//keeping last key to trigger revalidate 
export let _lastFetchKey_DocuSignRequest: string;

export function useDocuSignRequestSearch(httpPost: TPost, search: DocuSignSearchFilter): IStatus & { pagedResult: PagedList<DocuSignSearchResult>, refreshDate: Date } {
   const [refreshCheck, setRefeshCheck] = useState<Date>(new Date());
   const params = useMemo(() => {
      const utcSearch: DocuSignSearchFilter = {
         ...search,
         submittedDateFrom: search.submittedDateFrom ? getStartOfDayAsDate(search.submittedDateFrom) : undefined,
         submittedDateTo: search.submittedDateTo ? getEndOfDayAsDate(search.submittedDateTo) : undefined
      }

      return utcSearch
   }, [search]);

   _lastFetchKey_DocuSignRequest = createKey(docuSignRequestBaseUrl, params);

   const fetcher = () => httpPost<PagedList<DocuSignSearchResult>>(searchDocuSignRequestUrl, params);

   const { data, isLoading, isValidating, error } = useSWR<PagedList<DocuSignSearchResult>, HttpError>(
      _lastFetchKey_DocuSignRequest,
      fetcher,
      {
         ...swrOptions,
         refreshInterval: 1000 * 60 * 15,
         dedupingInterval: 0
      }
   );

   useEffect(() => {
      setRefeshCheck(new Date());
   }, [isValidating]);

   return {
      pagedResult: data,
      isLoading: isLoading,
      error: error?.message,
      refreshDate: refreshCheck
   };
}


export const mutateDocuSignRequestSearch = async () => {
   mutateSearch(docuSignRequestBaseUrl, _lastFetchKey_DocuSignRequest);
} 

export function useFetchDocuSignPractices(httpGet: TGet): IStatus & { practices: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      practicesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      practices: data,
      isLoading: isLoading,
      error: error?.message
   };
}
export function useFetchDocuSignPrograms(httpGet: TGet): IStatus & { programs: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      programsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      programs: data,
      isLoading: isLoading,
      error: error?.message
   };
}


function mutate(docuSignRequestBaseUrl: string) {
    throw new Error('Function not implemented.');
}

import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useApiContext } from '../../store/ApiContext';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Spinner from '../Spinner';
import Dialog from '../Dialog';
import { ActionButton, CancelButton, DeleteButton, SaveButton } from '../shared/Buttons';
import { Alert, Col, Form, Row, message } from 'antd';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { useErrorContext } from '../../store/ErrorContext';
import { DocuSignSearchResult } from '../../store/docusign/DocuSignRequestModels';
import { docuSignRequestBaseUrl } from '../../store/docusign/DocuSignRequestFetcher';
import { docuSignBaseUrl, resendEnvelope, sendUpdatedRecipients, voidEnvelope } from '../../store/docusign/DocuSignFetcher';
import { UpdateRecipientsESignatureModel } from '../../store/docusign/DocuSignModels';
import { validateEmail } from '../../functions/form.validators';
import {
   DocuSignRoles, getLastEvent, getPatientEvents, getProviderEvents
} from '../../store/docusign/DocuSignHelper';
import { InputField } from '../shared/InputLibrary';
import { objectsEqual } from '../../functions/common.functions';
import { stringify } from '../../functions/format.functions';

interface IProps {
   docuSignRequest: DocuSignSearchResult;
   isOpen: boolean;
   close: () => void;
}

interface RecipientFormModel {
   applicationId: number;
   providerName: string;
   providerEmail: string;
   providerCode: string;
   patientName: string;
   patientEmail: string;
   patientCode: string;
}

const yupDocuSignResendSchema = yup.object({
   applicationId: yup.number().required('ApplicationId is required'),
   providerName: yup.string()
      .max(100, 'Provider Name must not exceed 100 characters')
      .required('Provider Name Name is Required')
      .typeError('Provider Name Name is required.')
      .trim(),
   providerEmail: yup.string()
      .max(100, 'Provider Email Address must not exceed 100 characters')
      .required('Provider Email Address is Required')
      .typeError('Provider Email Address is required.')
      .test('providerEmail', 'Provider Email Address must be a valid email', value => validateEmail(value))
      .trim(),
   providerCode: yup.string()
      .max(50, 'Provider Code must not exceed 50 characters')
      .required('Provider Code is Required')
      .typeError('Provider Code is required.')
      .trim(),
   patientName: yup.string()
      .max(100, 'Patient Name must not exceed 100 characters')
      .required('Patient Name is Required')
      .typeError('Patient Name is required.')
      .trim(),
   patientEmail: yup.string()
      .max(100, 'Patient Email Address must not exceed 100 characters')
      .required('Patient Email Address is Required')
      .typeError('Patient Email Address is required.')
      .test('patientEmail', 'Patient Email Address must be a valid email', value => validateEmail(value))
      .trim(),
   patientCode: yup.string()
      .max(50, 'Patient Code must not exceed 50 characters')
      .required('Patient Code is Required')
      .typeError('Patient Code is required.')
      .trim(),
});

interface IDocuSignResendYup extends yup.Asserts<typeof yupDocuSignResendSchema> { }
const __formId = "frmDocuSignOperations";
const _keysLike: string[] = [docuSignRequestBaseUrl, docuSignBaseUrl];

const DocuSignOperationsDialog: React.FC<IProps> = (props) => {

   const { docuSignRequest, isOpen, close } = props;
   const { httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);
   const [resultMessage, setResultMessage] = React.useState<string>();
   const [isSuccess, setIsSuccess] = React.useState<boolean>();
   //const [isVoidDialogOpen, setIsVoidDialogOpen] = React.useState<boolean>(false);

   const { recipientModel } = React.useMemo(() => {
      const patientEvent = getLastEvent(getPatientEvents(docuSignRequest?.docuSignEvents));
      const providerEvent = getLastEvent(getProviderEvents(docuSignRequest?.docuSignEvents));

      const recipientModel = {
         applicationId: docuSignRequest.applicationId,
         providerName: providerEvent?.recipientName,
         providerEmail: providerEvent?.recipientEmail,
         providerCode: providerEvent?.recipientAccessCode,
         patientName: patientEvent?.recipientName,
         patientEmail: patientEvent?.recipientEmail,
         patientCode: patientEvent?.recipientAccessCode,
      } as RecipientFormModel;

      return { recipientModel }
      // eslint-disable-next-line
   }, [docuSignRequest, isOpen])

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset
   } = useForm<IDocuSignResendYup>({
      resolver: yupResolver<yup.AnyObject>(yupDocuSignResendSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      reset(recipientModel);
      setIsFormSaving(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [recipientModel, isOpen]);

   const onSubmit = (data: IDocuSignResendYup): void => {
      //console.log('--------------DocuSignResend-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const formModel = data as unknown as RecipientFormModel;
      if (objectsEqual(recipientModel, formModel))
         handleResendEnvelope();
      else
         handleUpdateAndResendEnvelope(formModel);
   }

   const handleUpdateAndResendEnvelope = (formModel: RecipientFormModel) => {
      const model = {
         applicationId: formModel.applicationId,
         recipients: [
            {
               key: DocuSignRoles.Patient,
               name: formModel.patientName,
               email: formModel.patientEmail,
               code: formModel.patientCode
            },
            {
               key: DocuSignRoles.Provider,
               name: formModel.providerName,
               email: formModel.providerEmail,
               code: formModel.providerCode
            }
         ]
      } as UpdateRecipientsESignatureModel;

      setIsFormSaving(true);
      sendUpdatedRecipients(httpPost, model)
         .then((m) => {
            setIsSuccess(true);
            setResultMessage(`Updated Recipients and Resend Envelope: ${stringify(m)}`);
         })
         .catch(err => {
            console.log(`Error Saving`, err)
         })
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleResendEnvelope = () => {
      //nothing changed, just resend the envelope
      setIsFormSaving(true);
      resendEnvelope(httpPost, docuSignRequest.applicationId)
         .then(() => {
            setIsSuccess(true);
            setResultMessage('Resend Envelope Completed Successfully');
         })
         .catch(err => console.error('Error Resending Envelope', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   //const handleVoidClick = () => {
   //   setIsFormSaving(true);
   //   voidEnvelope(httpPost, docuSignRequest.applicationId)
   //      .then(() => {
   //         setIsSuccess(true);
   //         setResultMessage('Resend Envelope Completed Successfully');
   //      })
   //      .catch(err => console.error('Error Voiding Envelope', err))
   //      .finally(() => {
   //         setIsFormSaving(false);
   //      });
   //}

   const handleClose = () => {
      /*setIsVoidDialogOpen*/(false);
      setIsSuccess(undefined);
      setIsFormSaving(false);
      removeErrors({ keys: _keysLike })
      close();
   }
      
   return (
      <Dialog
         scrollingContent={true}
         title={'DocuSign Signature Request Form'}
         open={isOpen}
         size={'small'}
         pending={isFormSaving}
         actionButtons={
            <>
               <CancelButton buttonText='Close' disabled={isFormSaving} onClick={handleClose} />
               {/* AP updates the service at the same time
                  <DeleteButton buttonText='Void Envelope' onClick={() => setIsVoidDialogOpen(true)} disabled={isFormSaving || isVoidDialogOpen} loading={isVoidDialogOpen} />
                  */}
               <SaveButton
                  formId={__formId}
                  buttonText="Resend"
                  icon={<CustomIcon type={CustomIconType.SendOutlined} />}
                  onClick={() => { return false }}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <ApiErrorDisplay title='Api Error' keysLike={_keysLike} />
         {isFormSaving &&
            <Spinner />
         }

         <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[16, 16]}>
               <Col>
                  <InputField
                     control={control}
                     name='providerName'
                     label='Provider Name'
                     type='text'
                     error={errors?.providerName}
                     required={true} />
                  <InputField
                     control={control}
                     name='providerEmail'
                     label='Provider Email'
                     type='text'
                     error={errors?.providerEmail}
                     required={true} />
                  <InputField
                     control={control}
                     name='providerCode'
                     label='Provider Code'
                     type='text'
                     error={errors?.providerCode}
                     required={true} />
               </Col>
               <Col>
                  <InputField
                     control={control}
                     name='patientName'
                     label='Patient Name'
                     type='text'
                     error={errors?.patientName}
                     required={true} />
                  <InputField
                     control={control}
                     name='patientEmail'
                     label='Patient Email'
                     type='text'
                     error={errors?.patientEmail}
                     required={true} />
                  <InputField
                     control={control}
                     name='patientCode'
                     label='Patient Code'
                     type='text'
                     error={errors?.patientCode}
                     required={true} />
               </Col>
            </Row>            
         </Form>
         {/* AP updates the service at the same time
            {isVoidDialogOpen &&
            <Dialog
               title='CONFIRM VOID ENVELOPE'
               open={isVoidDialogOpen}
               style={{ maxWidth: '550px' }}
               size='tiny'
               actionButtons={
                  <>
                     <CancelButton onClick={() => setIsVoidDialogOpen(false)} />
                     <DeleteButton
                        disabled={isFormSaving}
                        onClick={() => handleVoidClick()} />
                  </>
               }
            >
               {`Are you sure you want to VOID the envelope for applicationid ${docuSignRequest.applicationId}?`}
            </Dialog>
         }  
         */}
         {isSuccess &&
            <Dialog
               title='Success'
               open={isSuccess}
               style={{ maxWidth: '550px' }}
               size='tiny'
               actionButtons={
                  <>
                     <ActionButton buttonText='Ok'
                        filled
                        onClick={() => {
                           handleClose()
                        }}
                     />
                  </>
               }
            >
               <Alert message={resultMessage} type='success' />
            </Dialog>
         }
      </Dialog>
   );
}

export default DocuSignOperationsDialog;

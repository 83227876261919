
export enum UIStates {
   Success = 'success',
   Error = 'error',
   Info = 'info',
   Warning = 'warning',
   Memo = 'memo',
   Locked = 'locked',
}

export enum UITypes {
   Primary = 'primary',
   Secondary = 'secondary',
   Tertiary = 'tertiary',
}

import useSWR, { mutate } from 'swr';
import { useMemo } from 'react';
import {
   IntrinsiqPayerToAP,
   IntrinsiqPayerToAPViewModel,
   IntrinsiqPayerToAPSearchFilter,
   IntrinsiqPayerToAPBulkMap
} from './IntrinsiqPayerToAPModel';
import { HttpError, TGet, TPost } from '../../functions/httpClient';
import { IStatus, NameAndId, PagedList } from '../models';
import { useFetchPractice } from '../practice/PracticeFetcher';
import { useFetchBrand } from '../drug/BrandFetcher';
import { createKey, mutateKeysLike, swrOptions } from '../../functions/swr.functions';

export const intrinsiqPayerBaseUrl = `api/XRef/IntrinsiqPayerToAP`;
export const intrinsiqPayerBulkMapUrl = `${intrinsiqPayerBaseUrl}/BulkMap`
export const intrinsiqPayerSearchUrl = `${intrinsiqPayerBaseUrl}/search`;
export const intrinsiqPracticesUrl = `${intrinsiqPayerBaseUrl}/GetPractices`;
export const intrinsiqAssistanceProgramsUrl = `${intrinsiqPayerBaseUrl}/GetAssistancePrograms`;

//keeping last key to trigger revalidate 
let _lastFetchKey: string;

export function useIntrinsiqPayerToAPSearch(httpPost: TPost, request: IntrinsiqPayerToAPSearchFilter):
   IStatus &
   { pagedResult: PagedList<IntrinsiqPayerToAP> } {

   const params = useMemo(() => {
      return request
   }, [request]);

   _lastFetchKey = createKey(intrinsiqPayerSearchUrl, params);
   
   const fetcher = () => httpPost<PagedList<IntrinsiqPayerToAP>>(intrinsiqPayerSearchUrl, params);

   const { data, isLoading, error } = useSWR<PagedList<IntrinsiqPayerToAP>, HttpError>(
      _lastFetchKey,
      fetcher,
      { ...swrOptions, }
   );

   return {
      pagedResult: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useIntrinsiqPayerToAPSearchViewModel(httpGet: TGet, httpPost: TPost, request: IntrinsiqPayerToAPSearchFilter): IStatus & { pagedResult: PagedList<IntrinsiqPayerToAPViewModel> } {
   const { pagedResult, isLoading, error, isSaving } = useIntrinsiqPayerToAPSearch(httpPost, request);
   const { brands, isLoading: isLoading_b, error: error_b } = useFetchBrand(httpGet);
   const { practices, isLoading: isLoading_p, error: error_p } = useFetchPractice(httpGet);

   const viewModel = useMemo(() => {
      const result: PagedList<IntrinsiqPayerToAPViewModel> = {
         page: pagedResult?.page ?? -1,
         pageSize: request?.pageSize ?? 25,
         totalPages: pagedResult?.totalPages ?? 0,
         totalRecords: pagedResult?.totalRecords ?? 0,
         data: []
      };

      if (!isLoading && pagedResult && practices?.length > 0 && brands?.length > 0) {
         if (pagedResult?.data?.length > 0) {
            result.data = pagedResult?.data.map((item): IntrinsiqPayerToAPViewModel => {
               const p = practices?.find(y => y.id === item.practiceId);
               const b = brands?.find(y => y.id === item.brandId);

               return {
                  ...item,
                  practiceName: p?.name,
                  brandName: b?.brandName
               } as IntrinsiqPayerToAPViewModel
            });
         }

         return result;
      }
   }, [brands, isLoading, pagedResult, practices, request?.pageSize]);

   return {
      pagedResult: viewModel,
      isLoading: isLoading || isLoading_p || isLoading_b,
      error: error ?? error_p ?? error_b,
      isSaving
   };
}
export function useFetchIntrinsiqPractices(httpGet: TGet): IStatus & { intrinsiqPractices: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      intrinsiqPracticesUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      intrinsiqPractices: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export function useFetchIntrinsiqAssistancePrograms(httpGet: TGet): IStatus & { intrinsiqAssistancePrograms: NameAndId[] } {
   const { data, isLoading, error } = useSWR<NameAndId[], HttpError>(
      intrinsiqAssistanceProgramsUrl,
      httpGet,
      { ...swrOptions }
   );

   return {
      intrinsiqAssistancePrograms: data,
      isLoading: isLoading,
      error: error?.message
   };
}

export const saveIntrinsiqPayerToAP = async (httpPost: TPost, items: IntrinsiqPayerToAP[]) => {
   const result = await httpPost<IntrinsiqPayerToAP[]>(intrinsiqPayerBaseUrl, items);
   mutateKeysLike(intrinsiqPayerSearchUrl);
   mutate(intrinsiqAssistanceProgramsUrl);
   mutate(intrinsiqPracticesUrl);
   return result;
}

export const bulkMapIntrinsiqPayerToAP = async (httpPost: TPost, viewModel: IntrinsiqPayerToAPBulkMap) => {
   const result = await httpPost<IntrinsiqPayerToAPBulkMap>(intrinsiqPayerBulkMapUrl, viewModel);
   mutateKeysLike(intrinsiqPayerSearchUrl);
   mutate(intrinsiqAssistanceProgramsUrl);
   mutate(intrinsiqPracticesUrl);
   return result;
}

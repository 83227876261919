import { Col, Row, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import * as React from 'react';
import { useLayoutContext } from '../../../../layouts/LayoutContext';
import CustomIcon, { CustomIconType } from '../CustomIcon';
import { IAntTableProps } from './IAntTableProps';

interface ITableProps extends IAntTableProps {
   appliedFilterDisplay?: React.ReactNode
}
const defaultPageSize = 25;

const SearchResultsTable: React.FC<ITableProps> = (props) => {
   const {
      onChange, columns, data, serverSideRowCount, currentPage, currentPageSize,
      setCurrentPageSize, titleText, expandedRowRender, additionalComponents,
      rowSelection, fixedHeader, rowkey, footer, size, bordered, loading,
      scrollY, scrollX, scrollHeight, appliedFilterDisplay
   } = props;


   const {
      setCardName, setCardHeaderTotalCount, setCardHeaderFilteredCount
   } = useLayoutContext();

   const {
      __currentPageSize: _currentPageSize,
      __currentPage: _currentPage,
      __totalRecordCount: _totalRecordCount,
      __currentPageRecordCount: _currentPageRecordCount,
   } = React.useMemo(() => {
      const __currentPageSize = currentPageSize && !isNaN(+currentPageSize) ? +currentPageSize : defaultPageSize;
      const __currentPage = currentPage && !isNaN(+currentPage) ? currentPage : 1;
      const __totalRecordCount = serverSideRowCount ?? data?.length ?? 0;
      const maxRecordCountForPage = __currentPageSize * __currentPage;
      const __currentPageRecordCount = __totalRecordCount > maxRecordCountForPage ? maxRecordCountForPage : __totalRecordCount;
      return {
         __currentPageSize,
         __currentPage,
         __totalRecordCount,
         __currentPageRecordCount,
      };
   }, [currentPageSize, currentPage, serverSideRowCount, data?.length]);

   const _pagingProps = {
      defaultCurrent: 1,
      defaultPageSize: defaultPageSize,
      current: _currentPage,
      pageSize: _currentPageSize,
      pageSizeOptions: [25, 50, 100, 500]
   };
   const _pagination = serverSideRowCount ? { ..._pagingProps, total: serverSideRowCount } : { ..._pagingProps };   
   const _footerProp = footer ? { footer: () => footer } : undefined;
  
   React.useEffect(() => {
      //this stops the wicked flicker in the header
      if (!loading) {
         setCardName(titleText);
         setCardHeaderFilteredCount(_currentPageRecordCount);
         setCardHeaderTotalCount(_totalRecordCount);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [titleText, _totalRecordCount, _currentPageRecordCount, loading]);

   const localOnChange: TableProps<object>['onChange'] = (pagination, filters, sorter, extra) => {
      onChange && onChange(pagination, filters, sorter, extra);
      //NOTE: this line was causing the header to flash initial page size then reset to current 
      //  setCardHeaderFilteredCount(extra.currentDataSource?.length ?? 0); // isn't fired for remove function like here

      if (pagination?.pageSize != currentPageSize) {
         setCurrentPageSize && setCurrentPageSize(pagination?.pageSize)
      }
   }

   //TODO: figure out how to better adjust the table height -> getting full page scrollbar on some xref pages
   //       also, External Report Management page has to supress paging -> using currentPageSize to hide
   //
   //    scroll={fixedHeader && { y: currentPageSize ? 'calc(100vh - 350px)' : 'calc(100vh - 300px)' }} />
   //
   //NOTE: tableLayout = 'auto' seems to fix the horizontal scrollbar - but the colums jump all over the place
   return <div style={{ margin: 10 }} className='searchresults'>
      {additionalComponents?.length > 0 &&
         <Row gutter={[32, 0]} align='top' style={{ marginBottom: 0 }}>
            {additionalComponents?.map((additionalComponent, key) => {
               return <Col style={{ paddingTop: 5 }} key={key}><>{additionalComponent}</></Col>
            })}
         </Row>
      }
      { appliedFilterDisplay }
      <Table
         rowKey={rowkey}
         bordered={bordered}
         className={`searchresultstable ${fixedHeader && 'searchresultstablehidescroll'}`}
         data-table='searchresultstable'
         onChange={localOnChange}
         columns={columns as ColumnsType<object>}
         dataSource={data}
         expandable={expandedRowRender && {
            expandedRowRender: expandedRowRender,
            expandIcon: ({ expanded, onExpand, record }) =>
               expanded ? (
                  <span onClick={e => onExpand(record, e)} > <CustomIcon type={CustomIconType.UpOutlined} /></span>
               ) : (
                  <span onClick={e => onExpand(record, e)} > <CustomIcon type={CustomIconType.DownOutlined} /></span>
               )
         } as ExpandableConfig<object>}
         rowSelection={rowSelection}
         pagination={!currentPageSize ? false :
            { ..._pagination }}
         {..._footerProp}
         scroll={fixedHeader && { y: scrollY ? scrollY : `calc(100vh - ${scrollHeight ?? 350}px)`, x: scrollX ? scrollX : '' }}
         tableLayout='fixed'
         size={size}
         showSorterTooltip={false}
         loading={loading}
      />
   </div>
}

export default SearchResultsTable;

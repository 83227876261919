import {MainItemType} from './MainMenu';
import {KnownSettings} from '../SettingsModel';
import {CustomIconType} from '../../components/shared/AntComponents/CustomIcon'

export interface ISubmenu {
   categories: ISubmenuCategory[];
   itemType: MainItemType;
}

export interface ISubmenuCategory {
   label: string;
   // labelIconUrl?: string;
   labelIconName?: CustomIconType
   categoryType: CategoryType;
   items: ISubmenuCategoryItem[];
   allowedRoles: string[];
   featureFlag?: string;
}

export interface ISubmenuCategoryItem {
   label: string;
   hoverText: string;
   route: string;
   allowedRoles: string[];
   featureFlag?: string;
}

export enum CategoryType {
   ContentAdminAnalytics,
   ContentAdminPowerBI,
   ContentAdminProgramSummary,
   ContentAdminPractice,
   ContentAdminProgram,
}

export const getSubMenuCategoryItemByRoute = (route: string, submenus: ISubmenu[]): ISubmenuCategoryItem => {

   let menuItem: ISubmenuCategoryItem = undefined;
   for (let smidx = 0; smidx < submenus.length; smidx++) {
      for (let cidx = 0; cidx < submenus[smidx].categories.length; cidx++) {
         menuItem = submenus[smidx].categories[cidx].items.find(y => y.route.indexOf(route) > -1);
         if (menuItem)
            return menuItem;
      }
   }

   return undefined;
}

export const SubMenus: ISubmenu[] = [
   {
      itemType: MainItemType.ContentTool,
      categories: [
         {
            label: "Analytics",
            labelIconName: CustomIconType.BarChartOutlined,
            categoryType: CategoryType.ContentAdminAnalytics,
            items: [
               {
                  label: "ContentClassificationXRef",
                  hoverText: "ContentClassificationXRef",
                  route: "/content-tool/CONTENT_CLASSIFICATION_XREF",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "IntrinsiqPayerToApXRef",
                  hoverText: "IntrinsiqPayerToApXRef",
                  route: "/content-tool/INTRINSIQPAYERTOAPXREF",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "PracticeServiceToApXRef",
                  hoverText: "PracticeServiceToApXRef",
                  route: "/content-tool/PRACTICE_SERVICE_TO_AP_XREF",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "AssistPoint Report Management",
                  hoverText: "AssistPoint Report Management",
                  route: "/content-tool/ASSISTPOINT_REPORT_MANAGEMENT",
                  allowedRoles: [
                     KnownSettings.BIReportManager
                  ]
               },
               {
                  label: "Manual Enrollment Reasons",
                  hoverText: "Manual Enrollment Reasons",
                  route: "/content-tool/MANUAL_ENROLLMENT_REASONS",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               }

            ],
            allowedRoles: [
               KnownSettings.ContentAdmin,
               KnownSettings.ContentViewer,
               KnownSettings.BIReportManager
            ]
         },
         {
            label: "Report Management",
            labelIconName: CustomIconType.LineChartOutlined,
            categoryType: CategoryType.ContentAdminPowerBI,
            items: [
               //Added in FlyoutMenuContext
            ],
            allowedRoles: [
               KnownSettings.BIReportManager
            ]
         },
         {
            label: "Cohorts",
            labelIconName: CustomIconType.ContainerOutlined,
            categoryType: CategoryType.ContentAdminProgramSummary,
            items: [
               {
                  label: "Program Summary Cohort",
                  hoverText: "Program Summary Cohort",
                  route: "/cohort/cohortindex",
                  allowedRoles: [KnownSettings.CohortAdmin, KnownSettings.CohortReviewer]
               },
               {
                  label: "Brand Defaults",
                  hoverText: "AssistanceServiceBrandDefaults",
                  route: "/content-tool/ASSISTANCESERVICEBRANDDEFAULTS",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Foundation Defaults",
                  hoverText: "AssistanceServiceFoundationDefaults",
                  route: "/content-tool/ASSISTANCESERVICEFOUNDATIONDEFAULTS",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Eligibility Criteria",
                  hoverText: "AssistanceServiceEligibilityCriteria",
                  route: "/content-tool/ASSISTANCESERVICEELIGIBILITYCRITERIA",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
            ],
            allowedRoles: [KnownSettings.CohortAdmin, KnownSettings.CohortReviewer, KnownSettings.ContentAdmin, KnownSettings.ContentViewer],
            featureFlag: KnownSettings.FeatureFlag_ProgramSummaryCohorts
         },
         {
            label: "Dashboard",
            labelIconName: CustomIconType.SnippetsOutlined,
            categoryType: CategoryType.ContentAdminProgram,
            items: [
               {
                  label: "Dashboard Content",
                  hoverText: "Dashboard Content",
                  route: "/content-tool/DASHBOARDCONTENT",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
            ],
            allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
         },
         {
            label: "Brands",
            labelIconName: CustomIconType.AppstoreOutlined,
            categoryType: CategoryType.ContentAdminProgram,
            items: [
               {
                  label: "Billing Code",
                  hoverText: "Billing Code",
                  route: "/content-tool/BILLINGCODE",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Brand",
                  hoverText: "Brand",
                  route: "/content-tool/BRAND",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "CPT",
                  hoverText: "CPT",
                  route: "/content-tool/CPT",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Drug",
                  hoverText: "Generic Drug",
                  route: "/content-tool/DRUG",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Generic Drug",
                  hoverText: "Generic Drug",
                  route: "/content-tool/GENERICDRUG",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "NDC",
                  hoverText: "NDC",
                  route: "/content-tool/NDC",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
            ],
            allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
         },
         {
            label: "Practice",
            labelIconName: CustomIconType.MedicineBoxOutlined,
            categoryType: CategoryType.ContentAdminProgram,
            items: [
               {
                  label: "Practice",
                  hoverText: "Practice",
                  route: "/content-tool/PRACTICE",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Practice Subscription",
                  hoverText: "Practice Subscription",
                  route: "/content-tool/PRACTICESUBSCRIPTION",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: 'AssistPoint User Management',
                  hoverText: 'AssistPoint User Management',
                  route: "/content-tool/USERMANAGEMENT",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer],
                  featureFlag: KnownSettings.FeatureFlag_UserManagement
               },
               {
                  label: 'Fax Management',
                  hoverText: 'Fax Management',
                  route: '/content-tool/FAXMANAGEMENT',
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: 'Ring Call Queue',
                  hoverText: 'Ring Call Queue',
                  route: '/content-tool/RINGCALLQUEUE',
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: 'DocuSign',
                  hoverText: 'DocuSign',
                  route: '/content-tool/DOCUSIGN',
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               }
            ],
            allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
         },
         {
            label: "Program",
            labelIconName: CustomIconType.BankOutlined,
            categoryType: CategoryType.ContentAdminProgram,
            items: [
               {
                  label: "Assistance Program",
                  hoverText: "Assistance Program",
                  route: "/content-tool/ASSISTANCE_PROGRAM",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Assistance Program Resource",
                  hoverText: "Assistance Program Resource",
                  route: "/content-tool/ASSISTANCE_PROGRAM_RESOURCE",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Disease Type",
                  hoverText: "Disease Type",
                  route: "/content-tool/DISEASE_TYPE",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Foundation",
                  hoverText: "Foundation",
                  route: "/content-tool/FOUNDATION",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer],
                  featureFlag: KnownSettings.FeatureFlag_Foundation
               },
               {
                  label: "Funding Status",
                  hoverText: "Foundation Funding Status",
                  route: "/content-tool/FUNDINGSTATUS",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer],
                  featureFlag: KnownSettings.FeatureFlag_Foundation
               },
               {
                  label: "Manufacturer",
                  hoverText: "Manufacturer",
                  route: "/content-tool/MANUFACTURER",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
               {
                  label: "Specialties",
                  hoverText: "Specialties",
                  route: "/content-tool/SPECIALTY",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               }
            ],
            allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
         },
         {
            label: "Life Science",
            labelIconName: CustomIconType.SwapOutlined,
            categoryType: CategoryType.ContentAdminProgram,
            items: [
               {
                  label: "Enrollment Status Requests",
                  hoverText: "Enrollment Status Requests",
                  route: "/content-tool/ENROLLMENT_STATUS",
                  allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
               },
            ],
            allowedRoles: [KnownSettings.ContentAdmin, KnownSettings.ContentViewer]
         },
      ]
   }
];
